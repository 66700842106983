<div class="chart-section">
    <!-- <div class="control-panel-col-header commonvul_header mb0" *ngIf="showFilter">
        <h3 *ngIf="title" class="spark-sec-tittle">{{title}}</h3>
        <div class="spark-btngroup device-gtngroup region-sort" class="region-sort">
            <mat-button-toggle-group name="fontStyle" aria-label="Font Style"
                class="spark-btngroup cyber-reilience-gtngroup" [(ngModel)]="this.selectedVulnarability.id">

                <mat-button-toggle  (click)="filterVulnarability(value, true)" [ngClass]="{'active':this.selectedVulnarability.id==value.id}"*ngFor="let value of vulnarabilityMenu" [ngClass]="{'active':this.selectedVulnarability.id==value.id}" value={{value.id}}>{{value.name}}</mat-button-toggle> 

            </mat-button-toggle-group>
        </div>
    </div> -->
    <div class="search-bar" *ngIf="showSearch">
            <mat-form-field appearance="fill">
                <mat-label>Search Assets</mat-label>
                <input matInput placeholder="" autocomplete="off" autocomplete="off" type="text"
                    (input)="filterAssetsField()" #searchField>
                <mat-icon class='search-icon' matPrefix>search</mat-icon>
            </mat-form-field>
    </div>
    <div id="common" class="vul-list">
     <app-ag-grid
        #myGrid
        [agDeviceColumnDefs]="agDeviceColumnDefs"
        [data]="commonVulnarabilites"
        [gridClass]="'common_vul_class  ag-grid-loader-bg'"
        [loader]="isApiCalled"
        [customSidebar]="false"
        [tableKey]="'common-table-list'"
        [restrictCheckBoxSelection]="false"
      (onRowSelectedChange)="getRowData($event[0])"> 
       </app-ag-grid>
    </div>
</div>
