<div class="cve-header-details-section">
<h3 *ngIf="selectedCVE" class="spark-sec-tittle">
    {{selectedCVE?.selectedRow?.cve_id}}
    <label mat-button *ngIf="cveDetails && cveDetails.cve_summary_desc"
        [matMenuTriggerFor]="cisa">{{cveDetails.cve_summary_desc}}</label>
    <mat-menu #cisa="matMenu" class="vector-list cve-summarypopup scroll">
        <div *ngIf="cveDetails" class="vector-list-section">
            <div class="vector-list-header vector-drop-header">
                <p [options]="toolTipConfig" [tooltip]="cveDetails.cve_summary_desc">{{cveDetails.cve_summary_desc}}</p>
            </div>
            <div class="vector-list-info">
                <div *ngIf="cveDetails.cve_details.vulnerability_name" class="vector-list-item full-width">
                    <p class="vector-list-label">Vulnerability Name</p>
                    <p class="vector-list-value">{{cveDetails.cve_details.vulnerability_name}}</p>
                </div>
                <div *ngIf="cveDetails.cve_details.date_added" class="vector-list-item">
                    <p class="vector-list-label">Date Added</p>
                    <p class="vector-list-value">{{cveDetails.cve_details.date_added}}</p>
                </div>
                <div *ngIf="cveDetails.cve_details.due_date" class="vector-list-item">
                    <p class="vector-list-label">Due Date</p>
                    <p class="vector-list-value">{{cveDetails.cve_details.due_date}}</p>
                </div>
                <div *ngIf="cveDetails.cve_details.required_action?.length" class="vector-list-item full-width">
                    <p class="vector-list-label">Required Action</p>
                    <p class="vector-list-value">
                        {{cveDetails.cve_details.required_action}}
                    </p>
                </div>
            </div>
        </div>
    </mat-menu>
</h3>
</div>