import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private headingSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private dropdownSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private actions = new Subject<any>();
  private disableFilter = new Subject<boolean>();
  private disableSearch = new Subject<boolean>();
  public acceptedRiskCount : BehaviorSubject<any> = new BehaviorSubject<any>('');
  private searchButton = new BehaviorSubject<boolean>(false); // Initially enabled (true)
  constructor() { }
  
  getHeading() {
    return this.headingSubject.asObservable();
  }
  getDropdown() {
    return this.dropdownSubject.asObservable();
  }
  setHeading(heading: string, data: any = []) {
    // console.log(heading)
    this.headingSubject.next(heading);
    this.dropdownSubject.next(data);
  }

  dispatchActions(data: any){
    this.actions.next(data);
  }

  getActions(){
    return this.actions.asObservable();
  }
  dispatchDisableFilter(flag: boolean) {
    return this.disableFilter.next(flag);
  }
  dispatchDisableSearch(flag: boolean) {
    return this.disableSearch.next(flag);
  }
  getAcceptedRiskCount(){
    return this.acceptedRiskCount.asObservable();
  }
  dispatchAcceptedRiskCount(data:any){
    this.acceptedRiskCount.next(data);
  }
  getDisableFilter() {
    return this.disableFilter.asObservable();
  }
  getDisableSearch() {
    return this.disableSearch.asObservable();
  }
}
