import {
  Directive,
  Input,
  ElementRef,
  TemplateRef,
  ViewContainerRef,
  Renderer2,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';

@Directive({
  selector: '[appCustomTooltip]'
})
export class CustomTooltipDirective implements OnInit, OnDestroy {
  @Input('appCustomTooltip') tooltipTemplate!: TemplateRef<any>;
  private overlayRef: OverlayRef | null = null;
  private isTooltipShown = false;
  private mouseEnterListener!: () => void;
  private mouseLeaveListener!: () => void;
  private overlayMouseEnterListener!: () => void;
  private overlayMouseLeaveListener!: () => void;
  private hideTimeoutId!: any;
  
  // Static property to keep track of the currently shown tooltip
  private static currentTooltip: CustomTooltipDirective | null = null;

  constructor(
    private overlay: Overlay,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(this.elementRef)
      .withFlexibleDimensions(false)
      .withPush(false)
      .withPositions([{
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
        offsetY: 10
      }, {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
        offsetY: -10
      }]);

    this.overlayRef = this.overlay.create({ positionStrategy });

    this.mouseEnterListener = this.renderer.listen(this.elementRef.nativeElement, 'mouseenter', () => this.show());
    this.mouseLeaveListener = this.renderer.listen(this.elementRef.nativeElement, 'mouseleave', () => this.scheduleHide());

    if (this.overlayRef) {
      const overlayElement = this.overlayRef.overlayElement;
      this.overlayMouseEnterListener = this.renderer.listen(overlayElement, 'mouseenter', () => this.clearHideTimeout());
      this.overlayMouseLeaveListener = this.renderer.listen(overlayElement, 'mouseleave', () => this.scheduleHide());
    }
  }

  show() {
    this.clearHideTimeout();

    if (this.isTooltipShown) {
      return;
    }

    // Hide the current tooltip before showing a new one
    if (CustomTooltipDirective.currentTooltip) {
      CustomTooltipDirective.currentTooltip.hide();
    }

    if (!this.overlayRef) {
      return;
    }

    const portal = new TemplatePortal(this.tooltipTemplate, this.viewContainerRef);
    this.overlayRef.attach(portal);
    this.adjustTooltipPosition();
    this.isTooltipShown = true;
    
    // Set this as the current tooltip
    CustomTooltipDirective.currentTooltip = this;
  }

  scheduleHide() {
    this.hideTimeoutId = setTimeout(() => {
      this.hide();
    }, 300); // 300 ms delay before hiding the tooltip
  }

  clearHideTimeout() {
    if (this.hideTimeoutId) {
      clearTimeout(this.hideTimeoutId);
      this.hideTimeoutId = null;
    }
  }

  hide() {
    this.clearHideTimeout();

    if (!this.isTooltipShown) {
      return; // Exit if tooltip is not shown
    }

    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
      this.isTooltipShown = false;
      
      // Clear the current tooltip if this is the one being hidden
      if (CustomTooltipDirective.currentTooltip === this) {
        CustomTooltipDirective.currentTooltip = null;
      }
    }
  }

  keepOpen() {
    // No action required as the tooltip will remain open by default
  }

  adjustTooltipPosition() {
    const overlayElement = this.overlayRef?.overlayElement;
    if (!overlayElement) return;

    const boundingClientRect = this.elementRef.nativeElement.getBoundingClientRect();
    const viewportHeight = window.innerHeight;

    // Check if there is enough space below the element
    if (boundingClientRect.bottom + 148 > viewportHeight) {
      this.renderer.addClass(overlayElement.children[0], 'arrow-top');
      this.renderer.removeClass(overlayElement.children[0], 'arrow-bottom');
    } else {
      this.renderer.addClass(overlayElement.children[0], 'arrow-bottom');
      this.renderer.removeClass(overlayElement.children[0], 'arrow-top');
    }
  }

  ngOnDestroy() {
    this.clearHideTimeout();

    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }

    if (this.mouseEnterListener) {
      this.mouseEnterListener();
    }
    if (this.mouseLeaveListener) {
      this.mouseLeaveListener();
    }
    if (this.overlayMouseEnterListener) {
      this.overlayMouseEnterListener();
    }
    if (this.overlayMouseLeaveListener) {
      this.overlayMouseLeaveListener();
    }
  }
}
