<div class="invite-modalbox">
    <h3>Invite User</h3>
     <span><button mat-button class="close-icon btn" [mat-dialog-close]="true">
        <mat-icon>cancel</mat-icon></button> </span>
    <p class="sub-text">
        Enter users email ID, comma Separated
    </p>
    <div class="inviteuser-field d-flex">
        <div class="mr_3 inviteuser-input">
            <mat-form-field class="example-full-width border-field no-bg" appearance="outline">
                <mat-label>Users</mat-label>
                <input #inviteMail matInput placeholder="Enter email id" [(ngModel)]="userId"
                    (keydown.enter)="onClickOfEnter($event, inviteMail.value)" [disabled]="isFormDisabled"   >
            </mat-form-field>
        </div>
        <!-- <div class="ml_3 inviteuser-btn" [hidden]="inviteUserList.length">
            <button mat-raised-button class="full-width" color="primary" (click)="inviteUser()">Send
                Invite</button>
        </div> -->
    </div>
    <div class="inviteusers-list scroll">
        <div class="inviteuser-item" *ngFor="let user of inviteUserList; let i=index">
            <div class="inviteuser-id bordered-div">
                <span class="avatar" [ngStyle]="{'background': randomBackgroundcolor[i]}" >{{user.substr(0,1).toUpperCase()}}</span>
                <span class="inviteuser-name" matTooltip="{{user}}">{{user}}</span>
                <span class="inviteuser-icon" (click)="removeUser(user)"><mat-icon>close</mat-icon></span>
            </div>
            <div class="inviteuser-dorpdown ">
                <span>Read Only</span>
            </div>
        </div>
    </div>
    <mat-error *ngFor="let validation of validationMessages">
        <mat-error class="error-message">
            {{validation}}</mat-error>
    </mat-error>
    <div class="inviteuser-field">
        <div class="inviteuser-btn">
            <button #myButton [disabled]="validationMessages.length > 0 || !inviteUserList.length || isFormDisabled" class="full-width btn btn-primary" color="primary" (click)="inviteUser()" [ngClass]="{'loader-btn disabled': isSpinner}">Send Invite
                <span class="btn-loader spin" *ngIf="isSpinner"><img src="../../assets/images/btn-loader.png" /></span>
            </button>
        </div>
    </div>
    
</div>
