import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DeviceManagementComponent } from '../device-management.component';
import { IDeviceList } from '../device.model';
import { Component, ElementRef, ViewContainerRef } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DeviceSeverityComponent } from '../device-severity/device-severity.component';

@Component({
  selector: 'app-assets-name',
  templateUrl: './assets-name.component.html',
  styleUrls: ['./assets-name.component.scss']
})
export class AssetsNameComponent implements ICellRendererAngularComp{
  public params!: ICellRendererParams;
  get isActiveScans() {
    return this.deviceComp.isActiveScans;
  }
  
  private overlayRef!: OverlayRef;

  constructor(private overlay: Overlay, private viewContainerRef: ViewContainerRef,public deviceComp: DeviceManagementComponent) {}

  showTooltip(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(targetElement)
      .withPositions([{ originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' }]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
    });

    const tooltipPortal = new ComponentPortal(DeviceSeverityComponent, this.viewContainerRef);
    const tooltipComponentRef = this.overlayRef.attach(tooltipPortal);
    //tooltipComponentRef.instance.data = { content: 'This is dynamic content!' };
  }

  hideTooltip(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
  public openCalender(data: IDeviceList) {
    this.deviceComp.openCalender(data);
  }
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
}