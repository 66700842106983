<div class="complaince-main">
  <div class="complaince-header">
    <div class="header">
      <p>Asset Compliance Report</p>
      <div class="close-btn">
        <button mat-button class="btn close-sidebar" (click)="closeNotification()"><mat-icon>cancel</mat-icon></button>
      </div>
    </div>
  </div>
  <div class="complaince-body">
    <mat-form-field appearance="outline" class="border-field">
      <mat-label>Benchmarks</mat-label>
      <mat-select [formControl]="itemControl" multiple panelClass="bench-mark-panel" >
        <mat-select-trigger>
          <ng-container *ngFor="let item of selectedItemsForDisplay(); let i = index">
            <span matTooltip="{{ item.name }}">
              {{ formatText(item.name) }}
            </span>
            <ng-container *ngIf="i < selectedItemsForDisplay().length - 1">, </ng-container>
          </ng-container>
            <div *ngIf="additionalCount() > 0" class="count-num">+{{ additionalCount() }}</div>
        </mat-select-trigger>
        <div class="benchmark-options">
          <div  class="serach-bar"> 
            <img src="../../../../../assets/images/search-icon.svg" class="search-icon" alt="search icon" />
           <input matInput #searchInput (keyup)="applyFilter($event)" placeholder="Search" />
          </div>
          <div class="scroll options-section">
          <mat-option *ngFor="let item of filteredOptions" [value]="item.id" [selected]="isSelected(item.id)"
            (click)="toggleSelection(item)">
            {{ item.name }}
          </mat-option>
        </div>
        </div>

      </mat-select>
    </mat-form-field>
    <div class="label-box">
      <p>Parameters</p>
      <div class="radio-group">
        <mat-checkbox [(ngModel)]="parameters.failed" (change)="onParameterChange()">Failed</mat-checkbox>
        <mat-checkbox [(ngModel)]="parameters.passed" (change)="onParameterChange()">Passed</mat-checkbox>
      </div>

    </div>
    <div class="label-box">
      <p>Risk Accepted</p>
      <div>
        <mat-radio-group [(ngModel)]="accepted" class="report-label">
          <mat-radio-button [value]="true">Include
          </mat-radio-button>
          <mat-radio-button [value]="false">Exclude
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="label-box">
      <p>Parameter Descriptions</p>
      <div>
        <mat-radio-group [(ngModel)]="description" class="report-label">
          <mat-radio-button [value]="true">Include
          </mat-radio-button>

          <mat-radio-button [value]="false">Exclude
          </mat-radio-button>
          <label></label>
        </mat-radio-group>
      </div>
    </div>
    <div class="label-box">
      <p>Parameter Remediations</p>
      <div>
        <mat-radio-group [(ngModel)]="remediation" class="report-label">
          <mat-radio-button [value]="true">Include
          </mat-radio-button>
          <mat-radio-button [value]="false">Exclude
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="label-box">
      <p>Report Format</p>
      <div>
        <mat-radio-group [(ngModel)]="output" class="report-label">
          <mat-radio-button [value]="'pdf'">Pdf
          </mat-radio-button>
          <mat-radio-button [value]="'excel'">Excel
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>
  <div class="profile_buttons">
    <button class="btn btn-primary"
      [disabled]="selectedItemIds.length === 0 || (!this.parameters.passed && !this.parameters.failed)"
      (click)="complianceReport()">Download Report</button>
  </div>
</div>