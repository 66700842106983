import { Component } from '@angular/core';
import { WhitelistDetailsComponent } from '../whitelist-details/whitelist-details.component';

@Component({
  selector: 'app-customheader-checkbox',
  templateUrl: './customheader-checkbox.component.html',
  styleUrls: ['./customheader-checkbox.component.scss']
})
export class CustomheaderCheckboxComponent {
  public params: any;
  agInit(params: any): void {
    this.params = params;
  }
  public get permissionProps() {
    return this.whiteComp.permissionProps
  }
  constructor(public whiteComp: WhitelistDetailsComponent){
   console.log(this.params)
  }
  public hasPermission(type: string){
    return this.whiteComp.hasPermission(type)
  }

}
