// tooltip.component.ts
import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styles: [`
    :host {
      background: rgba(97, 97, 97, 0.9);
      color: #fff;
      padding: 8px;
      border-radius: 4px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    }
  `]
})
export class TooltipComponent {
  @Input() templateRef!: TemplateRef<any>;
}
