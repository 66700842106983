<span *ngIf="params.data.invite_status === invite">Invited</span>
<svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none" *ngIf="params.data.invite_status === 'Activated'">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.875 11.875L8.5 9.3025C8.15875 9.2675 7.905 9.25 7.625 9.25C5.28875 9.25 0.625 10.4225 0.625 12.75V14.5H8.5L5.875 11.875ZM7.625 7.5C9.55875 7.5 11.125 5.93375 11.125 4C11.125 2.06625 9.55875 0.5 7.625 0.5C5.69125 0.5 4.125 2.06625 4.125 4C4.125 5.93375 5.69125 7.5 7.625 7.5Z" fill="#19A71F"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5363 14.9375L8.5 11.875L9.725 10.6413L11.5363 12.4613L16.025 7.9375L17.25 9.17125L11.5363 14.9375Z" fill="#19A71F"/>
    </svg>
<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none" *ngIf="params.data.invite_status === 'Deactive'">
    <g clip-path="url(#clip0_5496_25900)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.875 14.875L10.5 12.3025C10.1588 12.2675 9.905 12.25 9.625 12.25C7.28875 12.25 2.625 13.4225 2.625 15.75V17.5H10.5L7.875 14.875ZM9.625 10.5C11.5588 10.5 13.125 8.93375 13.125 7C13.125 5.06625 11.5588 3.5 9.625 3.5C7.69125 3.5 6.125 5.06625 6.125 7C6.125 8.93375 7.69125 10.5 9.625 10.5Z" fill="#FB4B50"/>
    <path d="M18.0162 11.375L15.75 13.6412L13.4837 11.375L12.25 12.6087L14.5162 14.875L12.25 17.1412L13.4837 18.375L15.75 16.1087L18.0162 18.375L19.25 17.1412L16.9837 14.875L19.25 12.6087L18.0162 11.375Z" fill="#FB4B50"/>
    </g>
    <defs>
    <clipPath id="clip0_5496_25900">
    <rect width="21" height="21" fill="white"/>
    </clipPath>
    </defs>
    </svg>