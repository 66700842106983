import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomDailog } from 'src/app/modules/shared/model/Dialog.model';
import { ICreateUpdateDeleteVpnPayLoad, ICreateUpdateDeleteVpnResponse } from '../vpn..models';
import { CONFIG } from 'src/app/modules/shared/constants/config';
import { VpnService } from 'src/app/modules/shared/services/vpn.service';

@Component({
  selector: 'app-vpn-popups',
  templateUrl: './vpn-popups.component.html',
  styleUrls: ['./vpn-popups.component.scss']
})
export class VpnPopupsComponent {
  hide = true;
  public isSpinner: boolean = false;
  public vpndetails:any=null;
  ipForm: FormGroup;
  constructor(private fBuilder: FormBuilder,
    private vpnService: VpnService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: CustomDailog
  ) {
    this.vpndetails = this.data.data.data;
    this.ipForm = this.fb.group({
      ip: ['', [Validators.required]]
    });

  }

  

  public testVpn(){
    this.isSpinner = true;
    let payload:any={};
    payload.tunnel_id=this.vpndetails.tunnel_id;
    payload.customer_private_ip=this.ipForm.get('ip')?.value;
    this.vpnService.testVpn(payload, CONFIG.METHOD.POST, this.testVpnCallback);

 }
 pingStatus:boolean=false;
 testSuccess:boolean=false;
 vpn_tunnel_status:boolean=false;
 description:string='';
 isping:boolean=false
 public testVpnCallback=(response: any) => {
  this.isSpinner = false;
  if (response.status === 200) {
    this.testSuccess=true;
    this.pingStatus = response.data?.ping_status;
    this.ipForm.get('ip')?.disable(); // This makes the IP field readonly
    this.vpn_tunnel_status =  response.data?.vpn_tunnel_status;
    if(this.pingStatus && this.vpn_tunnel_status) {
      this.description = "Vpn is reachable and ping asset is success";
    } else {
      this.isping=true;
      this.description = "Either VPN/Asset is not reachable";
    }

    //this.createAndUpdateVpnLoader = true;
    //this.vpndetails=response.data;
  }
}
}
