<div class="create-role-modalbox">
    <h3>Rename the role?</h3> 
    <p class="sub-text">Rename the role name which will fit to the Organization</p>
    <form [formGroup]="roleNameInput">
    <div class="createrole-field">
        <div class="createrole-input">
            <mat-form-field class="example-full-width border-field no-bg" appearance="outline" [ngClass]="{'ng-pristine': roleNameInput.get('role_name')?.pristine}">
                <mat-label>Role Name</mat-label>
                <input matInput placeholder="Role Name"  #roleInput maxlength="65" formControlName="role_name" >
                <mat-error *ngFor="let validation of validation_messages.role_name">
                    <mat-error class="error-message error-text"
                        *ngIf="roleNameInput.get('role_name')?.hasError(validation.type) && (roleNameInput.get('role_name')?.dirty || roleNameInput.get('role_name')?.touched)">
                        {{validation.message}}</mat-error>
                </mat-error>    
            </mat-form-field>
        </div>
        <div class="renam-btns">
            <button  (click)="newName(roleInput.value)"  mat-raised-button class="btn btn-blue" [disabled]="renameDisabled(roleNameInput, roleInput)">Rename</button>
            <button  (click)="cancelRename()" mat-raised-button  class="btn btn-white" >Cancel</button>
        </div>
    </div>
</form> 
</div>
