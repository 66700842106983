import { DialogRef } from '@angular/cdk/dialog';
import {  Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CONSTANT } from 'src/app/modules/shared/constants/constant-data';
import { CustomDailog } from 'src/app/modules/shared/model/Dialog.model';
import { DialogService } from 'src/app/modules/shared/services/dialog.service';
import { Role } from '../model/tool-management.model';
@Component({
  selector: 'app-create-role',
  templateUrl: './create-role.component.html',
  styleUrls: ['./create-role.component.scss']
})
export class CreateRoleComponent {
  roleNameInput: FormGroup;
  selectedRole: Role = {};
  defaultRoles: Role[] = [];
  validation_messages = {
    role_name: [
      { type: 'required', message: CONSTANT.CREATE_ROLE.ROLE_NAME_REQUIRED },
      { type: 'minlength', min: 3, message: CONSTANT.CREATE_ROLE.ROLE_NAME_MIN_LENGTH },
      { type: 'maxlength', max: 64, message: CONSTANT.CREATE_ROLE.ROLE_NAME_MAX_LENGTH },
      { type: 'pattern', pattern:/^[a-zA-Z0-9]*(\s[a-zA-Z0-9]+)*$/, message: CONSTANT.CREATE_ROLE.ROLE_NAME_PATTERN },
      {type: 'roleAlreadyExist', message: CONSTANT.CREATE_ROLE.ROLE_ALEARDY_EXIST}
    ]
  };

  constructor(public dialogRef: MatDialogRef<CreateRoleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CustomDailog,
    private dialogService: DialogService,
    private fb: FormBuilder) { 
      this.defaultRoles = data?.data?.defaultRoles;
    this.roleNameInput = this.fb.group({
      "role_name": new FormControl("", Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(64),
        Validators.pattern(/^[a-zA-Z0-9]*(\s[a-zA-Z0-9]+)*$/)
      ])),
      "roleType": new FormControl(null, Validators.compose([
        Validators.required
      ]))
    })
  }

  createRole(value: string): void {
    // this.dialogRef.close(value.trim());
    const createRoleValue = {
      name: value.trim(),
      roleId: this.roleNameInput.controls['roleType'].value
    }
    const res =  this.data?.data?.setCallbackFunction(createRoleValue);      if (res?.closeDialog) {
        this.roleNameInput.controls['role_name'].setErrors(null);
        this.roleNameInput.controls['roleType'].setErrors(null);
        this.dialogService.closeDialog();
      }else{
        this.roleNameInput.controls['role_name'].setErrors({'roleAlreadyExist': true});
        this.roleNameInput.controls['role_name'].markAsTouched();
      }
    }
    cancelRename(): void {
      this.dialogService.closeDialog();
    }
}
