import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Chart, ChartType, registerables } from 'chart.js';
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";
import { ResponseService } from '../../services/response.service';
import { RestService } from '../../services/rest.service';
import { CONSTANT, ToolTipConfig } from '../../constants/constant-data';
import { ICatDrop, ICategoryWise, ICategoryWiseResponse } from './category.model';
import * as echarts from 'echarts';
import * as _ from 'lodash';
@Component({
  selector: 'app-category-wise',
  templateUrl: './category-wise.component.html',
  styleUrls: ['./category-wise.component.scss']
})
export class CategoryWiseComponent {
  categoryWise(categoryWise: any) {
    throw new Error();
  }
  @Input() title: string = '';
  @Input() showFilter: boolean = false;
  @Input() apiName: string = '';
  @Input() payLoad: any = {};
  @ViewChild('categoryWiseScores') categoryWiseScores!: ElementRef;
  @Input() categoryWiseGraphSec: string = 'bar-chart';
  public categoryWiseScoresChart!: Chart;
  public valueFilterList: ICatDrop[] = [];
  public categoryWiseScoresChartCanvas: HTMLCanvasElement;
  public categoryWiseScoresChartCtx: CanvasRenderingContext2D | [];
  public categoryPassedData: number[] = [];
  public categoryFailedData: number[] = [];
  public categoryWiseChartData: number[] = [];
  public categoryBgColor: string[] = ['#3A2ADA', '#F3931B', '#AF69F5'];
  public categoryLabel: string[] = ['Control', 'Management', 'Data'];
  public selectedCatFilter: string = 'all';
  public selectedCatValue: string = 'All';
  public bgColorData: string[] = [];
  public isSpinner: boolean = false;
  public constant = CONSTANT;
  public categoryWisePercVal: any[] = [];
  public categoryWiseActualPercVal: any[] = [];
  public categoryWisePassAndFailParams: any = {};
  public toolTipOption = _.cloneDeep(ToolTipConfig);
  noDataMsg: String = CONSTANT.NO_DATA_MSG;
  myChart: echarts.ECharts | undefined;
  constructor(private responseService: ResponseService,
    private restService: RestService) {
    this.toolTipOption.display = true;
    this.toolTipOption.placement = 'top';
    this.categoryWiseScoresChartCanvas = this.categoryWiseScores?.nativeElement;
    this.categoryWiseScoresChartCtx = this.categoryWiseScoresChartCanvas?.getContext("2d") as CanvasRenderingContext2D;
  }
  ngOnInit(): void {
    if (this.apiName) {
      this.loadCategotyChart(this.loadCategotyChartCallback, "");
    }
  }
  ngAfterViewInit(): void {
    this.categoryWiseScoresChartCanvas = this.categoryWiseScores?.nativeElement;
    this.categoryWiseScoresChartCtx = this.categoryWiseScoresChartCanvas?.getContext("2d") as CanvasRenderingContext2D;
  }

  public loadCategotyChart(callback: Function, value: string): void {
    this.isSpinner = true;
    let req = {
      category: value
    };
    if (this.payLoad) {
      req = { ...req, ...this.payLoad };
    }
    this.restService.postApi(this.apiName, req).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }
  public loadCategotyChartCallback = (response: ICategoryWiseResponse) => {
    this.isSpinner = false;
    this.categoryPassedData = [];
    this.categoryFailedData = [];
    // reset graph values when we have updated response data
    this.categoryWisePercVal = [];
    this.categoryWiseActualPercVal = [];
    if (response.status === 200 && response.data) {
      const categoryData: ICategoryWise[] =
        this.sortArrayBasedPropertyValue(CONSTANT.CATEGORY_WISE_LABELS, response.data[0].category_wise_output);
      categoryData?.forEach((currentCatObj: ICategoryWise, index: number) => {
        if (currentCatObj?.passed_count > 0 || currentCatObj.failed_count > 0) {
          this.categoryPassedData.push(currentCatObj.passed_count);
          this.categoryFailedData.push(currentCatObj.failed_count);
        }
        this.getCategoryWisePercentageAndParams(currentCatObj, index);
      });
    }
  }
  public valueFilter(selectedFilter: any) {
    this.selectedCatFilter = selectedFilter.key;
    this.selectedCatValue = selectedFilter.value;
    this.loadCategotyChart(this.loadCategotyChartCallback, this.selectedCatFilter);
  }

  public getCategoryWisePercentageAndParams(currentCatObj: any, index: number) {
    let percentage = (currentCatObj?.passed_count / currentCatObj?.parameter_count) * 100;
    let failedPercentage = (currentCatObj?.failed_count / currentCatObj?.parameter_count) * 100;
    const failedPrecion = failedPercentage?.toString().split('.')[1];
    const passedPrecion = percentage?.toString().split('.')[1];
    if(failedPrecion && Number('.' + failedPrecion) > 0.5) {
      failedPercentage = Math.ceil(failedPercentage)
    } else {
      failedPercentage = Math.floor(failedPercentage)
    }
    if(passedPrecion && Number('.' + passedPrecion) > 0.5) {
      percentage = Math.ceil(percentage);
    } else {
      percentage = Math.floor(percentage);
    }
    this.categoryWiseActualPercVal.push(Math.trunc(percentage));
    if (Math.trunc(percentage) <= 8) {
      percentage = 8;
    }
    this.categoryWisePercVal.push({ categoryLabel: CONSTANT.CATEGORY_WISE_LABELS[index], actualValue:this.categoryWiseActualPercVal[index],value: Math.trunc(percentage),value1: Math.trunc(failedPercentage), itemStyle: { color: this.categoryWiseActualPercVal[index] === 0 ? '#f6585c' : this.categoryBgColor[this.categoryWisePercVal.length] } });
    this.categoryWisePassAndFailParams[currentCatObj.category] = `Passed Count: ${currentCatObj.passed_count} <br> Failed Count: ${currentCatObj.failed_count}`;
  }

  public sortArrayBasedPropertyValue(values: string[], mainArray: ICategoryWise[]) {
    const sortedArray: any[] = [];
    values?.forEach((value) => {
      mainArray?.forEach((cat: any) => {
        if (cat.category === value) {
          sortedArray.push(cat);
        }
      })
    })
    return sortedArray;
  }
}
