import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from './modules/shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from './modules/shared/services/token-interceptor.service';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';
import { BnNgIdleService } from 'bn-ng-idle';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SubscriptionModule } from './modules/subscription/subscription.module';
import { NumberFormatPipe } from './number-format.pipe';
import { MatTooltip, MatTooltipModule } from '@angular/material/tooltip';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { AgGridModule } from 'ag-grid-angular';
import { HeaderService } from './modules/shared/services/header.service';
import { AdminModule } from './modules/admin/admin.module';

@NgModule({
  declarations: [
    AppComponent,
    // NumberFormatPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NoopAnimationsModule,
    SharedModule,
    SubscriptionModule,
    HttpClientModule,
     BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    MatTooltipModule,
    NgxSkeletonLoaderModule.forRoot(),
    NgIdleKeepaliveModule,
    AgGridModule,
    AdminModule
  ],
  providers: [
    HeaderService,
    {
      provide:HTTP_INTERCEPTORS,
      useClass:TokenInterceptorService,
      multi:true
    },
    
    CookieService,
    BnNgIdleService,
    MatTooltip],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
