import { Component, Input, SimpleChanges } from '@angular/core';
import * as echarts from 'echarts/core';
import {
  TooltipComponent,
  TooltipComponentOption,
  LegendComponent,
  LegendComponentOption
} from 'echarts/components';
import { PieChart, PieSeriesOption } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { DynamicChartData } from '../components/selected-list-details/selected-list-details.model';

echarts.use([
  TooltipComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout
]);

type EChartsOption = echarts.ComposeOption<
  TooltipComponentOption | LegendComponentOption | PieSeriesOption
>;

@Component({
  selector: 'app-semi-donut-chart',
  templateUrl: './semi-donut-chart.component.html',
  styleUrls: ['./semi-donut-chart.component.scss']
})
export class SemiDonutChartComponent {
  @Input() dynamicChartData: DynamicChartData[] = [];

  ngOnChanges(changes: SimpleChanges){
    if(changes['dynamicChartData'] && changes['dynamicChartData'].currentValue){
        this.donutChart(changes['dynamicChartData'].currentValue);
    }
  }
  
  ngOnInit(): void {
    if(this.dynamicChartData){
    this.donutChart(this.dynamicChartData)
  }
  }

  public donutChart(dynamicChartData: DynamicChartData[]){
    var chartDom = document.getElementById('main')!;
    var myChart = echarts.init(chartDom);
    var option: EChartsOption;

    const specialDataPoint = {
      value: this.calculateTotalValue(),
      itemStyle: {
        color: 'none',
        decal: {
          symbol: 'none',
        },
      },
      label: {
        show: false,
      },
    };

    const chartDataWithSpecialPoint = [...dynamicChartData, specialDataPoint];

    
    option = {
      tooltip: {
        trigger: 'item',
        textStyle: {
          width: 400
        },
        formatter: (params: any)=> {
          let content = '';
              content += params && params.data && params.data.name && params.data.value ?  params.data.name + ': ' + '<strong>' + params.data.value + '</strong>': '';
          return '<div style="width: auto;">'+
          '<i class="badge" style="border-radius:50%;margin-right: 5px;display:inline-block;width:10px;height:10px;vertical-align: middle;background-color:'+ params.data.itemStyle.color +'"></i>'
           + content + 
           '</div>'; // Adjust width as needed
        },
        position: 'right',
      },
      series: [
        {
          type: 'pie',
          radius: ['50%', '75%'],
          center: ['50%', '70%'],
          startAngle: 180,
          label: {
            show: false,
          },
          data: chartDataWithSpecialPoint
        }
      ]
    };
    
    option && myChart.setOption(option);
  }

  private calculateTotalValue(): number {
    return this.dynamicChartData.reduce((total, data) => total + data.value, 0);
  }

}
