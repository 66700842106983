import { Component, OnInit } from '@angular/core';
import { DEVICE_TREE_DATA, ITreeView, REGION_TREE_DATA, TodoItemFlatNode, TreeJson } from 'src/app/modules/shared/components/filter/filter.model';
import { FilterService } from 'src/app/modules/shared/services/filter.service';


@Component({
  selector: 'app-users-customize',
  templateUrl: './customize.component.html',
  styleUrls: ['./customize.component.scss']
})
export class CustomizeComponent implements OnInit {

  customizePayload: any = {
    upload: true,
    ssh: false,
    api: false,
    whitelist: true,
    duration_btw_scans: false,
    download_reports: true,
    emails_alerts: false,
    device_library: false,
    adding_params: false,
    scans_perday: 0

  }
  selectedRegionsDevices: ITreeView[] = [];

  filterColumnData: Map<String, ITreeView[]> = new Map<String, ITreeView[]>();

  constructor(private filterService: FilterService) {
    this.filterColumnData.set('Devices', DEVICE_TREE_DATA);
    this.filterColumnData.set('Region', REGION_TREE_DATA);
  }

  ngOnInit(): void {

  }

  onScanHandler(type: string): void {
    if (type === 'increment') {
      this.customizePayload['scans_perday'] += 1;
    } else if (type === 'decrement' && this.customizePayload['scans_perday'] > 0) {
      this.customizePayload['scans_perday'] = this.customizePayload['scans_perday'] - 1;
    }
  }

  OnToggleChange($event: any, type: string): void {
    this.customizePayload[type] = $event.checked;   
  }

  public selectedFilters(values: ITreeView[]) {
    this.selectedRegionsDevices = values;
  }    
}