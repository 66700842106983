import { EventEmitter, Injectable, Output } from '@angular/core';
import { END_POINTS } from '../constants/endpoints';
import { RestService } from './rest.service';
import { ResponseService } from './response.service';
import { ITreeView } from '../components/filter/filter.model';
import { AuthService } from './auth.service';
import { Utils } from '../utils';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ControlPanelService {
  @Output() enableGlobalFilter = new EventEmitter<boolean>();
  public vulFilterSubject = new Subject<any>();
  public vulFilter = this.vulFilterSubject.asObservable();
  constructor(private restService: RestService,
    private responseService: ResponseService,
    private authService: AuthService) { }

  public fetchDurationFilter(callback: Function) {
    const filterDetails = JSON.parse(this.authService.getCookie('duration') || '[]');
      if (filterDetails && filterDetails.length) {
        callback(filterDetails);
      } else {
        this.restService.getApi(END_POINTS.DASHBOARD_DURATION_FILTER).subscribe({
          next: (response) => {
            const data = this.responseService.successResponse(response);
            if(data?.status === 200 && data?.data?.duration) {
              const duration = Utils.sortByDesiredOrder(data?.data?.duration)
              this.setFilter('duration', duration);
              callback(duration);
            }
          },
          error: (err) => {
            this.setFilter('duration', []);
            callback([]);
            this.responseService.errorResponse(err);
          },
        })
      }
    
  }
  public setFilter(type: string, data: any) {
    this.authService.setCookie(type, JSON.stringify(data));
  }
  public fetchDeviceFilter(callback: Function) {
    const filterDetails = JSON.parse(this.authService.getCookie('deviceWith') || '[]');
      if (filterDetails && filterDetails.length) {
        callback(filterDetails);
      } else {
        this.restService.getApi(END_POINTS.DASHBOARD_DEVICE_FILTER)?.subscribe({
          next: (response) => {
            const data = this.responseService.successResponse(response);
            if(data.status === 200 && data?.data?.sortby) {
              this.setFilter('deviceWith', data?.data?.sortby);
              callback(data?.data?.sortby);
            }
          },
          error: (err) => {
            this.setFilter('deviceWith', []);
            callback([]);
            this.responseService.errorResponse(err);
          },
        })
      }
  }

  public fetchDashboardData(callback: Function) {
    this.restService.getApi(END_POINTS.DASHBOARD_DATA).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }
  public fetchNotDeviceScan(selectedMonth: string = '', callback: Function) {
    const req = {
      duration: selectedMonth
    };
    this.restService.postApi(END_POINTS.DASHBOARD_NO_DEVICE_SCAN, req).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }


  public fetchRegionWise(
    selectedMonth: string = '',
    region: string = '',
    level: number = 0,
    callback: Function) {
    const req: any = {
      duration: selectedMonth
    };
    if (region) {
      req.id = region;
    }
    if (level > -1) {
      req.level = level;
    }
    this.restService.postApi(END_POINTS.DASHBOARD_REGION_WISE, req).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }
  public fetchRegionDropdown(callback: Function) {
    this.restService.getApi(END_POINTS.DASHBOARD_REGION_DROPDOWN).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }

  public fetchDeviceWithScore(payload: any, callback: Function) {
      this.restService.postApi(END_POINTS.DASHBOARD_DEVICE_WITH_SCORE, payload).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }
  
  public filterSelectedNodes(data: ITreeView[]): any {
    if (!Array.isArray(data)) {
      return data
    }
    return data.filter(item => {
      if ('data' in item) {
        item.data = this.filterSelectedNodes(item.data || []);
      }
      return (item.selected || item.indeterminate)
    })
  }
  
}
