import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { MonacoEditorConstructionOptions } from '@materia-ui/ngx-monaco-editor';
import { END_POINTS } from 'src/app/modules/shared/constants/endpoints';
import { HeaderService } from 'src/app/modules/shared/services/header.service';
import { IDeviceList, IDeviceListResponse } from '../device.model';
import { SpinnerService } from 'src/app/modules/shared/services/spinner.service';
import { DeviceService } from 'src/app/modules/shared/services/device.service';
import { ICyberDrop, ICyberResilience, IEventData, cyberConstant } from 'src/app/modules/shared/components/cyber-reilience/cyber-reilience.model';
import { IDurationResponse } from 'src/app/modules/dashboard/components/controlpanel/controlPanelModel';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { IDeviceCyberResilience, IDeviceCyberResilienceResponse } from './device.model';
import { CONSTANT, ToolTipConfig } from 'src/app/modules/shared/constants/constant-data';
import { Utils } from 'src/app/modules/shared/utils';
import { DateAdapter } from '@angular/material/core';
import * as echarts from 'echarts/core';
import { diffLines } from 'diff';
import {
  DatasetComponent,
  DatasetComponentOption,
  TitleComponent,
  TitleComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  GridComponent,
  GridComponentOption,
  TransformComponent
} from 'echarts/components';
import { LineChart, LineSeriesOption } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import { RestService } from 'src/app/modules/shared/services/rest.service';
import { ResponseService } from 'src/app/modules/shared/services/response.service';
import * as _ from 'lodash';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
echarts.use([
  DatasetComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  TransformComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition
]);
type EChartsOption = echarts.ComposeOption<
  | DatasetComponentOption
  | TitleComponentOption
  | TooltipComponentOption
  | GridComponentOption
  | LineSeriesOption
>;

@Component({
  selector: 'app-new-device',
  templateUrl: './new-device.component.html',
  styleUrls: ['./new-device.component.scss']
})
export class NewDeviceComponent {
  @ViewChild('cyberResilience') cyberResilience!: ElementRef;
  public currentSelectedDevice:any = {};
  public isChartLoad: boolean = false;
  public isDevicePerameterLoad: boolean = true;
  public isCompare: boolean = false;
  public constant = CONSTANT;
  public selectedMonth: string | undefined = "";
  public monthFilter: ICyberDrop[] = [];
  public deviceId: string = '';
  public selectedMonthLabel: string | undefined = "";
  public title: string = 'CYBER RESILIENCE TREND';
  public cyberResilienceLineChart!: echarts.ECharts;
  public cyberResilienceData: number[] = [];
  public cyberResilienceLabels: string[] = [];
  public chartApiDropdownData: IDeviceCyberResilience[] = [];
  public chartApiData: IDeviceCyberResilience[] = [];

  datePickerRange: FormGroup | any;
  public minimumDate = Utils.getMonthDate(new Date(), -6);
  public maximumDate = new Date();
  loaderHeights = ['120px', '90px', '120px', '90px', '120px', '90px', '120px', '90px'];
  showPopup: boolean = false;
  public position: any = {
    left:0,
    top:0
  };
  public currentSelectedDateEvent : ICyberResilience | any= {};
  public eventData: IEventData[] = [];
  public loadEvents: boolean = false;

  public hasSingleDateResponse: boolean = true;
  public fromSelectedData: IDeviceCyberResilience | any = null;
  public toSelectedData: IDeviceCyberResilience | any = null;

  public comparefromSelectedData: IDeviceCyberResilience | any = null;
  public comparetoSelectedData: IDeviceCyberResilience | any = null;

  public fromDiffSelectedData: IDeviceCyberResilience | any = null;
  public toDiffSelectedData: IDeviceCyberResilience | any = null;

  public devicePerameterData: any = [];
  public newVulnerabilities: number = 0;
  public remediated:number = 0;
  public acceptedRisk:number = 0;
  public securityScore:number = 0;
  public result: { additions: number, deletions: number } | null = null;
  public totalCount:number = 0;
  public addition: number = 0;
  public deletion: number = 0;

  public editorOptions: MonacoEditorConstructionOptions = {
    theme: "vs",
    language: "text",
    readOnly: true,
    accessibilitySupport: 'off',
    contextmenu: false,
    wordWrap:'on',
    minimap: {
      enabled: false,
    },
    scrollbar: {
      vertical: 'visible'
    },
    scrollBeyondLastLine: false
  };
  leftFileText: string = '';
  rightFileText: string = '';
  public dropdownData: any = [];
  public toolTipOption = ToolTipConfig;
  get custom() {
    return cyberConstant.custom_range;
  }
  constructor(
    private activatedRoute: ActivatedRoute,
    private headerService: HeaderService,
    private spinnerService: SpinnerService,
    private deviceService: DeviceService,
    private formBuilder: FormBuilder,
    private _adapter: DateAdapter<any>,
    private restService: RestService,
    private responseService: ResponseService,
    private authService: AuthService) {
      this._adapter?.setLocale('en-US');
      const yesterday = new Date();
    //yesterday.setDate(yesterday.getDate() - 7);
    // Set maxDate to yesterday
    this.maxDate = yesterday;
    this.datePickerRange = new FormGroup(
      {
        startDate: new FormControl(null, Validators.required),
        endDate: new FormControl(null, Validators.required)
      },
      { validators: this.dateRangeValidator }
    );
    this.activatedRoute.params.subscribe((param: Params) => {
      if (param['id']) {
        this.resetCommomField();
        this.deviceId = param['id'];
        this.loadCyberResileance();
        // if(this.monthFilter && this.monthFilter.length) {
        //   this.loadCyberResileance();
        // } else {
        //   this.loadFilters();
        // }
      }
    })

  }
  getScoreStatus(score: number): string {
    return Utils.getRatingcolor(score);
  }
  ngOnInit(): void {
    this.headerService.getDropdown().subscribe((dropdownData: any) => {
      if(dropdownData && dropdownData?.dropdown?.length > 0){
        this.dropdownData = dropdownData?.dropdown;
      }
    })
    if (!this.dropdownData?.length) {
      this.spinnerService.show();
      this.deviceService.getDevicesDropdown((response: IDeviceListResponse) => {
        this.spinnerService.hide();
        if (response.status === 200 && response.data) {
          if (this.deviceId) {
            const currentRow = response.data.device_list.find((item: IDeviceList) => item.device_id === this.deviceId);
            this.currentSelectedDevice = currentRow;
            const deviceInfo = `${currentRow?.device_name}`;
            this.headerService.setHeading(deviceInfo,  {dropdown: response.data.device_list, type: 'device'});
          }
        }
      });
    } else {
      const currentRow = this.dropdownData?.find((item: IDeviceList) => item.id === this.deviceId);
      this.currentSelectedDevice = currentRow;
    }
  }
  ngAfterViewInit(): void {
  
  }
  public updateDate(data: IDeviceCyberResilience, type: string) {
    if(type==='from') {
      this.fromSelectedData = data;
    } else if(type==='to'){
      this.toSelectedData = data
    }
  }
  public loadFilters(): void {
    this.isChartLoad = true;
    this.isCompare = true;
    this.deviceService.loadFilters(this.fetchDurationFilterCallback);
  }
  public fetchDurationFilterCallback = (response: IDurationResponse) => {
    if (response.status === 200 && response.data?.duration) {
      this.monthFilter = Utils.sortByDesiredOrder(response.data?.duration);
      const lastSixMonths = this.monthFilter.find(duration => duration.id === 'last_6_months');
      if (lastSixMonths) {
        this.selectedMonthLabel = lastSixMonths.name;
        this.selectedMonth = lastSixMonths.id;
    } else {
      this.selectedMonthLabel = this.monthFilter[0].name;
      this.selectedMonth = this.monthFilter[0].id;
    }
      this.loadCyberResileance();
    }
  }
  fromDate!: Date;
  toDate!: Date;
  maxDate!: Date;
 
  applyDateRange() {
    if (this.datePickerRange.get("startDate").value && this.datePickerRange.get("endDate").value) {
      
      let fromDate = this.datePickerRange.get("startDate").value;
      let toDate = this.datePickerRange.get("endDate").value;
      const padWithZero = (number: number) => (number < 10 ? '0' : '') + number;

      let from = `${padWithZero(fromDate.getMonth() + 1)}/${padWithZero(fromDate.getDate())}/${fromDate.getFullYear()}`;
      let to = `${padWithZero(toDate.getMonth() + 1)}/${padWithZero(toDate.getDate())}/${toDate.getFullYear()}`;
 
      this.selectedMonthLabel = `${from} - ${to}`;
      this.selectedMonth=this.custom.id;
      this.loadCyberResileance();
      
      this.authService.setCookie('newDeviceDate', JSON.stringify({label:this.selectedMonthLabel, id: this.selectedMonth,
        startDate: this.datePickerRange.get("startDate").value, endDate: this.datePickerRange.get("endDate").value}));
    //     if (this.cyberResilienceLineChart) {
    //       this.cyberResilienceLineChart.dispose();
    //     }
  
    //  this.loadCyberResilienceChart(this.loadCyberResilienceChartCallback, this.custom.id);
    } else {
      
    }
  }
  public filterMonth(selectedFilter: ICyberDrop) {
    this.selectedMonth = selectedFilter.id;
    this.selectedMonthLabel = selectedFilter.name;
    this.datePickerRange?.reset();
    this.isChartLoad = true;
    this.isDevicePerameterLoad = true;
    this.isCompare = true;
    this.cyberResilienceData = [];
    this.cyberResilienceLabels = [];
    this.chartApiDropdownData = [];
    this.chartApiData = [];
    this.leftFileText = '';
    this.rightFileText = '';
    this.devicePerameterData = [];
    this.newVulnerabilities = 0;
    this.remediated = 0;
    this.acceptedRisk = 0;
    this.securityScore = 0;
    this.totalCount = 0;
    this.addition = 0;
    this.deletion = 0;
    if(this.selectedMonth=== this.custom.id) {
      this.isCompare = false;
      this.isDevicePerameterLoad = false;
      this.isChartLoad = false;
      this.resetCommomField()
    }
    if (this.cyberResilienceLineChart) {
      this.cyberResilienceLineChart.dispose();
    }
    if(selectedFilter &&selectedFilter.id &&   selectedFilter.id!== this.custom.id) {
      this.selectedMonth = selectedFilter.id;
      this.selectedMonthLabel = selectedFilter.name;
      this.datePickerRange.reset();
      this.authService.setCookie('newDeviceDate', JSON.stringify({label:this.selectedMonthLabel, id: this.selectedMonth,
        startDate: '', endDate: ''}));
      this.loadCyberResileance();
    }
    
  }
  public loadCyberResileance() {
    this.isChartLoad = true;
    this.isDevicePerameterLoad = true;
    this.isCompare = true;
    this.cyberResilienceData = [];
    this.cyberResilienceLabels = [];
    this.chartApiDropdownData = [];
    this.leftFileText = '';
    this.chartApiData = [];
    this.rightFileText = '';
    this.chartApiData = [];
    const startDate = this.datePickerRange.get("startDate").value;
    const endDate = this.datePickerRange.get("endDate").value;

    this.deviceService.loadCyberResileance(this.loadCyberResileanceCallback, this.deviceId, this.selectedMonth, startDate, endDate);
    this.deviceService.scanDeviceDropdown(this.scanDeviceDropdownCallback, this.deviceId, this.selectedMonth, startDate, endDate);
  }
  public loadCyberResileanceCallback = (response: IDeviceCyberResilienceResponse | any) => {
    this.isChartLoad = false;
    if (this.cyberResilienceLineChart) {
      this.cyberResilienceLineChart.dispose();
    }
    if (response.status === 200 && response.data && response.data.graph_data && response.data.graph_data.length) {
      response.data.graph_data?.forEach((obj: IDeviceCyberResilience) => {
        if(!_.isNull(obj.security_rating)) {
          this.dataMassging(obj)
          this.cyberResilienceLabels.push(this.getDateAndMonth(new Date(obj.date_time)));
          this.cyberResilienceData.push(obj.security_rating);
        }
      });
      this.chartApiData = response.data.graph_data;
      this.cyberResilienceChart();
    }
  }

  public scanDeviceDropdownCallback = (response: IDeviceCyberResilienceResponse) => {
    if (response.status === 200 && response.data && response.data.length) {
      this.chartApiDropdownData = response.data;
      response.data?.forEach((obj: IDeviceCyberResilience) => {
        obj.validDate = new Date(obj.date_added.replace('-', ''))
      });
      if(response.data.length === 1) {
        this.fromSelectedData = response.data[0];
        this.toSelectedData = response.data[0];
        this.hasSingleDateResponse = true;
      } else {
        this.fromSelectedData = response.data[1];
        this.toSelectedData =  response.data[0];
        this.hasSingleDateResponse = false;
      }
      this.fromDiffSelectedData = _.cloneDeep(this.fromSelectedData);
      this.toDiffSelectedData = _.cloneDeep(this.toSelectedData);
      
      this.getDevicePerameter();
      this.compareScans();
    } else {
      this.isDevicePerameterLoad = false;
      this.isCompare = false;
      this.resetCommomField();
    }
  }
  public getCustomRangeData() {
    if(this.validateDatePicker()) {
      this.loadCyberResileance();
    }
  }
  public validateDatePicker = ()=> {
    let validDate = false;
    const startDate = this.datePickerRange.get("startDate").value;
    const endDate = this.datePickerRange.get("endDate").value;
    if ((startDate && endDate) && (startDate < endDate)) {
      validDate = true
    }
    return validDate;
  }
  public compareData() {
    this.fromDiffSelectedData = _.cloneDeep(this.fromSelectedData);
    this.toDiffSelectedData = _.cloneDeep(this.toSelectedData);
    this.getDevicePerameter();
    this.compareScans();
  }
  public resetCommomField() {
    this.fromSelectedData = null;
    this.toSelectedData = null;
    this.devicePerameterData = [];
    this.newVulnerabilities = 0;
    this.remediated = 0;
    this.acceptedRisk = 0;
    this.securityScore = 0;
    this.totalCount = 0;
    this.addition = 0;
    this.deletion = 0;
    this.leftFileText = '';
    this.rightFileText = '';
    this.comparefromSelectedData = null;
    this.comparetoSelectedData = null;
    //this.datePickerRange.reset();
  }
  public getDevicePerameter() {
    this.isDevicePerameterLoad = true;
    this.deviceService.fetchDevicePerameter(this.fetchDevicePerameterCallback, this.fromSelectedData?.id, this.toSelectedData?.id);
  }
  public fetchDevicePerameterCallback = (response: any) => {
    this.isDevicePerameterLoad = false;
    if (response.status === 200 && response.data) {
      this.devicePerameterData = response.data.parameter_list1;
      this.newVulnerabilities = response.data.new_vul;
      this.remediated = response.data.remediation;
      this.acceptedRisk = response.data.whitelisted;
      this.securityScore = response.data.security_score;
    }
  }
  public compareScans() {
    this.isCompare = true;
    this.devicePerameterData = [];
    this.newVulnerabilities = 0;
    this.remediated = 0;
    this.acceptedRisk = 0;
    this.securityScore = 0;
    this.totalCount = 0;
    this.addition = 0;
    this.deletion = 0;
    this.leftFileText = '';
    this.rightFileText = '';
    this.deviceService.fetchCompareScans(this.fetchCompareScansCallback, this.fromSelectedData?.file_id, this.toSelectedData?.file_id);
  }
  public fetchCompareScansCallback = (response: any) => {
    this.isCompare = false;
    this.comparefromSelectedData = this.fromSelectedData;
    this.comparetoSelectedData = this.toSelectedData;

    if (response.status === 200 && response.data) {
      this.comparefromSelectedData.security_score = Math.floor(this.comparefromSelectedData.security_score);
      this.comparetoSelectedData.security_score = Math.floor(this.comparetoSelectedData.security_score)
      if(Array.isArray(response.data.content1)) {
        this.leftFileText = response.data.content1.join('\n');
      } else {
        this.leftFileText = response.data.content1
      }
      if(Array.isArray(response.data.content2)) {
        this.rightFileText = response.data.content2.join('\n');
      } else {
        this.rightFileText = response.data.content2;
      }
      this.rightFileText = this.hasSingleDateResponse ? '' : this.rightFileText
      if(this.leftFileText && this.rightFileText){
        this.result = this.getDiff(this.leftFileText, this.rightFileText);
        this.addition = this.result.additions 
        this.deletion = this.result.deletions
        this.totalCount = this.result.additions + this.result.deletions
      }
    }
  }
  public validateDate() {
    if (this.chartApiDropdownData?.length > 1 && this.toSelectedData?.validDate === this.fromSelectedData?.validDate) {
      return true
    }
    return false;
  }

  getDiff(oldText: string, newText: string) {
    const diff = diffLines(oldText, newText);
    let additions = 0;
    let deletions = 0;

    diff.forEach(part => {
      const count = part.count || 0;  
      if (part.added) {
        additions += count;
      } else if (part.removed) {
        deletions += count;
      }
    });

    return { additions, deletions };
  }
  
  public cyberResilienceChart(): void {
    const chartDom = this.cyberResilience.nativeElement;
    this.cyberResilienceLineChart = echarts.init(chartDom);
    // let option: EChartsOption;
    // option = {
    //   grid:{
    //     top: '10px',
    //     bottom: '20px',
    //     right: '25px',
    //     left: '30px'
    //   },
    //   dataset: [
    //     {
    //       id: 'dataset_raw',
    //       source: this.cyberResilienceData
    //     }
    //   ],
    //   tooltip: {
    //     trigger: 'axis'
    //   },
    //   xAxis: {
    //     type: 'category',
    //     nameLocation: 'start',
    //     boundaryGap: false,
    //     data: this.cyberResilienceLabels
    //   },
    //   yAxis: {
    //     min: 0,
    //     max: 100,
    //     offset: 0
    //   },
    //   triggerLineEvent: true,
    //   series: [
    //     {
    //       type: 'line',
    //       datasetId: 'dataset_raw',
    //       showSymbol: false,
    //       data: this.cyberResilienceData
    //     }
    //   ]
    // };
      const lastIndex = this.cyberResilienceLabels.length - 1; // Index of the last date
    const oneWeekBackIndex = Math.max(lastIndex - 6, 0); // Ensure we don't go out of bounds
    let option: EChartsOption = {
      tooltip: {
        trigger: 'axis'
      },
      grid: {
        left: '5%',
        right: '2%',
        bottom: '30%',

      },
      xAxis: {
        data: this.cyberResilienceLabels
      },
      yAxis: {},
      toolbox: {
        right: 10,
        feature: {
          // dataZoom: { yAxisIndex: 'none' },
          // restore: {},
          // saveAsImage: {}
        }
      },
      dataZoom: [
        {
          type: 'slider',
          right:'6%',
          startValue: this.cyberResilienceLabels[lastIndex],
          endValue: this.cyberResilienceLabels[oneWeekBackIndex]
        },
       {
        type: 'inside',
       }
      ],
      visualMap: {
        show: false,
        top: 50,
        right: 10,
        pieces: [
          { gt: 0, lte: 50, color: '#FF0000' },
          { gt: 50, lte: 80, color: '#FFA500' },
          { gt: 80, lte: 100, color: '#11BB57' }
        ],
        outOfRange: { color: '#999' }
      },
      series: [
        {
          type: 'line',
          data: this.cyberResilienceData,
        //  markPoint: {
            //data: this.newEventMarkers // Highlight points where new_event is true
         // }
        }
      ]
    };
    this.cyberResilienceLineChart.setOption<echarts.EChartsCoreOption>(option);
    this.cyberResilienceLineChart.on('click', (params)=> {
      
      const dataFromCurrentIndex: any = this.chartApiData[params.dataIndex];
      if(dataFromCurrentIndex && dataFromCurrentIndex.new_event) {
        this.cyberResilienceLineChart.dispatchAction({
          type: 'hideTip',
        });
        this.eventData = [];
        this.currentSelectedDateEvent = {};
        this.loadEvents = true;
        this.position.left = params && params.event?.offsetX;
        this.position.top = params && params.event?.offsetY;
        this.showPopup = true;
        this.currentSelectedDateEvent = dataFromCurrentIndex;
        document.body.classList.add('new-device-page');
              this.selectDateEvent(dataFromCurrentIndex);
      }
    });
    this.cyberResilienceLineChart.on('mouseover', (params: any)=> {
      const dataFromCurrentIndex: any = this.chartApiData[params.dataIndex];
      if(params && params.event && params.event.target) {
        if(dataFromCurrentIndex && dataFromCurrentIndex.new_event) {
          params.event.target.cursor = 'pointer';
        } else {
          params.event.target.cursor = 'not-allowed';
        }
      }
    });
    window.addEventListener('resize', ()=>{
      this.cyberResilienceLineChart.resize();
    })
  }
  public selectDateEvent(data: ICyberResilience | any) {
    if(data.new_event) {
      let req: any = {
        date: data.date_time,
        device_uuid: this.deviceId
      };
      if(Intl.DateTimeFormat().resolvedOptions().timeZone){
        req.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      }
      this.restService.postApi(END_POINTS.DAILY_EVENT, req).subscribe({
        next: (response) => {
          this.selectDateEventCallback(this.responseService.successResponse(response));
        },
        error: (err) => {
          this.selectDateEventCallback(this.responseService.errorResponse(err));
        },
      })

    }
    
  }
  public selectDateEventCallback(response: any) {
    this.loadEvents = false;
    if (response.status === 200 && response.data) {
      this.eventData = response.data.event_data
    }
  }
  public getDateAndMonth(date: Date): string {
    const dt = new Date(date);
    const month = dt.toLocaleString('default', { month: 'short' });
    return dt.getDate() + ' ' + month;
  }
  public overlayClick() {
    document.body.classList.remove('new-device-page');
    this.showPopup = false;
  }
  dataMassging(data:any){
    data.security_rating = parseFloat(data.security_rating.toFixed(2));
  }
  public dateRangeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const group = control as FormGroup;
    const from = group.get("startDate")?.value;
    const to = group.get("endDate")?.value;
  
    if (!from || !to) {
      return { invalidRange: true };  // Invalid if either date is missing
    }
  
    return new Date(from).valueOf() <= new Date(to).valueOf() ? null : { invalidRange: true };
  };
  onDateInput(event: any, controlName: string): void {
    const inputDate = event.target.value;
    const parsedDate = this.parseDate(inputDate);
  
    if (parsedDate) {
      this.datePickerRange.get(controlName)?.setValue(parsedDate);
      this.datePickerRange.get(controlName)?.setErrors(null); // Clear previous errors
    } else {
      this.datePickerRange.get(controlName)?.setErrors({ invalidDate: true });
    }
  }
  
  parseDate(dateString: string): Date | null {
    const parts = dateString.split('/');
    if (parts.length === 3) {
      const month = parseInt(parts[0], 10) - 1; // Months are 0-based in Date object
      const day = parseInt(parts[1], 10);
      const year = parseInt(parts[2], 10);
  
      // Simple validation for date parts
      if (month >= 0 && month <= 11 && day > 0 && day <= 31 && year >= 1900) {
        const date = new Date(year, month, day);
        // Check if the created date matches the input
        if (date.getDate() === day && date.getMonth() === month && date.getFullYear() === year) {
          return date;
        }
      }
    }
    return null; // Return null if parsing fails
  }
  
  public getPositiveValue(value: number | null){
    let val = value ?? 0;
    if (val !== 0) {
      val = Math.abs(val);
    } 
    return val?val:null;
  }

  setDates() {
    const savedSelectedCookies = JSON.parse(this.authService.getCookie('newDeviceDate') || '{}');
      if(savedSelectedCookies.startDate) {
        this.datePickerRange.controls['startDate'].setValue(new Date(savedSelectedCookies?.startDate || ''));
      } else {
        this.datePickerRange.reset();
      }
      if(savedSelectedCookies.endDate) {
        this.datePickerRange.controls['endDate'].setValue(new Date(savedSelectedCookies?.endDate || ''));
      } else {
        this.datePickerRange.reset();
      }
   
  }
}
