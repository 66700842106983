import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';


@Component({
    selector: 'app-compliance',
    templateUrl: './compliance.component.html',
    styleUrls: ['./compliance.component.scss']
  })
  export class ComplianceComponent {
    public params!: ICellRendererParams;
  
    agInit(params: ICellRendererParams): void {
      this.params = params;
    }
  }