import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[appIsEllipsisActive]'
})
export class IsEllipsisActiveDirective implements AfterViewInit {
  
  constructor(private elementRef: ElementRef) {}

  

  ngAfterViewInit(): void {
      const element = this.elementRef.nativeElement;

      if(element.offsetWidth < element.scrollWidth){
        element.title = element.value;
      };

      // if (this.isSafariBrowser()) {
      //   this.elementRef.nativeElement.removeAttribute('options');
      //   this.elementRef.nativeElement.removeAttribute('tooltip');
      //   this.matTooltip.disabled = true;
      //   this.elementRef.nativeElement.setAttribute('title','');
      // } else {
      //   this.elementRef.nativeElement.setAttribute('title','');
      // }

  }
  private isSafariBrowser(): boolean {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }
}
