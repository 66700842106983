import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-severity',
  templateUrl: './severity.component.html',
  styleUrls: ['./severity.component.scss']
})
export class SeverityComponent implements ICellRendererAngularComp{
  public params!: ICellRendererParams;
  filterMenuOpen = false;
  
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
  public addBadgeClass = (type: string) => {
    let className = 'sunsetorange-bg';
    if (type === 'Critical') {
      className = 'critical';
    } else if (type === 'High') {
      className = 'high';
    } else if (type === 'Medium') {
      className = 'medium';
    } else if (type === 'Low') {
      className = 'low';
    } else if (type === 'whitelist') {
      className = 'bg-tertiary';
    }
    return className;
  }

}
