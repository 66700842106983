import { Component, Input, SimpleChanges } from '@angular/core';
import { CVE_PAGE } from '../../constants/constant-data';

@Component({
  selector: 'app-cve-details',
  templateUrl: './cve-details.component.html',
  styleUrls: ['./cve-details.component.scss']
})
export class CveDetailsComponent {
  @Input() public cveDetails: any;
  @Input() public assetsData: any = [];
  @Input() public assetLoader: boolean = false;
  get str() {
    return CVE_PAGE;
  }
  constructor() {
    
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['cveDetails'] &&
      changes['cveDetails']?.currentValue != changes['cveDetails']?.previousValue) {
        this.cveDetails = changes['cveDetails'].currentValue;
    }
    if (changes && changes['assetsData'] &&
      changes['assetsData']?.currentValue != changes['assetsData']?.previousValue) {
        this.assetsData = changes['assetsData']?.currentValue;
    }
  }
}
