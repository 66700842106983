import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Utils } from 'src/app/modules/shared/utils';
import { DeviceManagementComponent } from '../device-management.component';

@Component({
  selector: 'app-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.scss']
})
export class ScoreComponent implements ICellRendererAngularComp{
  public params!: ICellRendererParams;
  public get isDecommissioned() {
    return this.deviceComp.isDecommissioned;
  }
  constructor(private deviceComp: DeviceManagementComponent) {
    
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;

  }
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
  getScoreStatus(score: number): string {
    return Utils.getRatingBadge(score);
  }
  public isSuccess(item: any): boolean {
    return this.deviceComp.isSuccess(item);
  }
  public isFailed(item: any): boolean {
    return this.deviceComp.isFailed(item);
  }
  public isCanceled(item: any): boolean {
    return this.deviceComp.isCanceled(item);
  }
}