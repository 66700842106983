import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FilterService } from '../../shared/services/filter.service';

@Component({
  selector: 'app-save-change-dialog',
  templateUrl: './save-change-dialog.component.html',
  styleUrls: ['./save-change-dialog.component.scss']
})
export class SaveChangeDialogComponent {
  fileCount!: number;
  constructor(
    public dialogRef: MatDialogRef<SaveChangeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private filterService: FilterService) {
    this.fileCount = data.fileCount || 0;
  }

  onClose(callBack: Function | undefined,label?: string | undefined) {
    if (callBack) {
      
        callBack();
        if(label == 'Go Back'){
          this.onGoBack();
        }
    }
}

  onGoBack(): void {
    this.dialogRef.close(false);
  }

  onCancelUpload(): void {
    //this.filterService.closeSidenav();
    this.dialogRef.close(true);
  }

}
