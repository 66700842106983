<div class="assets-info-section">
    <div class="search-bar">
        <mat-form-field appearance="fill">
            <mat-label>Search Assets</mat-label>
            <input matInput placeholder="" autocomplete="off" class="search-input" autocomplete="off" type="text"
                (input)="filterAssetsField()" #searchField>
            <mat-icon class='search-icon' matPrefix>search</mat-icon>
        </mat-form-field>
    </div>
    <app-ag-grid #myGrid [agDeviceColumnDefs]="agDeviceColumnDefs" [data]="assetsData"
        [gridClass]="'cve-details-assets-table ag-grid-loader-bg'" [loader]="assetLoader">
    </app-ag-grid>
</div>