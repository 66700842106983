<div class="netsec-modelbox">
    <h2 mat-dialog-title class="title">{{data.title}}</h2>
    <div class="modelbox-body">
        <p>{{data.content}}</p>
    </div>
    <div class="modelbox-footer"  >
        <button *ngFor="let btn of data.buttons"  [ngClass]="btn?.class" 
                    (click)="onClose(btn?.callBack,btn.label)" mat-dialog-close>{{btn.label}}</button>
           
    </div>
</div>
