import { EventEmitter, Inject, Injectable } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { DOCUMENT } from "@angular/common";
import { Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  sidenav: any = {};
  public filter = new EventEmitter<string>();
  public hideDetailsSubject = new Subject<boolean>();
  hideDetails$ = this.hideDetailsSubject.asObservable();
  public profileFormSubject = new Subject<boolean>();
  profileForm$ = this.hideDetailsSubject.asObservable();
  public isFilterTouch = false;
  public sidenavCloseEvent = new EventEmitter<void>();
  constructor(@Inject(DOCUMENT) private document: Document) {
    
  }
  setSidenav(nav: MatSidenav, name: string) {
    this.sidenav[name] = nav;
    nav.openedChange.subscribe(opened => {
      if (!opened) {
        this.sidenavCloseEvent.emit();
      }
    });
  }

  toggleSidenav(name: string): void {
    const navItem: MatSidenav = this.sidenav[name];
    navItem.toggle();
    if(navItem.opened){
      this.document.body.classList.add('overflow-hidden');
    } else {
      this.document.body.classList.remove('overflow-hidden');
    }
  }

  closeSidenav() {
    this.document.body.classList.remove('overflow-hidden');
    for (const name in this.sidenav) {
      this.sidenav[name].close();
    }
    return true;
  }
}
