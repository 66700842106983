import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cyberresilience-dwonload-report',
  templateUrl: './cyberresilience-dwonload-report.component.html',
  styleUrls: ['./cyberresilience-dwonload-report.component.scss']
})
export class CyberresilienceDwonloadReportComponent {
  selectedFormat: any;
  cybergraph: FormGroup;
  fromDateProp: any;
  fromDateValue: any;
  toDateValue: any;
  today: Date = new Date();
  dateError: boolean = false; // Error message for date validation


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder,private dialogRef: MatDialogRef<any>) {
   
    this.cybergraph = this.formBuilder.group({
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
  
    });
    this.selectedFormat = 'excel';
    this.fromDateProp = data.fromDateProp;
  }

  onClose(callBack: Function | undefined,label:any) {
    if(label === 'Cancel'){
      this.dialogRef.close();
      return;
    }
    this.fromDateValue = this.cybergraph.get('fromDate')?.value as Date;
    this.toDateValue = this.cybergraph.get('toDate')?.value as Date;

    if (this.fromDateValue && this.toDateValue) {
      const fromDate = new Date(this.fromDateValue);
      const toDate = new Date(this.toDateValue);     

      if (fromDate > toDate) {
        this.dateError = true;
        return; // Prevent closing the dialog
      }
    }

    if (callBack) {
      this.dialogRef.close();
      const formatFromDate = this.fromDateValue ? new Date(this.fromDateValue + 'Z').toISOString().split('T')[0] : null;
      const formatToDate = this.toDateValue ? new Date(this.toDateValue + 'Z').toISOString().split('T')[0] : null;
      callBack(formatFromDate, formatToDate, this.selectedFormat);
    }
  }
}
