import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';


@Component({
    selector: 'app-risk',
    templateUrl: './risk.component.html',
    styleUrls: ['./risk.component.scss']
  })
  export class RiskComponent {
    public params!: ICellRendererParams;
  
    agInit(params: ICellRendererParams): void {
      this.params = params;
    }
  
  }