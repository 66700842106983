import { Component, ElementRef, HostListener, Input, SimpleChanges, ViewChild } from '@angular/core';
import { ResponseService } from '../../services/response.service';
import { RestService } from '../../services/rest.service';
import { ISecurityScoreResponse } from './security.model';
import { SharedService } from '../../services/shared.service';
import { CONSTANT } from '../../constants/constant-data';
import { SecurityInformationService } from '../../services/security-information.service';
import {
  ApexNonAxisChartSeries, ApexPlotOptions, ApexChart,
  ApexLegend,
  ChartComponent,
  ApexDataLabels,
  ApexTooltip
} from "ng-apexcharts";
import { Utils } from '../../utils';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: any[];
  colors: any[];
  legend: ApexLegend;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  tooltip: ApexTooltip;
};

@Component({
  selector: 'app-security-score',
  templateUrl: './security-score.component.html',
  styleUrls: ['./security-score.component.scss']
})
export class SecurityScoreComponent {
  @Input() title: string = '';
  @Input() apiName: string = '';
  @Input() type:number = 1;
  @ViewChild("security-score-chart") chart!: ChartComponent;
  public chartOptions!: Partial<ChartOptions> | any;
  public securityValue: number = 0;
  public securityScore:any=0;
  public actualScoreValue: number = 0;
  public securityScoreChartData: number[] = [];
  public securityScoreChartDataWithPercentage: number[] = [];
  public securityScoreLegends: string[] = ['Security Score', ];
  public securityScoreColor: string[] = [ '#11BB57'];
  public isSpinner: boolean = false;
  public constant = CONSTANT;
  public isGraphDataReceived: boolean = false;
  public selectSecurityImage = '';
  public showOldGraph:boolean = false;
  public showOldSkeleton:boolean = false;
  constructor(
    private responseService: ResponseService,
    private restService: RestService,
    private sharedService: SharedService,
    private securityInformationService: SecurityInformationService
    ) {

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['type'] && changes['type'].currentValue !== changes['type'].previousValue) {
      if (this.type === 2) {
        this.showOldSkeleton = true;
      } 
    }
  }
  
  ngOnInit(): void {
    if (this.apiName) {
      this.isSpinner = true;
      if (this.type === 2) {
        this.showOldGraph = true;
      } 
      this.securityInformationService.loadSecurityScore(this.apiName, this.loadSecurityScoreCallback);
      this.sharedService.clearData();
    }
  }
  public loadSecurityScoreCallback = (response: ISecurityScoreResponse) => {
    this.isSpinner = false;
    this.securityScoreChartData = [];
    // reset graph values when we have updated response data
    this.securityScoreChartDataWithPercentage = [];
    if (this.chartOptions) {
      this.chartOptions = ''
    }
    if (response.status === 200 && response.data) {
      this.securityValue = response.data.security_Score;
      this.securityScore = response.data.security_Score;
      this.securityValue = Math.floor(this.securityValue)
      this.actualScoreValue = response.data.actual_score;
      this.securityScoreChartData.push(response.data.security_Score);
      this.securityScoreChartData.push(response.data.passed_parameters);
      this.securityScoreChartData.push(response.data.failed_parameters);
      this.isGraphDataReceived = true;
      //const secutiryScorePercentage = (response.data.security_Score/response.data.total_score) * 100;
      const passedParaPercentage = (response.data.passed_parameters/response.data.total_parameters) * 100;
      const failedParaPercentage = (response.data.failed_parameters/response.data.total_parameters) * 100;
      if (this.securityValue < 50) {
        this.securityScoreColor = ['#FF0000']; 
        this.selectSecurityImage = 'security_red.gif';
    } else if (this.securityValue >= 50 && this.securityValue < 80) {
        this.securityScoreColor = ['#FFA500']; 
        this.selectSecurityImage = 'security_orange.gif';
    } else {
        this.securityScoreColor = ['#11BB57']; 
        this.selectSecurityImage = 'security_green.gif';
    }
      this.securityScoreChartDataWithPercentage.push(this.securityValue);
      if(this.type === 2){
        this.securityChart();
      }
    }
  }
  /**
   * securityChart method used to config options and dispaly the graph
   */
  public securityChart(): void {
    this.chartOptions = {
      series: this.securityScoreChartDataWithPercentage,
      chart: { 
        type: "radialBar",
        height: 230
      },
      plotOptions: {
        radialBar: {
          height:350,
          offsetY: 0,
          offsetX: 0,
          startAngle: 0,
          
          grid: {
            padding: {
                top: 0,
                bottom: -15
            }
        },
          hollow: {
            size: "30%",
            background: "transparent",
          },
          dataLabels: {
            position: 'bottom',
            name: {
              show: false,
              fontSize: "10px"
            },
            value: {
              show: true,
              offsetY: 5,
              color: '#1E2036',
              fontSize: '12',
              fontWeight: 'bold',
              fontFamily: 'Roboto',
              formatter: (val: any): any => {
                if (Math.trunc(this.securityScoreChartDataWithPercentage[0]) === parseInt(val)) {
                  return this.securityScoreChartData[0];
                }
                if (Math.trunc(this.securityScoreChartDataWithPercentage[1]) === parseInt(val)) {
                  return this.securityScoreChartData[1];
                }
                if (Math.trunc(this.securityScoreChartDataWithPercentage[2]) === parseInt(val)) {
                  return this.securityScoreChartData[2];
                }
              }
            },
            total: {
              show: true,
              label: "",
              position: 'center',
              color: '#1E2036',
              fontSize: '12',
              fontWeight: 'bold',
              fontFamily: 'Roboto',
              formatter: (): any => {
                return Math.round(this.securityValue);
              }
            }
          }
        }
      },
      colors: this.securityScoreColor,
      labels: this.securityScoreLegends,
      responsive: [
        {
          breakpoint: 480,
          options: {
            responsive: true,
            chart: {
              width: 400
            },
          }
        }
      ]
    };
  }

  @HostListener('window:resize', ['$event'])
    onResize(event: any) {
      if(this.securityChart){
        this.securityChart();
      }
    }
    getScoreStatus(score: number): string {
      return Utils.getRatingcolor(score);
    }
}
