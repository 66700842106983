import { Component } from '@angular/core';


@Component({
  selector: 'app-vulnerability-assets',
  templateUrl: './vulnerability-assets.component.html',
  styleUrls: ['./vulnerability-assets.component.scss']
})
export class VulnerabilityAssetsComponent {
  constructor() {
  }
}
