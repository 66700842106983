<div class="ag-center role-container">
    <span  appSafariEllipsis 
        class="text_ellipsis">{{params.data.role}}</span>
    <button [disabled]="(userData?.organization_email || '') === (params?.data?.organization_email || '') 
                     && params.data.role=== admin" mat-icon-button [matMenuTriggerFor]="extendMenu" 
        aria-label="role">
        <span class="center" *ngIf="!((userData?.organization_email || '') === (params?.data?.organization_email || '') 
                        && params.data.role=== admin)">
            <mat-icon>arrow_drop_down</mat-icon>
        </span>
    </button>
</div>
<mat-menu #extendMenu="matMenu" class="mat-menu-scroll">
    <ng-container *ngFor="let role of roleItems">
        <button mat-menu-item *ngIf="(role?.name || '')!==(params?.data?.role || '')" (click)="updateRoleInSelectedUser(params.data, role)"
            matTooltipDisabled="{{disableTooltip}}" matTooltip="{{role.name}}">
            {{role.name}}
        </button>
    </ng-container>
</mat-menu>