<div class="history-details-popup" *ngIf="whitelistHistoryData">
    <div class="history-details-header">
        <p class="header-title" [ngClass]="addBadgeClass(whitelistHistoryData?.whitelist_info?.vulnerability||'')">{{whitelistHistoryData?.whitelist_info?.paramter}}</p>
        <p class="header-subtitle">{{whitelistHistoryData?.whitelist_info?.desctiption}}</p>
        <span class="popup-close-btn"><button mat-button [mat-dialog-close]="true">
            <mat-icon>close</mat-icon></button></span>
    </div>
    <div class="history-details-content">
        <div class="history-telnet-list scroll">
            <div class="history-telnet-item" *ngFor="let item of whitelistHistoryData?.whitelist_info?.device_list">
                <div class="history-telnet-itemleft">
                  <span class="accepted" *ngIf="item.whitelist else nonWhitelist">A</span>
                  <ng-template #nonWhitelist>
                    <span class="bg-green"><mat-icon>minimize</mat-icon></span>
                  </ng-template>
                </div>
                <div class="history-telnet-itemcenter">
                  <p class="text_ellipsis whitelisted-devices">{{ item.host_name}}({{makeFirstLetterCapital(item.vendor)}} - {{makeFirstLetterCapital(item.device_type)}} )</p>
                </div>
                <div class="history-telnet-itemright">
                  <span class="check" *ngIf="item.passed else failed"><mat-icon>check</mat-icon></span>
                  <ng-template #failed>
                    <span class="bg-critical"><mat-icon>close</mat-icon></span>
                  </ng-template>
                </div>
              </div>

        </div>
        <div class="history-person-details">
           <div class="person-details-header">
                <p><span>{{whitelistHistoryData?.whitelist_info?.vulnerability}}</span>
                  <span>{{whitelistHistoryData?.whitelist_info?.benchmark.join(', ')}}</span><span>{{whitelistHistoryData?.whitelist_info?.device_count}} Assets</span></p>
           </div>
           <div class="person-details-info">
                <div class="profile-details">
                    <div class="profile-pic">
                      <app-profile-icon [userData]="profileData" [imageBase64]="this.imageBase64"></app-profile-icon>
                    </div>
                    <div class="profile-name">
                      <p class="profile-name-title">
                        <span>{{whitelistHistoryData?.whitelist_info?.first_name}}</span>
                        <span>&nbsp;</span>
                        <span>{{whitelistHistoryData?.whitelist_info?.last_name}}</span>
                    </p>                    
                        <p  class="profile-name-date">{{whitelistHistoryData?.whitelist_info?.date_time}} </p>
                    </div>
                </div>
                <div class="profile-details-disc scroll">
                    <P class="profile-disc-label">Reason</P>
                    <p class="profile-disc-text">{{makeFirstLetterCapital(whitelistHistoryData?.whitelist_info?.reason)}}</p>
                </div>
           </div>
        </div>
    </div>
</div>