<div class="userfilter-box">
    <div class="user-filtersearch">
      <div (click)="$event.stopPropagation()" class="search-bar user-search">
        <mat-form-field appearance="fill" class="border-field">
          <input #searchField  matInput placeholder="Search.." 
          [ngModel]="searchText" (input)="onSearchInputChange($event)"  autocomplete="off" type="text">
          <mat-icon class='search-icon' matPrefix>search</mat-icon>
        </mat-form-field>
      </div>
      <div (click)="apply()"><button [disabled]="is_clicked" mat-button class="btn apply-btn">Apply</button></div>
    </div>
    <div *ngIf="!isApiCalled" class="item user-filter-loader">
      <ngx-skeleton-loader class="rhs-content-loader checkbox-box-loader" *ngFor="let row of [].constructor(8)" count="2"></ngx-skeleton-loader>
    </div>
    <div *ngIf="isApiCalled" class="userfiltr-list scroll" infinite-scroll [infiniteScrollDistance]="1" [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="onScroll()">
      <div *ngIf="userList.length > 0; else noDataMessage">
      <div *ngIf="!hideSelectAll">
       <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="selectAllState" (change)="selectAllUsers($event)" >
        <label class="text_ellipsis">Select All</label>
       </mat-checkbox>
      </div>
      <div *ngFor="let user of userList">
        <mat-checkbox (click)="$event.stopPropagation()" [(ngModel)]="user.selected" (change)="selectUser(user)">
            <label class="text_ellipsis" [tooltip]="user.firstname + ' ' + user.lastname + ' (' + user.user + ')'"> 
              {{ user.firstname }} {{ user.lastname }} (<span class="user-mail">{{ user.user }}</span> )
            </label> 
        </mat-checkbox>
      </div>
    </div>
    <ng-template #noDataMessage>
      <p class="no-data">{{noDataMsg}}</p>
    </ng-template>
      <div class="item user-filter-loader" *ngIf="isPreloaderEligible()">
        <ngx-skeleton-loader class="rhs-content-loader checkbox-box-loader" *ngFor="let row of [].constructor(2)" count="2"></ngx-skeleton-loader>
      </div>
    </div>
  </div>