import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
import { UsersComponent } from '../users.component';
import { UserManagementService } from 'src/app/modules/shared/services/user-management.service';
import { IUserDetail } from 'src/app/modules/shared/model/profile/profile';
import { User } from '../model/user.model';
import { IDialog } from 'src/app/modules/shared/model/Dialog.model';
import { UserDeleteComponentComponent } from '../user-delete-component/user-delete-component.component';
import { COMPOENT_NAME, CONSTANT, CONTENT_MESSAGE, GET_MENU_ITEM_DATA, ROLES_CONTENT_PROPS } from 'src/app/modules/shared/constants/constant-data';
import { DialogService } from 'src/app/modules/shared/services/dialog.service';

@Component({
  selector: 'app-menu-options',
  templateUrl: './menu-options.component.html',
  styleUrls: ['./menu-options.component.scss']
})
export class MenuOptionsComponent {
  public params!: ICellRendererParams;
  userData: IUserDetail = {};
  public openedMenuId: string | null = null;
  public edit = CONSTANT.EDIT;
  public delete = CONSTANT.DELETE;
  public reactivate = CONSTANT.REACTIVATE;
  public deactivate = CONSTANT.DEACTIVATE;
  public resendPasswordLink = CONSTANT.RESEND_LINK;
  constructor(private authService: AuthService,
    private dialogService: DialogService,
    private userComp: UsersComponent
  ) {
    if(userComp.userData){
    this.userData = userComp.userData
    }
  } 
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  onMenuOpen(id: string) {
    this.openedMenuId = id;
}

onMenuClose() {
    this.openedMenuId = null;
}

onOptionsSelection(option: string, currentUser: User): void {
    
  if (option.toLocaleLowerCase() === this.edit) {
    this.userComp.editUserPopup(currentUser);
  }
  else {
    let hasPermission = false;
    if(option.toLocaleLowerCase() === this.delete) {
      hasPermission = this.permissionCheck(this.componentName.DELETE_USER, this.permissionProps.DELETE_USER);
    } else if(option.toLocaleLowerCase() === this.deactivate || option.toLocaleLowerCase() === this.reactivate){
      hasPermission = this.permissionCheck(this.componentName.ACTIVATE_DEACTIVATE, this.permissionProps.ACTIVATE_DEACTIVATE);
    }else if(option.toLocaleLowerCase() === this.resendPasswordLink){
      hasPermission = this.permissionCheck(this.componentName.RESET_PASS_LINK, this.permissionProps.RESET_PASS_LINK);
    }
    const payload: IDialog = {
      component:UserDeleteComponentComponent,
      width: '360px',
      data: { 
        content:CONTENT_MESSAGE(option, currentUser),
        title: option,
        setCallbackFunction: (result: any) => {
          this.onOptionCall(result,option,currentUser)
        },
        menuData:GET_MENU_ITEM_DATA(option,currentUser)
      },
    }
    this.dialogService.openDialog(payload);
  }
}
onOptionCall(result:any,option:string,currentUser: User){
  if (result) {
    this.onOptionsSelectionPopupCallback(option, currentUser);
  }
}
onOptionsSelectionPopupCallback = (option: string, currentUser: User) => {
  if (option.toLocaleLowerCase() === this.delete) {
    this.userComp.deleteUser(currentUser);
  } else if (option.toLocaleLowerCase() === this.deactivate) {
    this.userComp.activateDeactivateUser(currentUser, false)
  } else if (option.toLocaleLowerCase() === this.reactivate) {
    this.userComp.activateDeactivateUser(currentUser, true);
  } else if (option.toLocaleLowerCase() === this.resendPasswordLink) {
    this.userComp.resedPasswordLink(currentUser)
  }
}

public permissionCheck(title: string, props: string) {
  return this.authService.permissionMsg(title, props);
}
public hasPermission(roleName: string): boolean {
  return !!this.authService.isRoleAccess(roleName);
}

get permissionProps() {
  return ROLES_CONTENT_PROPS;
}
get componentName() {
  return COMPOENT_NAME;
}

}
