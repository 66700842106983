import { Component } from '@angular/core';

@Component({
  selector: 'app-whitelist-accepted',
  templateUrl: './whitelist-accepted.component.html',
  styleUrls: ['./whitelist-accepted.component.scss']
})
export class WhitelistAcceptedComponent {
  public params: any;
  agInit(params: any): void {
    this.params = params;
  }
  getAcceptedRiskData(acceptedrisk: boolean) {
    return acceptedrisk ? "Accepted" : null;
  }
}
