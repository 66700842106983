import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot,  CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UnsaveChangeGuard implements CanDeactivate<CanComponentDeactivate> {
  constructor(private dialog: MatDialog) {}

  canDeactivate(component: CanComponentDeactivate, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.isNavigatingToLogin(currentState, nextState)) {
      return true; 
    }
    // Check if the component has a canDeactivate method
    if (component.canDeactivate) {
      // Call the component's canDeactivate method
      return component.canDeactivate();
    }
    return true;
  }
  private isNavigatingToLogin(currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): boolean {
    if (nextState) {
      const nextUrl = nextState.url;
      return nextUrl.includes('/login');
    }
    return false;
  }
}
