import { Component, Input } from '@angular/core';
import { Utils } from '../../utils';

@Component({
  selector: 'app-profile-icon',
  templateUrl: './profile-icon.component.html',
  styleUrls: ['./profile-icon.component.scss']
})
export class ProfileIconComponent {
  @Input() imageSrc!: any;
  @Input() imageBase64!:any;
  @Input() userData?: {
    firstname: string | null |undefined;
    lastname: string | null | undefined;
  };
  displayImageBlocker:any;

  constructor(){
  }

  getNameCombination(firstName:any, lastName:any | undefined){
    return Utils.getNameCombination(firstName, lastName)
  }
}

