import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './components/admin/admin.component';
import { DeviceManagementComponent } from './components/device-management/device-management.component';
import { OrganisationComponent } from './components/organisation/organisation.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ToolManagementComponent } from './components/tool-management/tool-management.component';
import { UsersComponent } from './components/users/users.component';
import { SecurityInformationComponent } from './components/security-information/security-information.component';
import { AuthGuardService } from '../shared/services/auth-guard.service';
import { ROLES_ROUTE } from '../shared/constants/constant-data';
import { NewDeviceComponent } from './components/device-management/new-device/new-device.component';
import { UnsaveChangeGuard } from '../shared/unsave-change.guard';
import { CredentionalsManagementComponent } from './components/credentionals-management/credentionals-management.component';
import { VpnComponent } from './components/vpn/vpn.component';
 

export const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuardService],
    title: '',
    children: [
      {
        path: '',
        redirectTo: 'profile',
        pathMatch: 'full',
        title: 'profile'
      },
      {
        path: 'profile',
        component: ProfileComponent,
        title: 'Admin Profile',
      },
      {
        path: 'organisation',
        component: OrganisationComponent,
        title: 'Organisation Profile',
      },
      {
        path: 'role-management',
        component:ToolManagementComponent,
        title: 'Role Management',
        canActivate: [AuthGuardService],
        canDeactivate: [UnsaveChangeGuard],
        data: {
          role: ROLES_ROUTE.ROLE_MGNT
        }
      },
      {
        path: 'user-management',
        component:UsersComponent,
        title: 'User Management',
        canActivate: [AuthGuardService],
        data: {
          role: ROLES_ROUTE.USER_MGNT
        }
      } ,
      {
        path: 'asset-compare/:id',
        component:NewDeviceComponent,
        title: 'Asset Compare',
      },
      {
        title: 'Asset Management',
        path: 'asset-management',
        canActivate: [AuthGuardService],
        component:DeviceManagementComponent,
        data: {
          isFilter:  true,
          role: ROLES_ROUTE.DEVICE_MGNT
        },
      },
      {
        title: 'Security Information',
        path: 'security-information',
        component:SecurityInformationComponent,
        canActivate: [AuthGuardService],
        data: {
          isFilter:  true,
          role: ROLES_ROUTE.SECURITY_INFO
        }
      },
      {
        path: 'credential',
        component: CredentionalsManagementComponent,
        title: 'Credential Management',
        canActivate: [AuthGuardService],
        data: {
          role: ROLES_ROUTE.CREDENTIAL_MANAGER
        }
      },
      {
        path: 'vpn',
        component: VpnComponent,
        title: 'VPN',
        canActivate: [AuthGuardService]
      }
    ]
  }
] 
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
