import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import { ResponseService } from '../../services/response.service';
import { RestService } from '../../services/rest.service';
import { IVulnarability, IVulnarabilityResponse } from './vulnerability.model';
import { SharedService } from '../../services/shared.service';
import { CONSTANT } from '../../constants/constant-data';
import * as echarts from 'echarts';
import { Utils } from '../../utils';
import { ControlPanelService } from '../../services/control-panel.service';
@Component({
  selector: 'app-vulnerability',
  templateUrl: './vulnerability.component.html',
  styleUrls: ['./vulnerability.component.scss']
})
export class VulnerabilityComponent {
  onNavigateTopage(type: string, role: {}) {
    throw new Error();
  }
  @Input() title: string = '';
  @Input() apiName: string = '';
  @Input() link: boolean = false;
  @ViewChild('vulnarability') vulnarability!: ElementRef;
  @Input() vulnerabilityGraphSec: string = 'pie-chart';
  public vulnarabilityChartCanvas: HTMLCanvasElement;
  public vulnarabilityChartCtx: CanvasRenderingContext2D | [];
  public vulnarabilityResponse: IVulnarability[] = []
  public vulnarabilityLegends: string[] = [];
  public vulnarabilityColor: string[] = ['#F53B3B', '#F7941F', '#BDB615', '#00A61B'];
  public vulnarabilityChartData: number[] = [];
  public isSpinner: boolean = false;
  public vulChart!: Chart;
  public constant = CONSTANT;
  noDataMsg: String = CONSTANT.NO_DATA_MSG;
  public myChart: any = null;
  public vulnarabilitiesTotalCount: number = 0;
  public getFirstLetter = Utils.getFirstLetter;
  vulnarabilitiesGraphDataArr: any = [];
  public totalCount: number = 0;
  severityLevels: ('critical' | 'high' | 'medium' | 'low')[] = ['critical', 'high', 'medium', 'low'];
  data = {
    critical: 0,
    high: 0,
    medium: 0,
    low: 0,
    total: 0,
  };
  constructor(
    private responseService: ResponseService,
    private restService: RestService,
    private sharedService: SharedService,
    private controlPannelService: ControlPanelService
  ) {
    this.vulnarabilityChartCanvas = this.vulnarability?.nativeElement;
    this.vulnarabilityChartCtx = this.vulnarabilityChartCanvas?.getContext("2d") as CanvasRenderingContext2D;
    this.controlPannelService.vulFilter.subscribe((res:any)=>{
      if(res && this.apiName){
        this.totalCount = 0;
        this.data = {
          critical: 0,
          high: 0,
          medium: 0,
          low: 0,
          total: 0,
        };
        this.loadVulnarabilityChart(this.loadVulnarabilityChartCallback);
      }
    })
  }
  ngOnInit(): void {
    if (this.apiName) {
      this.loadVulnarabilityChart(this.loadVulnarabilityChartCallback);
    }
  }
  ngAfterViewInit(): void {
    this.vulnarabilityChartCanvas = this.vulnarability?.nativeElement;
    this.vulnarabilityChartCtx = this.vulnarabilityChartCanvas?.getContext("2d") as CanvasRenderingContext2D;
  }
  public loadVulnarabilityChart(callback: Function): void {
    this.isSpinner = true;
    this.restService.getApi(this.apiName).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }
  public loadVulnarabilityChartCallback = (response: any) => {
    this.isSpinner = false;
    if (response.status === 200 && response.data) {
      this.vulnarabilityResponse = response.data;
      if(this.title === 'Vulnerabilities'){
        this.totalCount = response.data.reduce((sum: any, item: any) => sum + item.count, 0);
        this.vulnarabilityResponse.forEach(item => {
          switch (item.vulnerabilities_name) {
            case "Critical":
              this.data.critical = item.count;
              break;
            case "High":
              this.data.high = item.count;
              break;
            case "Medium":
              this.data.medium = item.count;
              break;
            case "Low":
              this.data.low = item.count;
              break;
          }
        });
      } 
      else {
        this.totalCount = response.data.count;
        this.data = response.data
      }
    }
  }
}
