import { Component } from '@angular/core';
import { ICellRendererAngularComp, ITooltipAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, ITooltipComp, ITooltipParams } from 'ag-grid-community';

@Component({
  selector: 'app-vulnerability-tooltip',
  templateUrl: './vulnerability-tooltip.component.html',
  styleUrls: ['./vulnerability-tooltip.component.scss']
})
export class VulnerabilityTooltipComponent implements ITooltipAngularComp{
  public params!: ITooltipParams;
  agInit(params: ITooltipParams): void {
    this.params = params;
  }
  refresh(params: ITooltipParams): boolean {
    this.params = params;
    return true;
  }
}