<app-ag-grid
#myGrid
[agDeviceColumnDefs]="agScanColumnDefs"
[data]="dataSource?.data"
[loader]="isApiCallDone"
[gridClass]="'list-details-table ag-grid-tabel'"
[restrictCheckBoxSelection]="false"
[rowHeight]="rowHeight"
(onRowSelectedChange)="clickRow($event[0])"
[tableKey]="tableKey"></app-ag-grid> 

