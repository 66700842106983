<div class="netsec-modelbox">
    <h2 mat-dialog-title class="title">{{data.title}}</h2>
    <div class="modelbox-body">
        <p class="sub-text">{{data.content}}</p>
        <p class="sub-header">Report Format</p>
        <div>
            <mat-radio-group class="report-label"  [(ngModel)]="selectedFormat">
                <mat-radio-button name="reportFormat"  value="pdf">Pdf</mat-radio-button>
                <mat-radio-button name="reportFormat"  value="excel">Excel</mat-radio-button>
            </mat-radio-group>
        </div>
    </div> 
    <div class="backend-error" *ngIf="data.apiStatus && data.apiStatus!==200">
        <mat-icon>warning</mat-icon> Error message from backend
    </div>
    <div class="modelbox-footer">
        <button mat-button *ngFor="let btn of data.buttons" [ngClass]="btn?.class" class="mat-stroked-button"
                (click)="onClose(btn?.callBack)" mat-dialog-close [disabled]="isBtnSpinner  && btn.label === 'Download'">{{btn.label}}</button>
                <!-- <button mat-button *ngFor="let btn of data.buttons" [ngClass]="[btn?.class, (isBtnSpinner && btn.label === 'Download') ? 'loader-btn disabled' : '']" class="mat-stroked-button"
                (click)="onClose(btn?.callBack)" mat-dialog-close [disabled]="isBtnSpinner  && btn.label === 'Download'">
                <span>{{btn.label}}</span>
                <span *ngIf="btn.label === 'Download' && isBtnSpinner" class="btn-loader spin">
                    <img src="../../assets/images/btn-loader.png">
                </span>
            </button> -->
    </div>
</div>