import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { Utils } from '../../utils';


@Component({
    selector: 'app-vulnerability-tooltip',
    templateUrl: './vul-tooltip.component.html',
    styleUrls: ['./vul-tooltip.component.scss']
  })
  export class VulnerabilityTooltip {
    public params!: ICellRendererParams;
    public positionVal = {x:0 , y:0};
  
    agInit(params: ICellRendererParams): void {
      this.params = params;
      // console.log(params.data.failed_count);
      // console.log(params.data.count)
      // console.log(params.data.hover[0])
    }

    public currentMousePostion(event: MouseEvent) {
        this.positionVal = { x: event.clientX - 230, y: event.clientY - 80 };
        // console.log(this.positionVal)
      } 

      makeCapital(str:string | undefined){
        return Utils.makeFirstWordCapital(str)
      }
  
  }