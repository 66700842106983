<div class="notification-main">
    <div class="notification-result">
        <div class="notification-header">
            <div class="header-1">
                <p>Alerts & Notifications</p>
                <div class="close-btn">
                    <button mat-button class="btn close-sidebar"
                        (click)="closeNotification()"><mat-icon>cancel</mat-icon></button>
                </div>
            </div>
            <div class="right-side-header">
                <ul>
                    <li (click)="alertKeyStatus(true,'alerts')" [ngClass]="{'active': selectedTab === 'alerts'}">Alerts</li>
                    <li (click)="notificationKeyStatus(true,'notifications')"
                        [ngClass]="{'active': selectedTab === 'notifications'}">Notifications</li>
                </ul>
            </div>
            <mat-icon [matMenuTriggerFor]="menu" class="notification-btn"
            [ngClass]="{'muted-notification': selectedOption !== 0, 'active': selectedOption === 0}">
            {{ selectedOption !== 0 ? 'notifications_off' : 'notifications_active' }}
        </mat-icon>
        <mat-menu #menu="matMenu" class="notification-dropdown">
            <div class="menu-item" *ngFor="let option of muteOptions" (click)="onSelectMuteOption(option.value)"
                [ngClass]="{'active': selectedOption === option.value}">
                <mat-radio-button [checked]="selectedOption === option.value">{{ option.label }}</mat-radio-button>
            </div>
        </mat-menu>
        </div>
        <div class="notification-filter" *ngIf="(alertShow && alertPannelShow) || (notificationShow && notificationPannelShow)">
            <div class="notification-filter-left">
                <button mat-button class="btn all-btn" [ngClass]="{'btn-dark-outline-highlight': alertShow && alertPannelShow? flagAlertStatus :flagNotificationStatus}"  (click)="handleButtonClick(true)">
                    All
                </button>
                
                <button mat-button class="btn unread-btn" [ngClass]="{'btn-dark-outline-highlight':alertShow && alertPannelShow? !flagAlertStatus:!flagNotificationStatus}"  (click)="handleButtonClick(false)">
                    Unread <span *ngIf="alertShow && alertPannelShow">({{alertUnreadCount}})</span> <span *ngIf="notificationShow && notificationPannelShow">({{notificationUnreadCount}})</span>
                </button>
            </div>
            <div class="notification-filter-right">
                <button mat-button (click)="readNotification(readAllnotification)" class="markall-btn">
                    Mark all as read
                </button>
            </div>
        </div>
        <div>
        <div *ngIf="alertShow && !showDetails" class="notification-list  scroll h-scroll"  infinite-scroll [infiniteScrollDistance]="6"
        [infiniteScrollThrottle]="50" [alwaysCallback]="true"
        [scrollWindow]="false" 
        (scrolled)="onAlertScroll()">
            <div  class="notification-container" *ngIf="alertMessages.length > 0">
                <div *ngFor="let alert of alertMessages" [ngClass]="{'notification-item': true, 'unread': !alert.read_status}">
                    <div (click)="readNotification(readOneNotification, alert)" class="notification-item-inner">
                        <div class="notification-item-icon">
                            <div *ngIf="isInCategory(alert.notification_category_code, acceptedRiskCodes)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path
                                        d="M6.52505 15.95C6.72505 15.95 6.90005 15.875 7.05005 15.725C7.20005 15.575 7.27505 15.4 7.27505 15.2C7.27505 15 7.20005 14.825 7.05005 14.675C6.90005 14.525 6.72505 14.45 6.52505 14.45C6.32505 14.45 6.15005 14.525 6.00005 14.675C5.85005 14.825 5.77505 15 5.77505 15.2C5.77505 15.4 5.85005 15.575 6.00005 15.725C6.15005 15.875 6.32505 15.95 6.52505 15.95ZM6.52942 12.7C6.74317 12.7 6.92088 12.6281 7.06255 12.4844C7.20422 12.3406 7.27505 12.1625 7.27505 11.95V8.675C7.27505 8.4625 7.20276 8.28438 7.05817 8.14062C6.91357 7.99688 6.73441 7.925 6.52067 7.925C6.30692 7.925 6.12922 7.99688 5.98755 8.14062C5.84588 8.28438 5.77505 8.4625 5.77505 8.675V11.95C5.77505 12.1625 5.84734 12.3406 5.99192 12.4844C6.13652 12.6281 6.31569 12.7 6.52942 12.7ZM11 14.7H17.475C17.6875 14.7 17.8657 14.6277 18.0094 14.4831C18.1532 14.3385 18.225 14.1594 18.225 13.9456C18.225 13.7319 18.1532 13.5542 18.0094 13.4125C17.8657 13.2708 17.6875 13.2 17.475 13.2H11C10.7875 13.2 10.6094 13.2723 10.4657 13.4169C10.3219 13.5615 10.25 13.7406 10.25 13.9544C10.25 14.1681 10.3219 14.3458 10.4657 14.4875C10.6094 14.6292 10.7875 14.7 11 14.7ZM11 10.425H17.475C17.6875 10.425 17.8657 10.3527 18.0094 10.2081C18.1532 10.0635 18.225 9.88436 18.225 9.67063C18.225 9.45688 18.1532 9.27917 18.0094 9.1375C17.8657 8.99583 17.6875 8.925 17.475 8.925H11C10.7875 8.925 10.6094 8.99729 10.4657 9.14187C10.3219 9.28647 10.25 9.46564 10.25 9.67937C10.25 9.89312 10.3219 10.0708 10.4657 10.2125C10.6094 10.3542 10.7875 10.425 11 10.425ZM3.30005 20C2.90005 20 2.55005 19.85 2.25005 19.55C1.95005 19.25 1.80005 18.9 1.80005 18.5V5.5C1.80005 5.1 1.95005 4.75 2.25005 4.45C2.55005 4.15 2.90005 4 3.30005 4H20.7C21.1 4 21.45 4.15 21.75 4.45C22.05 4.75 22.2 5.1 22.2 5.5V18.5C22.2 18.9 22.05 19.25 21.75 19.55C21.45 19.85 21.1 20 20.7 20H3.30005ZM3.30005 18.5H20.7V5.5H3.30005V18.5Z"
                                        fill="#8191A1" />
                                </svg>
                            </div>
                            <div *ngIf="isInCategory(alert.notification_category_code, deviceManagementCodes)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <g clip-path="url(#clip0_1420_9607)">
                                        <path
                                            d="M21 3H3C1.9 3 1 3.9 1 5V17C1 18.1 1.9 19 3 19H8V20C8 20.55 8.45 21 9 21H15C15.55 21 16 20.55 16 20V19H21C22.1 19 22.99 18.1 22.99 17L23 5C23 3.89 22.1 3 21 3ZM20 17H4C3.45 17 3 16.55 3 16V6C3 5.45 3.45 5 4 5H20C20.55 5 21 5.45 21 6V16C21 16.55 20.55 17 20 17Z"
                                            fill="#8191A1" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1420_9607">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div *ngIf="isInCategory(alert.notification_category_code, newFileUpload)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M4 3H7V1H2V6H4V3ZM22 1V6H20V3H17V1H22ZM4 21H7V23H2V18H4V21ZM20 21V18H22V23H17V21H20ZM13.336 4C13.76 4 14.168 4.168 14.472 4.472L18.328 8.336C18.632 8.632 18.8 9.04 18.8 9.464V11H22V14H18.8V18.4C18.8 19.28 18.08 20 17.2 20H7.592C6.712 20 6 19.28 6 18.4V14H2V11H6V5.6C6 4.72 6.72 4 7.6 4H13.336ZM10 16.8H14.8C15.24 16.8 15.6 16.44 15.6 16C15.6 15.56 15.24 15.2 14.8 15.2H10C9.56 15.2 9.2 15.56 9.2 16C9.2 16.44 9.56 16.8 10 16.8ZM13.2 5.2V8.8C13.2 9.24 13.56 9.6 14 9.6H17.6L13.2 5.2ZM10 11.7C9.55818 11.7 9.20001 12.0582 9.20001 12.5C9.20001 12.9418 9.55819 13.3 10 13.3H14.8C15.2418 13.3 15.6 12.9418 15.6 12.5C15.6 12.0582 15.2418 11.7 14.8 11.7H10Z"
                                        fill="#8191A1" />
                                </svg>
                            </div>
                            <div *ngIf="isInCategory(alert.notification_category_code, userManagement)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <g clip-path="url(#clip0_1420_11725)">
                                        <path
                                            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 5C13.66 5 15 6.34 15 8C15 9.66 13.66 11 12 11C10.34 11 9 9.66 9 8C9 6.34 10.34 5 12 5ZM12 19.2C9.5 19.2 7.29 17.92 6 15.98C6.03 13.99 10 12.9 12 12.9C13.99 12.9 17.97 13.99 18 15.98C16.71 17.92 14.5 19.2 12 19.2Z"
                                            fill="#8191A1" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1420_11725">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div *ngIf="isInCategory(alert.notification_category_code, regionManagementCodes)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <g clip-path="url(#clip0_1420_9952)">
                                        <path
                                            d="M17 11H20C21.11 11 22 10.1 22 9V5C22 3.89 21.1 3 20 3H17C15.89 3 15 3.9 15 5V6H9.01V5C9.01 3.89 8.11 3 7.01 3H4C2.9 3 2 3.9 2 5V9C2 10.11 2.9 11 4 11H7C8.11 11 9 10.1 9 9V8H11V15.01C11 16.66 12.34 18 13.99 18H15V19C15 20.11 15.9 21 17 21H20C21.11 21 22 20.1 22 19V15C22 13.89 21.1 13 20 13H17C15.89 13 15 13.9 15 15V16H13.99C13.45 16 13 15.55 13 15.01V8H15V9C15 10.1 15.9 11 17 11Z"
                                            fill="#8191A1" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1420_9952">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div *ngIf="isInCategory(alert.notification_category_code, roleManagement)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <g clip-path="url(#clip0_1420_11792)">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M12 14.06L9 17L12 20H3V18C3 15.34 8.33 14 11 14C11.32 14 11.61 14.02 12 14.06ZM15 8C15 10.21 13.21 12 11 12C8.79 12 7 10.21 7 8C7 5.79 8.79 4 11 4C13.21 4 15 5.79 15 8ZM12 17L15.47 20.5L22 13.91L20.6 12.5L15.47 17.67L13.4 15.59L12 17Z"
                                            fill="#8191A1" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1420_11792">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div *ngIf="isInCategory(alert.notification_category_code, securityInformation)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <g clip-path="url(#clip0_3448_7790)">
                                        <path
                                            d="M11.3 2.26L5.3 4.51C4.52 4.81 4 5.55 4 6.39V11.1C4 16.15 7.41 20.86 12 22.01C16.59 20.86 20 16.15 20 11.1V6.39C20 5.56 19.48 4.81 18.7 4.52L12.7 2.27C12.25 2.09 11.75 2.09 11.3 2.26ZM10.23 14.83L8.11 12.71C7.72 12.32 7.72 11.69 8.11 11.3C8.5 10.91 9.13 10.91 9.52 11.3L10.93 12.71L14.47 9.17C14.86 8.78 15.49 8.78 15.88 9.17C16.27 9.56 16.27 10.19 15.88 10.58L11.64 14.82C11.26 15.22 10.62 15.22 10.23 14.83Z"
                                            fill="#888994" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_3448_7790">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div *ngIf="isInCategory(alert.notification_category_code, myScans)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path
                                        d="M7 3H4V6H2V1H7V3ZM22 6V1H17V3H20V6H22ZM7 21H4V18H2V23H7V21ZM20 18V21H17V23H22V18H20ZM19 18C19 19.1 18.1 20 17 20H7C5.9 20 5 19.1 5 18V6C5 4.9 5.9 4 7 4H17C18.1 4 19 4.9 19 6V18ZM15 8H9V10H15V8ZM15 11H9V13H15V11ZM15 14H9V16H15V14Z"
                                        fill="#888994" />
                                </svg>
                            </div>
                    
                    
                    
                            <div *ngIf="!isInCategory(alert.notification_category_code, getCombinedCodes())">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <g clip-path="url(#clip0_1420_9607)">
                                        <path
                                            d="M21 3H3C1.9 3 1 3.9 1 5V17C1 18.1 1.9 19 3 19H8V20C8 20.55 8.45 21 9 21H15C15.55 21 16 20.55 16 20V19H21C22.1 19 22.99 18.1 22.99 17L23 5C23 3.89 22.1 3 21 3ZM20 17H4C3.45 17 3 16.55 3 16V6C3 5.45 3.45 5 4 5H20C20.55 5 21 5.45 21 6V16C21 16.55 20.55 17 20 17Z"
                                            fill="#8191A1" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1420_9607">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                        <div class="notification-item-text" *ngIf="alert?.message">
                            <span  *ngIf="alert.isMessageArrayType else plainText">
                                <span  *ngFor="let message of alert?.message">
                                    <span style="margin-right: 3px;" [tooltip]="message.fullmesg" *ngIf="message && message.fullmesg && message?.fullmesg?.length > 0 && !message.redirection" [innerHTML]="message.mesg"></span><span style="margin-right: 3px;"  *ngIf="!message.redirection && (message?.fullmesg?.length === 0 || message.fullmesg === '')" [innerHTML]="message.mesg"></span><span style="margin-right: 3px;" class="highlight" (click)="handleClick(alert)" *ngIf="message.redirection" [tooltip]="message.fullmesg.length > 0 ? message.fullmesg : ''" [innerHTML]="message.mesg"></span>
                                </span>
                            </span>
                            <ng-template #plainText>
                            {{showLimitedContent(alert.message, alert.isMore)}}
                            <span (click)="viewmore(alert)" *ngIf="alert.message.length > 80" class="show-more">
                                {{alert.message.length > 80 && !alert.isMore ? 'More': 'Less'}}
                            </span>
                        </ng-template>
                        </div>
                        <div class="notification-item-time">
                            <!-- {{ alert.notice_time }} -->
                            <ng-container *ngIf="alert.isValidTime else rawValue">
                                {{ alert.notice_time | date: 'd MMM' }}
                              </ng-container>
                              <ng-template #rawValue>
                                {{ alert.notice_time }}
                              </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <ng-container *ngIf="alertMessages.length === 0 && isApiCallDone; else skeleton_loader">
                <div class="notification-empty"> 
                <div class="notification-icon" *ngIf="flagAlertStatus === true">
                    <img src="../../assets/images/no_alerts.svg" />
                    <p class="empty-text">{{alertsEmptyMessage}}</p>
                </div>
                <div class="notification-icon" *ngIf="flagAlertStatus != true">
                    <img src="../../assets/images/no_alerts.svg" />
                    <p class="empty-text">{{unreadAlerts}}</p>
                </div>
               </div> 
            </ng-container>
            <ng-template #skeleton_loader>
                <div class="item" *ngIf="!isApiCallDone">
                    <div *ngFor="let item of [].constructor(alertMessages.length === 0 ? 21 : 3); let i = index">
                        <ngx-skeleton-loader count="1" class="rhs-content-loader white-list-history-detail-loader">
                        </ngx-skeleton-loader>
                    </div>
                </div>
            </ng-template>  
        </div>
        <div *ngIf="notificationShow && !showDetails" class="notification-list scroll h-scroll" infinite-scroll [infiniteScrollDistance]="6"
        [infiniteScrollThrottle]="50" [alwaysCallback]="true"
        [scrollWindow]="false" 
        (scrolled)="onNotificationScroll()">
        <div  class="notification-container" *ngIf="notificationMessages.length > 0">
            <div *ngFor="let notification of notificationMessages" [ngClass]="{'notification-item': true, 'unread': !notification.read_status}">
                <div (click)="readNotification(readOneNotification, notification)" class="notification-item-inner">
                    <div  class="notification-item-icon">
                        <div *ngIf="isInCategory(notification.notification_category_code, acceptedRiskCodes)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path
                                    d="M6.52505 15.95C6.72505 15.95 6.90005 15.875 7.05005 15.725C7.20005 15.575 7.27505 15.4 7.27505 15.2C7.27505 15 7.20005 14.825 7.05005 14.675C6.90005 14.525 6.72505 14.45 6.52505 14.45C6.32505 14.45 6.15005 14.525 6.00005 14.675C5.85005 14.825 5.77505 15 5.77505 15.2C5.77505 15.4 5.85005 15.575 6.00005 15.725C6.15005 15.875 6.32505 15.95 6.52505 15.95ZM6.52942 12.7C6.74317 12.7 6.92088 12.6281 7.06255 12.4844C7.20422 12.3406 7.27505 12.1625 7.27505 11.95V8.675C7.27505 8.4625 7.20276 8.28438 7.05817 8.14062C6.91357 7.99688 6.73441 7.925 6.52067 7.925C6.30692 7.925 6.12922 7.99688 5.98755 8.14062C5.84588 8.28438 5.77505 8.4625 5.77505 8.675V11.95C5.77505 12.1625 5.84734 12.3406 5.99192 12.4844C6.13652 12.6281 6.31569 12.7 6.52942 12.7ZM11 14.7H17.475C17.6875 14.7 17.8657 14.6277 18.0094 14.4831C18.1532 14.3385 18.225 14.1594 18.225 13.9456C18.225 13.7319 18.1532 13.5542 18.0094 13.4125C17.8657 13.2708 17.6875 13.2 17.475 13.2H11C10.7875 13.2 10.6094 13.2723 10.4657 13.4169C10.3219 13.5615 10.25 13.7406 10.25 13.9544C10.25 14.1681 10.3219 14.3458 10.4657 14.4875C10.6094 14.6292 10.7875 14.7 11 14.7ZM11 10.425H17.475C17.6875 10.425 17.8657 10.3527 18.0094 10.2081C18.1532 10.0635 18.225 9.88436 18.225 9.67063C18.225 9.45688 18.1532 9.27917 18.0094 9.1375C17.8657 8.99583 17.6875 8.925 17.475 8.925H11C10.7875 8.925 10.6094 8.99729 10.4657 9.14187C10.3219 9.28647 10.25 9.46564 10.25 9.67937C10.25 9.89312 10.3219 10.0708 10.4657 10.2125C10.6094 10.3542 10.7875 10.425 11 10.425ZM3.30005 20C2.90005 20 2.55005 19.85 2.25005 19.55C1.95005 19.25 1.80005 18.9 1.80005 18.5V5.5C1.80005 5.1 1.95005 4.75 2.25005 4.45C2.55005 4.15 2.90005 4 3.30005 4H20.7C21.1 4 21.45 4.15 21.75 4.45C22.05 4.75 22.2 5.1 22.2 5.5V18.5C22.2 18.9 22.05 19.25 21.75 19.55C21.45 19.85 21.1 20 20.7 20H3.30005ZM3.30005 18.5H20.7V5.5H3.30005V18.5Z"
                                    fill="#8191A1" />
                            </svg>
                        </div>
                        <div *ngIf="isInCategory(notification.notification_category_code, deviceManagementCodes)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <g clip-path="url(#clip0_1420_9607)">
                                    <path
                                        d="M21 3H3C1.9 3 1 3.9 1 5V17C1 18.1 1.9 19 3 19H8V20C8 20.55 8.45 21 9 21H15C15.55 21 16 20.55 16 20V19H21C22.1 19 22.99 18.1 22.99 17L23 5C23 3.89 22.1 3 21 3ZM20 17H4C3.45 17 3 16.55 3 16V6C3 5.45 3.45 5 4 5H20C20.55 5 21 5.45 21 6V16C21 16.55 20.55 17 20 17Z"
                                        fill="#8191A1" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1420_9607">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div *ngIf="isInCategory(notification.notification_category_code, newFileUpload)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M4 3H7V1H2V6H4V3ZM22 1V6H20V3H17V1H22ZM4 21H7V23H2V18H4V21ZM20 21V18H22V23H17V21H20ZM13.336 4C13.76 4 14.168 4.168 14.472 4.472L18.328 8.336C18.632 8.632 18.8 9.04 18.8 9.464V11H22V14H18.8V18.4C18.8 19.28 18.08 20 17.2 20H7.592C6.712 20 6 19.28 6 18.4V14H2V11H6V5.6C6 4.72 6.72 4 7.6 4H13.336ZM10 16.8H14.8C15.24 16.8 15.6 16.44 15.6 16C15.6 15.56 15.24 15.2 14.8 15.2H10C9.56 15.2 9.2 15.56 9.2 16C9.2 16.44 9.56 16.8 10 16.8ZM13.2 5.2V8.8C13.2 9.24 13.56 9.6 14 9.6H17.6L13.2 5.2ZM10 11.7C9.55818 11.7 9.20001 12.0582 9.20001 12.5C9.20001 12.9418 9.55819 13.3 10 13.3H14.8C15.2418 13.3 15.6 12.9418 15.6 12.5C15.6 12.0582 15.2418 11.7 14.8 11.7H10Z"
                                    fill="#8191A1" />
                            </svg>
                        </div>
                        <div *ngIf="isInCategory(notification.notification_category_code, userManagement)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <g clip-path="url(#clip0_1420_11725)">
                                    <path
                                        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 5C13.66 5 15 6.34 15 8C15 9.66 13.66 11 12 11C10.34 11 9 9.66 9 8C9 6.34 10.34 5 12 5ZM12 19.2C9.5 19.2 7.29 17.92 6 15.98C6.03 13.99 10 12.9 12 12.9C13.99 12.9 17.97 13.99 18 15.98C16.71 17.92 14.5 19.2 12 19.2Z"
                                        fill="#8191A1" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1420_11725">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div *ngIf="isInCategory(notification.notification_category_code, regionManagementCodes)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <g clip-path="url(#clip0_1420_9952)">
                                    <path
                                        d="M17 11H20C21.11 11 22 10.1 22 9V5C22 3.89 21.1 3 20 3H17C15.89 3 15 3.9 15 5V6H9.01V5C9.01 3.89 8.11 3 7.01 3H4C2.9 3 2 3.9 2 5V9C2 10.11 2.9 11 4 11H7C8.11 11 9 10.1 9 9V8H11V15.01C11 16.66 12.34 18 13.99 18H15V19C15 20.11 15.9 21 17 21H20C21.11 21 22 20.1 22 19V15C22 13.89 21.1 13 20 13H17C15.89 13 15 13.9 15 15V16H13.99C13.45 16 13 15.55 13 15.01V8H15V9C15 10.1 15.9 11 17 11Z"
                                        fill="#8191A1" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1420_9952">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div *ngIf="isInCategory(notification.notification_category_code, roleManagement)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <g clip-path="url(#clip0_1420_11792)">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M12 14.06L9 17L12 20H3V18C3 15.34 8.33 14 11 14C11.32 14 11.61 14.02 12 14.06ZM15 8C15 10.21 13.21 12 11 12C8.79 12 7 10.21 7 8C7 5.79 8.79 4 11 4C13.21 4 15 5.79 15 8ZM12 17L15.47 20.5L22 13.91L20.6 12.5L15.47 17.67L13.4 15.59L12 17Z"
                                        fill="#8191A1" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1420_11792">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div *ngIf="isInCategory(notification.notification_category_code, securityInformation)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <g clip-path="url(#clip0_3448_7790)">
                                  <path
                                    d="M11.3 2.26L5.3 4.51C4.52 4.81 4 5.55 4 6.39V11.1C4 16.15 7.41 20.86 12 22.01C16.59 20.86 20 16.15 20 11.1V6.39C20 5.56 19.48 4.81 18.7 4.52L12.7 2.27C12.25 2.09 11.75 2.09 11.3 2.26ZM10.23 14.83L8.11 12.71C7.72 12.32 7.72 11.69 8.11 11.3C8.5 10.91 9.13 10.91 9.52 11.3L10.93 12.71L14.47 9.17C14.86 8.78 15.49 8.78 15.88 9.17C16.27 9.56 16.27 10.19 15.88 10.58L11.64 14.82C11.26 15.22 10.62 15.22 10.23 14.83Z"
                                    fill="#888994" />
                                </g>
                                <defs>
                                  <clipPath id="clip0_3448_7790">
                                    <rect width="24" height="24" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg> 
                            </div>
                        <div *ngIf="isInCategory(notification.notification_category_code, myScans)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path
                                  d="M7 3H4V6H2V1H7V3ZM22 6V1H17V3H20V6H22ZM7 21H4V18H2V23H7V21ZM20 18V21H17V23H22V18H20ZM19 18C19 19.1 18.1 20 17 20H7C5.9 20 5 19.1 5 18V6C5 4.9 5.9 4 7 4H17C18.1 4 19 4.9 19 6V18ZM15 8H9V10H15V8ZM15 11H9V13H15V11ZM15 14H9V16H15V14Z"
                                  fill="#888994" />
                              </svg>
                            </div>
                        
                        
                        
                        <div *ngIf="!isInCategory(notification.notification_category_code, getCombinedCodes())">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <g clip-path="url(#clip0_1420_9607)">
                                    <path
                                        d="M21 3H3C1.9 3 1 3.9 1 5V17C1 18.1 1.9 19 3 19H8V20C8 20.55 8.45 21 9 21H15C15.55 21 16 20.55 16 20V19H21C22.1 19 22.99 18.1 22.99 17L23 5C23 3.89 22.1 3 21 3ZM20 17H4C3.45 17 3 16.55 3 16V6C3 5.45 3.45 5 4 5H20C20.55 5 21 5.45 21 6V16C21 16.55 20.55 17 20 17Z"
                                        fill="#8191A1" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1420_9607">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                      </div>
              <div class="notification-item-text" *ngIf="notification?.message">
                <span  *ngIf="notification.isMessageArrayType else plainText">
                    <span  *ngFor="let message of notification?.message">
                        <span style="margin-right: 3px;" [tooltip]="message.fullmesg" *ngIf="message && message.fullmesg && message?.fullmesg?.length > 0 && !message.redirection" [innerHTML]="message.mesg"></span><span style="margin-right: 3px;"  *ngIf="!message.redirection && (message?.fullmesg?.length === 0 || message.fullmesg === '')" [innerHTML]="message.mesg"></span><span style="margin-right: 3px;" class="highlight" (click)="handleClick(notification)" *ngIf="message.redirection" [tooltip]="message.fullmesg.length > 0 ? message.fullmesg : ''" [innerHTML]="message.mesg"></span>
                    </span>
                </span>
                <ng-template #plainText>
                {{showLimitedContent(notification.message, notification.isMore)}}
                <span (click)="viewmore(notification)" *ngIf="notification.message.length > 80" class="show-more">
                    {{notification.message.length > 80 && !notification.isMore ? 'More': 'Less'}}
                </span>
            </ng-template>
              </div>
              <div class="notification-item-time">
                <!-- {{ notification.notice_time }} -->
                <ng-container *ngIf="notification.isValidTime; else rawValue">
                    {{ notification.notice_time | date: 'd MMM' }}
                  </ng-container>
                  <ng-template #rawValue>
                    {{  notification.notice_time }}
                  </ng-template>
              </div>
            </div>
            </div>
        </div>
        <ng-container *ngIf="notificationMessages.length === 0 && isApiCallDone; else skeleton_loader">
            <div class="notification-empty"> 
            <div class="notification-icon" *ngIf="flagNotificationStatus === true">
                <img src="../../assets/images/no_notification.svg" />
                <p class="empty-text">{{notificationEmptyMessage}}</p>
            </div>
            <div class="notification-icon" *ngIf="flagNotificationStatus != true">
                <img src="../../assets/images/no_notification.svg" />
                <p class="empty-text">{{unreadnotification}}</p>
            </div>
           </div> 
        </ng-container>
        <ng-template #skeleton_loader>
            <div class="item" *ngIf="!isApiCallDone">
                <div *ngFor="let item of [].constructor(notificationMessages.length === 0 ? 21 : 3); let i = index">
                    <ngx-skeleton-loader count="1" class="rhs-content-loader white-list-history-detail-loader">
                    </ngx-skeleton-loader>
                </div>
            </div>
        </ng-template> 
        </div>
        <div *ngIf="showDetails">
            <div class="notification-details">
                <div class="header">
                    <p class="inner-title"> <span class="back-arrow"> <mat-icon
                                (click)="backToList()">keyboard_backspace</mat-icon></span>
                        <span class="notifi-info row_display" *ngIf="selectedNotification?.isMessageArrayType">
                            <span  *ngFor="let message of selectedNotification?.message">
                                <!-- <span [tooltip]="message.fullmesg" *ngIf="message && message.fullmesg && message?.fullmesg?.length > 0" [innerHTML]="message.mesg"></span><span  *ngIf="message.redirection ==='false' && (message?.fullmesg?.length === 0 || message.fullmesg === '')" [innerHTML]="message.mesg"></span><span  *ngIf="message.redirection === 'true'" [innerHTML]="message.mesg"></span> -->
                                 <!-- <span style="margin-right: 3px;"  [innerHTML]="message.fullmesg.length > 0 ? message.fullmesg : message.mesg"></span> -->
                                 <span style="margin-right: 3px;" [tooltip]="message.fullmesg" *ngIf="message && message.fullmesg && message?.fullmesg?.length > 0 && !message.redirection" [innerHTML]="message.mesg"></span><span style="margin-right: 3px;"  *ngIf="!message.redirection && (message?.fullmesg?.length === 0 || message.fullmesg === '')" [innerHTML]="message.mesg"></span><span style="margin-right: 3px;" class="highlight"  *ngIf="message.redirection" [tooltip]="message.fullmesg.length > 0 ? message.fullmesg : ''" [innerHTML]="message.mesg"></span>

                            </span>
                        </span>
                        <!-- <ng-template #plainText>
                            <span class="notifi-info">{{ selectedNotification?.message }}</span>
                        </ng-template> -->
                    </p>
                    <!-- <span class="notifi-time">{{ selectedNotification?.notice_time}}</span> -->
                    <ng-container *ngIf="selectedNotification.isValidTime; else rawValue">
                        {{ selectedNotification.notice_time | date: 'd MMM' }}
                      </ng-container>
                      <ng-template #rawValue>
                        {{  selectedNotification.notice_time }}
                      </ng-template>
                </div>
                <div class="sub-item-list scroll">
                    <div *ngFor="let subItem of selectedNotification?.sub_notification" class="sub-item-row">
                        <p>{{ subItem.name }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>