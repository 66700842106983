import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Subject, Subscription, catchError, debounceTime, distinctUntilChanged, throwError } from 'rxjs';
import { CONSTANT, ToolTipConfig } from '../../../constants/constant-data';
import { ResponseService } from '../../../services/response.service';
import { RestService } from '../../../services/rest.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import * as _ from 'lodash';
import { MyScansService } from '../../../services/my-scans.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent {
  @ViewChild('searchField') searchField!: ElementRef;
   searchInputChange$ = new Subject<string>();
  @Output() updateSelectedList: EventEmitter<any[]> = new EventEmitter();
  @Input() apiName: string = '';
  public page: number = 1;
  public totalPage: number = 1;
  public isApiCalled: boolean = false;
  public searchText: string = '';
  public subscription!: Subscription;
  public selectedUser: any = [];
  public userList: any = [];
  public toolTipOption = ToolTipConfig;
  public is_clicked: boolean = true;
  noDataMsg: String = CONSTANT.NO_DATA_MSG
  public selectAllState: boolean = false;
  hideSelectAll:boolean = false;
  public intialSelectedUser:any = [];
  constructor(private responseService: ResponseService,
    private cookieService: CookieService,
    public scanService: MyScansService,
    public authService: AuthService,
    private restService: RestService) {
      const initialSelectedUserCookie: any = JSON.parse(this.authService.getCookie('initialSelectedUser') || '[]');
      if (initialSelectedUserCookie.length > 0) {
        this.intialSelectedUser  = _.cloneDeep(initialSelectedUserCookie)
        this.selectedUser = _.cloneDeep(this.intialSelectedUser);
        this.is_clicked = true
        this.userList?.forEach((user: any)=>{
          if(this.selectedUser && this.selectedUser.length && this.selectedUser.indexOf(user.id) > -1) {
            user.selected = true;
          } else {
            user.selected = false;
          }
        })
        const allSelected = this.userList.length && this.userList.every((user: { selected: any; }) => user.selected);
        this.selectAllState = allSelected;
        if(this.selectAllState){
          this.userList.forEach((user: { selected: boolean; }) => {
            user.selected = true;
          });
        }
        this.updateSelectedList.emit(this.selectedUser);
      }

  }

  ngOnInit(): void {
    this.loadUserList(this.page);
    this.scanService.userMenuClosed$.subscribe(() => {
      this.selectedUser = _.cloneDeep(this.intialSelectedUser);
      this.is_clicked = true
      this.userList?.forEach((user: any)=>{
        if(this.selectedUser && this.selectedUser.length && this.selectedUser.indexOf(user.id) > -1) {
          user.selected = true;
        } else {
          user.selected = false;
        }
      })
      const allSelected = this.userList.length && this.userList.every((user: { selected: any; }) => user.selected);
      this.selectAllState = allSelected;
      if(this.selectAllState){
        this.userList.forEach((user: { selected: boolean; }) => {
          user.selected = true;
        });
      }
    })
  }

  ngAfterViewInit(): void { 
    this.searchInputChange$
    .pipe(
      debounceTime(500), 
      distinctUntilChanged()
    )
    .subscribe((searchTerm: string) => {
      if(searchTerm.length>0){
        this.hideSelectAll = true;
      } else {
        this.hideSelectAll = false;
      }
      this.filterUserList();
    });
  }

  onSearchInputChange(event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;
    this.searchInputChange$.next(inputValue);
  }

  public loadUserList(page: number = 1) {
    const req = {
      search: ''
    };
    if (this.searchField?.nativeElement) {
      req.search = this.searchField?.nativeElement.value;
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.userListApiCall(req).subscribe({
      next: (response: any) => {
        this.isApiCalled = true;
        const data = this.responseService.successResponse(response);
        this.totalPage = Number(data.data.numberofpages || 1);
        if (data.status === 200 && data.data && data.data.length) {
          if (this.userList && this.userList.length) {
            this.userList = this.userList.concat(data.data);
          } else {
            this.userList = data.data
          }
         if(this.selectAllState){
          this.userList.forEach((user: { selected: boolean; }) => {
            user.selected = true;
          });
         } else {
          this.userList?.forEach((user: any)=>{
            if(this.selectedUser && this.selectedUser.length && this.selectedUser.indexOf(user.id) > -1) {
              user.selected = true;
            } else {
              user.selected = false;
            }
          })
         }
          this.updateSelectAllState();
        }
      },
      error: (err: any) => {
        this.isApiCalled = true;
        this.responseService.errorResponse(err);
      }
    })
  }
  public filterUserList() {
    this.isApiCalled = false;
    this.page = 1;
    this.totalPage = 1;
    this.userList = [];
    this.loadUserList(1);
  }
  public onScroll = () => {
    if (this.isApiCalled && (this.page < this.totalPage)) {
      this.loadUserList(++this.page);
    }
  }
  public userListApiCall(req: any) {
    return this.restService.postApi(this.apiName, req).pipe(
      catchError((error) => {
        return throwError(error);
      })
    )
  }
  public selectUser(item: any) {
    this.is_clicked = false;
    // this.selectedUser = _.differenceWith(this.userList, this.userListBackUp, _.isEqual);
    if (item.selected) {
      this.selectedUser.push(item.id);
    } else {
      const index = this.selectedUser.indexOf(item.id);
      if (index !== -1) {
        this.selectedUser.splice(index, 1);
      }
    }
    this.updateSelectAllState();
  }

  selectAllUsers(event: MatCheckboxChange): void {
    this.is_clicked = false;
    const isChecked = event.checked;
    this.selectAllState = isChecked;
    if (isChecked) {
      this.userList.forEach((user: { id: any; selected: boolean; }) => {
        if (!this.selectedUser.includes(user.id)) {
          this.selectedUser.push(user.id);
          user.selected = true;
        }
      });
    } else {
      this.userList.forEach((user: { selected: boolean; }) => {
        user.selected = false;
      });
      this.selectedUser = [];
    }
  }

  updateSelectAllState(): void {
    const allSelected = this.userList.length && this.userList.every((user: { selected: any; }) => user.selected);
    this.selectAllState = allSelected;
  }

  apply() {
    this.is_clicked = true;
    this.intialSelectedUser = _.cloneDeep(this.selectedUser);
    this.authService.setCookie('initialSelectedUser', JSON.stringify(this.intialSelectedUser || []));
    this.updateSelectedList.emit(this.selectedUser);
  }
  public isPreloaderEligible(): boolean {
    return (this.page < this.totalPage) || !this.isApiCalled;
  }
}
