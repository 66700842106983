<div class="device-popup">
    <div class="device-popup-header">
        <h3>Are you sure want to delete asset(s)?</h3>
        <p>Please enter your password and reason to delete the asset(s)</p>
            <div class="close-btn">
                <button mat-button class="btn icon-btn" [mat-dialog-close]="true"><mat-icon>cancel</mat-icon></button>
            </div>
    </div>
    <div class="device-data">
        <div class="device-popup-row">
            <form [formGroup]="deleteDeviceForm" (ngSubmit)="confirm()">
                <mat-form-field appearance="outline" class="full-width mb_3 border-field no-bg" *ngIf="!userProfile?.sso_enabled">
                    <mat-label>Password</mat-label>
                    <input matInput [type]="hide ? 'password' : 'text'" formControlName="password">
                    <mat-icon matSuffix (click)="hide = !hide">{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                    
                   <mat-error *ngFor="let validation of validation_messages.password">
                    <mat-error class="error-message"
                    *ngIf="deleteDeviceForm.get('password')?.hasError(validation.type) && 
                    (deleteDeviceForm.get('password')?.dirty || deleteDeviceForm.get('password')?.touched)">
                    {{validation.message}}
                </mat-error>
                   </mat-error>
                </mat-form-field>  
                <mat-form-field appearance="outline" class="full-width textarea-field border-field no-bg">
                    <mat-label>Reason</mat-label>
                    <textarea  matInput  #messageInput class="scroll"  (input)="checkTextField($event)" 
                           formControlName="reason" minlength="8"
                        maxlength="120"></textarea>
                        <mat-error *ngFor="let validation of validation_messages.reason">
                            <mat-error class="error-message"
                                *ngIf="deleteDeviceForm.get('reason')?.hasError(validation.type) && 
                                (deleteDeviceForm.get('reason')?.dirty || deleteDeviceForm.get('reason')?.touched)">
                                {{validation.message}}
                            </mat-error>
                        </mat-error>
                </mat-form-field>
                <div class="below-section">
                    <p class="field_length">Please enter maximum 120 characters</p>   
                    <div>
                        <button mat-raised-button class="full-width btn-tartorange " [disabled]="!deleteDeviceForm.valid">Delete Asset</button>
    
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>