import { Component } from '@angular/core';

@Component({
  selector: 'app-whitelist-percentage',
  templateUrl: './whitelist-percentage.component.html',
  styleUrls: ['./whitelist-percentage.component.scss']
})
export class WhitelistPercentageComponent {
  public params: any;
  public riskClass: string = '';
  public riskLevel: string = '';
  agInit(params: any): void {
    this.params = params;

    // Set risk class and level based on severity
    if (params.data.severity === 'Critical') {
      this.riskClass = 'critical';
      this.riskLevel = 'Critical';
    } else if (params.data.severity === 'Medium') {
      this.riskClass = 'medium';
      this.riskLevel = 'Medium';
    } else if (params.data.severity === 'Low') {
      this.riskClass = 'low';
      this.riskLevel = 'Low';
    }
  }

  public addBadgeClass = (type: string) => {
    let className = 'sunsetorange-bg';
    if (type === 'Critical') {
      className = 'critical';
    } else if (type === 'High') {
      className = 'high';
    } else if (type === 'Medium') {
      className = 'medium';
    } else if (type === 'Low') {
      className = 'low';
    } else if (type === 'whitelist') {
      className = 'bg-tertiary';
    }
    return className;
  }

  refresh(params: any): boolean {
    return false;
  }
}
