import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Utils } from 'src/app/modules/shared/utils';
import { ToolTipConfig } from '../../constants/constant-data';
import { HeaderService } from '../../services/header.service';
@Component({
  selector: 'app-scan-header',
  templateUrl: './scan-header.component.html',
  styleUrls: ['./scan-header.component.scss']
})
export class ScanHeaderComponent implements OnInit, OnChanges {
  @Input() newScanDetails: any = {}; 
  title:string|undefined;
  scanData: any;
  public toolTipOption = ToolTipConfig;
  acceptedRisk:any;
  constructor(private router: ActivatedRoute,
    private headerService: HeaderService) { }

  ngOnInit(): void {
    this.router.title.subscribe((title) => {
      this.title = title;
    })
    this.headerService.getAcceptedRiskCount().subscribe((data:any)=>{
      this.acceptedRisk = data;
      })
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.newScanDetails['ratingcls'] = Utils.getRatingBadge(Math.floor(this.newScanDetails?.rating));
    this.newScanDetails['rating_rounded']=Math.floor(this.newScanDetails?.rating)
  }
}
