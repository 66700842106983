<div class="app-container">
    <mat-drawer-container autosize>
      <mat-drawer #drawer closed mode="push" class="left-nav-section">
        <!-- <app-side-nav> </app-side-nav> -->
      </mat-drawer>
  
      <div class="main_content">
        <app-header></app-header>
        <router-outlet></router-outlet>
      </div>
    </mat-drawer-container>
  </div>
 