import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomDailog } from 'src/app/modules/shared/model/Dialog.model';
import { ICreateUpdateDeleteVpnPayLoad, ICreateUpdateDeleteVpnResponse } from '../vpn..models';
import { CONFIG } from 'src/app/modules/shared/constants/config';
import { VpnService } from 'src/app/modules/shared/services/vpn.service';

@Component({
  selector: 'app-add-vpn',
  templateUrl: './add-vpn.component.html',
  styleUrls: ['./add-vpn.component.scss']
})
export class AddVpnComponent {
  hide = true;
  public isSpinner: boolean = false;
  public addVpnFrm!: FormGroup;
  public createAndUpdateVpnLoader: boolean = false;
  public vpndetails:any;
  constructor(private fBuilder: FormBuilder,
    private vpnService: VpnService,
    @Inject(MAT_DIALOG_DATA) public data: CustomDailog
  ) {
    this.addVpnFrm = this.fBuilder.group({
      name: new FormControl(null, [Validators.required]),
      description: new FormControl(null, [Validators.required]),
      customer_gw_ip: new FormControl(null, [Validators.required]),
      customer_cidr: new FormControl(null, [Validators.required]),
      // customer_private_ip:new FormControl(null, [Validators.required]),
    })
    if (this.data && this.data.data && this.data.data.vpndetails) {
      this.patchFormValues(this.data.data.vpndetails);
    }

  }
  private patchFormValues(vpnDetails: any) {
    this.addVpnFrm.patchValue({
      name: vpnDetails.tunnel_name,
      description: vpnDetails.tunnel_description,
      customer_gw_ip: vpnDetails.client_gateway_ip,
      customer_cidr: vpnDetails.client_cidr_block,
      // customer_private_ip:vpnDetails.customer_private_ip
    });
  }
  isCopied = false;
  public copyToClipboard(value: string): void {
    if (!value) {
      return;
    }
  
    // Create a temporary textarea element
    const textarea = document.createElement('textarea');
    textarea.value = value;
    document.body.appendChild(textarea);
  
    // Select the text
    textarea.select();
    textarea.setSelectionRange(0, 99999); // For mobile devices
  
    // Copy the text to clipboard
    document.execCommand('copy');
  
    // Remove the temporary element
    document.body.removeChild(textarea);
    this.isCopied = true;
  
    // Optionally, you can show a success message or a toast notification
    console.log('Copied to clipboard:', value);
  }
  public createTunnel() {
    if(this.addVpnFrm.valid){
      this.createAndUpdateVpn(this.addVpnFrm.value)
    }
  }
  public createAndUpdateVpn(payload: ICreateUpdateDeleteVpnPayLoad, isNew = true) {
    this.createAndUpdateVpnLoader = false;
    payload.customer_private_ip=payload.customer_gw_ip;
    if(this.data.data.action=='edit'){
      payload.tunnel_id=this.data.data.vpndetails.tunnel_id;
      this.vpnService.createUpdateVpn(payload, CONFIG.METHOD.PUT, this.createAndUpdateVpnCallback);
    } else {
      this.isSpinner = true;
      this.vpnService.createUpdateVpn(payload, CONFIG.METHOD.POST, this.createAndUpdateVpnCallback);

    }
    
  }

  public createAndUpdateVpnCallback = (response: ICreateUpdateDeleteVpnResponse) => {
    this.isSpinner = false;
    if (response.status === 200) {
      this.createAndUpdateVpnLoader = true;
      this.vpndetails=response.data;
      this.data?.data?.createVpn(true)
    }
  }
  addAnotherVPN() {
    this.createAndUpdateVpnLoader=false;
    this.addVpnFrm.reset();
    //throw new Error('Method not implemented.');
  }

}
