<!-- <div class="accepted-risk-container">
  <span class="badge">{{ params.value }}</span>
  <span class="device-label">Devices</span>
</div> -->
<div class="ag-center" *ngIf="params.data.accepted_count> 0">
  <p class="whitelisted-label bg-tertiary">
    <span class="whitelisted-value text-tertiary"
    [ngClass]="addBadgeClass('whitelist')">
    {{ params.data.accepted_count}}</span>
  <span *ngIf="params.data.accepted_count > 1" class="whitelisted-text">Assets</span>
  <span class="whitelisted-text" *ngIf="params.data.accepted_count === 1">Asset</span>
  </p>

</div>
