import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';


@Component({
    selector: 'app-parameter',
    templateUrl: './parameter.component.html',
    styleUrls: ['./parameter.component.scss']
  })
  export class ParameterComponent {
    public params!: ICellRendererParams;
  
    agInit(params: ICellRendererParams): void {
      this.params = params;
    }

    public addClassCommonVul (value: string = ''): string {
        return value.toLocaleLowerCase();
      } 
  
  }