<div class="filter-tree">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <mat-tree-node class="filter-tree-node" *matTreeNodeDef="let node" matTreeNodePadding>
            <button mat-icon-button disabled></button>
            <mat-checkbox *ngIf="type==='checkbox'" class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)"
                (change)="todoLeafItemSelectionToggle(node)">{{node.item.label}}</mat-checkbox>
            <mat-radio-button
                *ngIf="type==='radio'"
                class="checklist-leaf-node"
                (change)="toggleRadioSelection(node)">
                {{node.item.label}}
            </mat-radio-button>   
        </mat-tree-node>
        <mat-tree-node class="filter-tree-node" *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.item">
                <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'arrow_drop_down' : 'arrow_right'}}
                </mat-icon>
            </button>
            <mat-checkbox *ngIf="type==='checkbox'" [checked]="descendantsAllSelected(node)"
             [indeterminate]="descendantsPartiallySelected(node)"
                (change)="todoItemSelectionToggle(node)">
                {{node.item}}</mat-checkbox>
           <mat-radio-button
            *ngIf="type==='radio'"
            (change)="toggleRadioSelection(node)">
           {{node.item}}
        </mat-radio-button>    
        </mat-tree-node>
    </mat-tree>
</div>