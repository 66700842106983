import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { WhitelistDetailsComponent } from 'src/app/modules/whitelist/components/whitelist-details/whitelist-details.component';

@Component({
  selector: 'app-checkbox-whitelist',
  templateUrl: './checkbox-whitelist.component.html',
  styleUrls: ['./checkbox-whitelist.component.scss']
})
export class CheckboxWhitelistComponent {
  public params: any;
  agInit(params: any): void {
    this.params = params;
  }
  public get permissionProps() {
    return this.whiteComp.permissionProps
  }
  constructor(public whiteComp: WhitelistDetailsComponent){
  }
  public hasPermission(type: string){
    return this.whiteComp.hasPermission(type)
  }

}
