import { Component, ElementRef, Input } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-panel-chart',
  templateUrl: './panel-chart.component.html',
  styleUrls: ['./panel-chart.component.scss']
})
export class PanelChartComponent {

  @Input() name!: String;
  @Input() data!: Number[]; 
  @Input() color!: string[];
  @Input() borderWidth: number =0;
  public chart: any;

  constructor(private elementRef: ElementRef){

  }

  ngOnInit(): void {
    this.createChart();
  }

  createChart() {
    const canvas = this.elementRef.nativeElement.querySelector('canvas');
    this.chart = new Chart(canvas ,{
      type: 'doughnut',
      data: {
        datasets: [{
          label: 'My First Dataset',
          data: this.data,
          backgroundColor: this.color,
          hoverOffset: 0,
          borderWidth: this.borderWidth,
        }],
      },
      options: {
        aspectRatio: 2.5,
        plugins: {
          legend: {
            display: false, 
          },
          tooltip: {
            enabled: false, 
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          mode: 'nearest',
        },
      },
    });
  }
  

}
