import { Component } from '@angular/core';

@Component({
  selector: 'app-device-severity',
  templateUrl: './device-severity.component.html',
  styleUrls: ['./device-severity.component.scss']
})
export class DeviceSeverityComponent {

}
