import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ICommonVulnarability, ICommonVulnarabilityResponse, IDrop, ISelectedVulnarability } from '../common-vulnerability/common.vulnerability.model';
import { AuthService } from '../../services/auth.service';
import { RestService } from '../../services/rest.service';
import { ResponseService } from '../../services/response.service';
import { SharedService } from '../../services/shared.service';
import { MyScansService } from '../../services/my-scans.service';
import { CveAssetsService } from '../../services/cve-assets.service';
import { AgGridComponent } from '../ag-grid/ag-grid.component';
import { END_POINTS } from '../../constants/endpoints';
import { CONFIG } from '../../constants/config';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { RiskComponent } from '../risk/risk.component';
import { ComplianceComponent } from '../compliance/compliance.component';
import { SeverityVulComponent } from '../severit-vul/severity-vul.component';

@Component({
    selector: 'app-new-vulnerability',
    templateUrl: './new-vulnerability.component.html',
    styleUrls: ['./new-vulnerability.component.scss']
  })

  export class NewVulnerabilityComponent {
    @Input() title: string = '';
    @Input() showFilter: boolean = false;
    @Input() apiName: string = '';
    @Input() filterPage: string = '';
    @Input() payLoad: any = {};
    @Input() links: boolean = false;
    @Output() vulListLength = new EventEmitter<number>();
    public selectedVulnarability: ISelectedVulnarability = {};
    public vulnarabilityMenu: IDrop[] = [];
    public commonVulnarabilites: ICommonVulnarability[] = [];
    public allCommonVulnarabilites: ICommonVulnarability[] = [];
    private isFilterAppplied = false;
    public isApiCalled: boolean = false;
    @ViewChild('myGrid') grid!: AgGridComponent;
    @ViewChild('searchField') searchField!: ElementRef;
    public tableHeader = CONFIG.TABLE_INFO.NEW_VULNERABILITY.HEADER_LABEL;
    public tableProps = CONFIG.TABLE_INFO.NEW_VULNERABILITY.PROPS;
    public agDeviceColumnDefs: ColDef[] = [
        {
          headerName: this.tableHeader.PARAMETER,
          field: this.tableProps.PARAMETER,
          headerTooltip:this.tableHeader.PARAMETER,
          lockPosition:true,
          tooltipField: this.tableProps.PARAMETER,
          onCellClicked: (event: CellClickedEvent) => {
            this.getRowData(event);
          },
          width: 230,
          // pinned: 'left'
        },
        {
          headerName: this.tableHeader.SEVERITY,
          field: this.tableProps.SEVERITY,
          headerTooltip:this.tableHeader.SEVERITY,
          lockPosition:true,
          // pinned: 'left',
        //   tooltipField: this.tableProps.SEVERITY,
           cellRenderer: SeverityVulComponent,
          onCellClicked: (event: CellClickedEvent) => {
            this.getRowData(event);
          }
        },
        {
          headerName: this.tableHeader.ACCEPTED_RISK,
          field: this.tableProps.ACCEPTED_RISK,
          headerTooltip:this.tableHeader.ACCEPTED_RISK,
          cellRenderer: RiskComponent,
          onCellClicked: (event: CellClickedEvent) => {
            this.getRowData(event);
          }
        },
        {
            headerName: this.tableHeader.COMPLIANCE,
            field: this.tableProps.COMPLIANCE,
            headerTooltip:this.tableHeader.COMPLIANCE,
            cellRenderer: ComplianceComponent,
            onCellClicked: (event: CellClickedEvent) => {
              this.getRowData(event);
            },
            width: 150,
        },
      ];
    constructor(
        private responseService: ResponseService,
        private restService: RestService,
        private sharedService: SharedService,
        private authService: AuthService,
        private myScansService: MyScansService,
        private cveAssetsService: CveAssetsService) {

      }

      ngOnInit(): void {
        if (this.apiName) {
          this.fetchCommonVulFilter(this.fetchCommonVulFilterCallback);
        }
      }

      public fetchCommonVulFilter(callback: Function) {
        this.isApiCalled = false;
        const filterDetails = JSON.parse(this.authService.getCookie('vulnerability') || '[]');
        this.vulnarabilityMenu = filterDetails;
        if(this.vulnarabilityMenu && this.vulnarabilityMenu.length) {
          callback(this.vulnarabilityMenu);
        } else {
          this.restService.getApi(END_POINTS.DASHBOARD_COMMON_VUL_FILTER).subscribe({
            next: (response) => {
              const data = this.responseService.successResponse(response);
              if(data.status === 200 && data?.data?.vulnerabilitylevel) {
                this.setFilter('vulnerability', data.data.vulnerabilitylevel);
                callback(data?.data?.vulnerabilitylevel);
              }
            },
            error: (err) => {
              this.setFilter('vulnerability', []);
              callback([]);
              this.responseService.errorResponse(err);
            },
          })
        }
      }
      public setFilter(type: string, data: any) {
        this.vulnarabilityMenu = data;
        this.authService.setCookie(type, JSON.stringify(data));
      }
      public fetchCommonVulFilterCallback = (response: IDrop[]) => {
        this.isApiCalled = false;
        if (response.length) {
          this.vulnarabilityMenu = response;
          this.selectedVulnarability.commonVul = this.vulnarabilityMenu[0].name;
          this.selectedVulnarability.id = this.vulnarabilityMenu[0].id;
          this.setSelectedCookies();
          this.loadCommonVulnaribilites(this.loadCommonVulnaribilitesCallback, this.selectedVulnarability.id);
        }
      }

      setSelectedCookies(){
        if(this.filterPage) {
          const savedSelectedCookies = JSON.parse(this.authService.getCookie(this.filterPage) || '{}');
          if(savedSelectedCookies && Object.keys(savedSelectedCookies).length) {
            this.selectedVulnarability.commonVul = savedSelectedCookies.label;
            this.selectedVulnarability.id = savedSelectedCookies.id;
          }
          this.authService.setCookie(this.filterPage, JSON.stringify({label:this.selectedVulnarability.commonVul, id: this.selectedVulnarability.id }));
        }
      }

      public loadCommonVulnaribilitesCallback = (response: ICommonVulnarabilityResponse) => {
        this.isApiCalled = true;
        if(this.isFilterAppplied){
          this.commonVulnarabilites = [];
        }
        this.isFilterAppplied = false;
        if (response.status === 200) {
          const data = response.data.indexes || [];
          // const critical = data.filter((item) => item.vulnerability === 'Critical');
          // const high = data.filter((item) => item.vulnerability === 'High');
          // const medium = data.filter((item) => item.vulnerability === 'Medium');
          // const low = data.filter((item) => item.vulnerability === 'Low');
          const critical = data
          .filter((item) => item.vulnerability === 'Critical')
          .sort((a, b) => Number(b.accepted_risk) - Number(a.accepted_risk));

          const high = data
          .filter((item) => item.vulnerability === 'High')
          .sort((a, b) => Number(b.accepted_risk) - Number(a.accepted_risk));

          const medium = data
          .filter((item) => item.vulnerability === 'Medium')
          .sort((a, b) => Number(b.accepted_risk) - Number(a.accepted_risk));
          
          const low = data
          .filter((item) => item.vulnerability === 'Low')
          .sort((a, b) => Number(b.accepted_risk) - Number(a.accepted_risk));

          const sortedData = [...critical, ...high, ...medium, ...low];
          this.commonVulnarabilites = [...sortedData]
          this.vulListLength.emit(this.commonVulnarabilites.length);
          this.allCommonVulnarabilites = [...this.commonVulnarabilites];
            this.sharedService.setAPIData(CONFIG.PAGE_API.NEW_VULNERABILITY, response, this.commonVulnarabilites.length > 0);
            window.setTimeout(() => {
              this.grid.grid.api.sizeColumnsToFit();
            }, 10);
          }

      }

      public loadCommonVulnaribilites(callback: Function, vulnarability: string = 'All', spinnerType: number = 1): void {
        let req = {
          type: 1
        };
        if(this.payLoad){
          req = {...req, ...this.payLoad};
        }
        this.restService.postApi(this.apiName, req).subscribe({
          next: (response) => {
            callback(this.responseService.successResponse(response));
          },
          error: (err) => {
            callback(this.responseService.errorResponse(err));
          },
        })
      }

    public filterVulnarability(value: IDrop, isSelected: boolean = false) {
        this.selectedVulnarability.commonVul = value.name;
        this.selectedVulnarability.id = value.id;
      this.isFilterAppplied = true;
      if (this.selectedVulnarability.commonVul === 'All') {
        this.commonVulnarabilites = [...this.allCommonVulnarabilites];
      } else {
        this.commonVulnarabilites = this.allCommonVulnarabilites.filter(vul => vul.vulnerability === this.selectedVulnarability.commonVul);
      }
        if(this.filterPage) {
          this.authService.setCookie(this.filterPage, JSON.stringify({label:this.selectedVulnarability.commonVul, id: this.selectedVulnarability.id }));
        }
        }

        filterAssetsField() {
            this.grid.grid.api.setGridOption(
              "quickFilterText",
               this.searchField?.nativeElement?.value
            );
          }

          getRowData(row: any){
            // this.links? this.onNavigateTopage('security', row) : '';
            this.cveAssetsService.vulnerabilityRow.next(row.data);
           }
  }
