import { Component, Input, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { ToolTipConfig } from '../../constants/constant-data';

@Component({
  selector: 'app-cve-overlay-header',
  templateUrl: './cve-overlay-header.component.html',
  styleUrls: ['./cve-overlay-header.component.scss']
})
export class CveOverlayHeaderComponent {
  public toolTipConfig: any = ToolTipConfig;
  @Input() public selectedCVE: any;
  @Input() public cveDetails: any;
  
  constructor() {
   
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['selectedCVE'] &&
      _.isEmpty(changes['selectedCVE'].currentValue)) {
        this.selectedCVE = changes['selectedCVE'].currentValue;
    }
    if (changes && changes['cveDetails'] &&
      _.isEmpty(changes['cveDetails'].currentValue)) {
        this.cveDetails = changes['cveDetails'].currentValue;
    }
  }
  ngOnInit(): void { 
    
  }
}
