<div class="ag-center" *ngIf="isSuccess(params.data) && valideKebabMenu(params.data)">
    <button mat-icon-button class="mat-kebab-btn  show" [matMenuTriggerFor]="menu"
        (menuOpened)="onMenuOpen(params.data.id)" (menuClosed)="onMenuClose()"
        aria-label="Example icon-button with a menu">
        <mat-icon>more_vert</mat-icon>
    </button>
</div>
<mat-menu #menu="matMenu">
    <ng-container *ngFor="let item of menuListItems">
        <button [ngClass]="{'red-text':item==CANCESCHEDULE}" mat-menu-item *ngIf="kebabMenuController(item,params.data)"
            (click)="menuClick(item, params.data)">
            {{ item }}
        </button>
    </ng-container>
</mat-menu>