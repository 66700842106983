import { Injectable } from '@angular/core';
import { RestService } from './rest.service';
import { ResponseService } from './response.service';
import { END_POINTS } from '../constants/endpoints';
import { EncryptService } from './encrypt.service';

@Injectable({
  providedIn: 'root'
})
export class CredentionalService {

  constructor(private restService: RestService,
    private responseService: ResponseService,
    private encryptService: EncryptService,) {
   }
   public credentialTypes(callback: Function){
    this.restService.getApi(END_POINTS.CREDENTIAL_TYPES).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
   }

   public credentialList(callback: Function){ 
    this.restService.getApi(END_POINTS.CREDENTIAL_LIST).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    });
   }

   loadUserList(callback: Function, req: any) {
    this.restService.postApi(END_POINTS.USER_MANAGEMENT_LIST, req)?.subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      }
    })
  }

  getRoles(callback: Function) {
    const roleReq: any = {
      "order_column": "name",
      "sort_by": "ASC"
    };
    this.restService.postApi(END_POINTS.ORG_ROLES, roleReq)?.subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }

   public viewCredential(callback: Function,cred_id:any){
    this.restService.getApi(END_POINTS.CREDENTIAL_LIST + '/' + cred_id).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    });
   }

   public addCredential(callback: Function,credentials_payload:any){
    return this.restService.postApi(END_POINTS.CREDENTIAL_LIST, credentials_payload).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      }
    });
   }

   public editCredential(callback: Function,credentials_payload:any){
    return this.restService.putApi(END_POINTS.CREDENTIAL_LIST, credentials_payload).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      }
    });
   }

   public deleteCredential(callback: Function,cred_id:any){
    this.restService.deleteApi(END_POINTS.CREDENTIAL_LIST+ '/' + cred_id).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
   }

   public assetCredential(callback: Function,credentials_payload:any, new_cred:any){
    credentials_payload = Object.assign(credentials_payload, {
      password: this.encryptService.doEncryptLogin(credentials_payload.password),
      username: this.encryptService.doEncryptLogin(credentials_payload.username),
      encrypt:true
    })
    if(credentials_payload.enable_password){
      credentials_payload = Object.assign(credentials_payload, {
        enable_password: this.encryptService.doEncryptLogin(credentials_payload.enable_password),
      })
    }
   

    return this.restService.postApi(END_POINTS.RE_SCAN_CRED, credentials_payload).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      }
    });
   }

   public assetMapCredential(callback: Function,credentials_payload:any){
    credentials_payload = Object.assign(credentials_payload, {
      password: this.encryptService.doEncryptLogin(credentials_payload.password),
      username: this.encryptService.doEncryptLogin(credentials_payload.username),
      encrypt:true
    })
    if(credentials_payload.enable_password){
      credentials_payload = Object.assign(credentials_payload, {
        enable_password: this.encryptService.doEncryptLogin(credentials_payload.enable_password),
      })
    }
    if(credentials_payload.api_token){
      credentials_payload = Object.assign(credentials_payload, {
        api_token: this.encryptService.doEncryptLogin(credentials_payload.api_token),
      })
    }
    return this.restService.postApi(END_POINTS.MAP_CRED, credentials_payload).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      }
    });
   }

}
