<div class="permissions-modalbox">
    <button class="btn blue-btn" >Save Changes</button>
    <span>
        <button mat-button class="close-icon" [mat-dialog-close]="true">
        <mat-icon>close</mat-icon></button></span>
    <h3>Customize User Permissions</h3>
    <div class="permissions-section d-flex">
        <div class="permissions-list ">
            <ul class="tool_list">
                <li class="d-flex">
                    <div class="tool_label ">
                        <label>Upload File</label>
                    </div>
                    <div class="tool_switch right_ele">
                        <mat-slide-toggle [(ngModel)]="customizePayload.upload"
                            (change)="OnToggleChange($event, 'upload')"></mat-slide-toggle>
                    </div>
                </li>
                <li class="d-flex">
                    <div class="tool_label ">
                        <label>SSH Connection</label>
                    </div>
                    <div class="tool_switch right_ele">
                        <mat-slide-toggle [(ngModel)]="customizePayload.ssh"
                            (change)="OnToggleChange($event, 'ssh')"></mat-slide-toggle>
                    </div>
                </li>
                <li class="d-flex">
                    <div class="tool_label">
                        <label>API connections</label>
                    </div>
                    <div class="tool_switch right_ele">
                        <mat-slide-toggle [(ngModel)]="customizePayload.api"
                            (change)="OnToggleChange($event, 'api')"></mat-slide-toggle>
                    </div>
                </li>
                <li class="d-flex">
                    <div class="tool_label ">
                        <label>Whitelisting</label>
                    </div>
                    <div class="tool_switch right_ele">
                        <mat-slide-toggle [(ngModel)]="customizePayload.whitelist"
                            (change)="OnToggleChange($event, 'whitelist')"></mat-slide-toggle>
                    </div>
                </li>
                <li class="d-flex">
                    <div class="tool_label ">
                        <label>Set limits on No. Of scans per day</label>
                    </div>
                    <div class="tool_switch right_ele ">
                        <div class="tool_value">
                            <div class="value-button" id="decrease" (click)="onScanHandler('decrement')"
                                value="Decrease Value">-
                            </div>
                            <input type="number" id="number" [(ngModel)]="customizePayload['scans_perday']" />
                            <div class="value-button" id="increase" (click)="onScanHandler('increment')"
                                value="Increase Value">+
                            </div>
                        </div>
                    </div>
                </li>
                <li class="d-flex">
                    <div class="tool_label ">
                        <label>Duration between two scans</label>
                    </div>
                    <div class="tool_switch right_ele">
                        <mat-slide-toggle [(ngModel)]="customizePayload.duration_btw_scans"
                            (change)="OnToggleChange($event, 'duration_btw_scans')"></mat-slide-toggle>
                    </div>
                </li>
                <li class="d-flex">
                    <div class="tool_label ">
                        <label>Downloads Reports</label>
                    </div>
                    <div class="tool_switch right_ele">
                        <mat-slide-toggle [(ngModel)]="customizePayload.download_reports"
                            (change)="OnToggleChange($event, 'download_reports')"></mat-slide-toggle>
                    </div>
                </li>
                <li class="d-flex">
                    <div class="tool_label ">
                        <label>Email alerts for Scans</label>
                    </div>
                    <div class="tool_switch right_ele">
                        <mat-slide-toggle [(ngModel)]="customizePayload.emails_alerts"
                        (change)="OnToggleChange($event, 'emails_alerts')"></mat-slide-toggle>
                    </div>
                </li>
                <li class="d-flex">
                    <div class="tool_label ">
                        <label>Add or upload assets to the Asset Library</label>
                    </div>
                    <div class="tool_switch right_ele">
                        <mat-slide-toggle [(ngModel)]="customizePayload.device_library"
                            (change)="OnToggleChange($event, 'device_library')"></mat-slide-toggle>
                    </div>
                </li>
                <li class="d-flex">
                    <div class="tool_label ">
                        <label>Adding parameters</label>
                    </div>
                    <div class="tool_switch right_ele">
                        <mat-slide-toggle [(ngModel)]="customizePayload.adding_params"
                            (change)="OnToggleChange($event, 'adding_params')"></mat-slide-toggle>
                    </div>
                </li>
            </ul>
        </div>
        <div class="regions-list ">
            <app-filter [resetButtonRequired]="true" [title]="'Set Regions & Assets'" (selectedFiltersEvent)="selectedFilters($event)" [filterColumnData]="filterColumnData"></app-filter>
        </div>
    </div>
</div>