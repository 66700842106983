import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent implements OnInit {
  acoutDetail: any;
  constructor() {
    this.acoutDetail = {
      email:'abc@gmail.com'
    }
   }

  ngOnInit(): void {
  }

}
