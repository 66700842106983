import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { CONSTANT, ToolTipConfig } from 'src/app/modules/shared/constants/constant-data';
import { UsersComponent } from '../users.component';
import { DialogService } from 'src/app/modules/shared/services/dialog.service';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
import { IUserDetail } from 'src/app/modules/shared/model/profile/profile';
import { IUpdateUserRoleReq, RoleItem, User } from '../model/user.model';
import { SpinnerService } from 'src/app/modules/shared/services/spinner.service';
import { UserManagementService } from 'src/app/modules/shared/services/user-management.service';
import { MessageServiceService } from 'src/app/modules/shared/services/message-service.service';
import { IToastrOptions } from 'src/app/modules/shared/model/IMessage.model';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent {
  public params!: ICellRendererParams;
  filterMenuOpen = false;
  // public toolTipOption = ToolTipConfig;
  public disableTooltip = false;
  userData: IUserDetail = {};
  roleItems: RoleItem[] = [];
  public admin = CONSTANT.ADMIN_ROLE;
  constructor(
    private userManagementService: UserManagementService,
    private spinnerService: SpinnerService,
    private messageService: MessageServiceService,
    private userComp: UsersComponent
  ) {
    if(userComp.userData){
    this.userData = userComp.userData
    }
    if(userComp.roleItems){
    this.roleItems = userComp.roleItems
    }
  } 

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  updateRoleInSelectedUser(element: User, role: RoleItem): void {
    if (element.role?.toLocaleLowerCase() !== role.name?.toLocaleLowerCase()) {
      this.spinnerService.show();
      const req: IUpdateUserRoleReq = {};
      req.role_id = role.id;
      req.user_id = element.id;

      this.userManagementService.updateRoleForSelectedUser((response: any) => {
        this.spinnerService.hide();
        if (response.status === 200) {
          const index = this.userComp.displayUserData.findIndex((x: User) => x === element);
          if (index > -1) {
            this.userComp.displayUserData[index].role = role.name
          } else {
            this.userComp.displayUserData[index].role = null
          }
          const snakMsg: IToastrOptions = {
            message: response.message,
          }
          this.messageService.showSuccess(snakMsg);
        }

      }, req);
    }
  }

}
