<!--Delete popup-->
<!-- <div class="vpnpopup-modelbox text_center">
    <h3>Insert an IP to test?</h3>
    <p class="sub-text">If you delete, you will not be able to find the VPN again.</p>
    <div class="vpn-form">
        <div class="form-field">
            <mat-form-field class="example-full-width border-field" appearance="outline">
                <mat-label>Password</mat-label>
                <input formControlName="password" matInput [type]="hide ? 'password' : 'text'">
                <mat-icon matSuffix (click)="hide = !hide" class="hide-icon">{{hide ? 'visibility_off' :
                    'visibility'}}</mat-icon>
            </mat-form-field>
        </div>
        <div class="vpn-btns">
            <button mat-raised-button class="btn btn-lightblack-outline">Cancel</button>
            <button mat-raised-button class="btn btn-tartorange">Delete</button>

        </div>
    </div>

</div> -->

<!--Pause popup-->
<!-- <div class="vpnpopup-modelbox text_center">
    <h3>Are you sure want to Pause "Tunnel Name"?</h3>
    <p class="sub-text">You won't be able to connect using this tunnel. You can resume it later.</p>

    <div class="vpn-btns m-top">
        <button mat-raised-button class="btn btn-lightblack-outline">Cancel</button>
        <button mat-raised-button class="btn btn-Pumpkin">Pause</button>
    </div>

</div> -->


<!--Test popup-->


<div class="vpnpopup-modelbox text_center">
    <h3>Insert an IP to test</h3>
    <div class="vpn-form">
        <form [formGroup]="ipForm">
            <div class="form-field">
                <mat-form-field class="example-full-width border-field" appearance="outline">
                    <mat-label>Enter IP</mat-label>
                    <input formControlName="ip" matInput>
                </mat-form-field>
            </div>

            <div class="vpn-btns" *ngIf="!testSuccess">
                <div *ngIf="isSpinner" class="loader-btn" [ngClass]="{isSpinner: 'loader-btn disabled'}">
                    <span class="btn-loader spin">
                      <img src="../../../../../../assets/images/vpn-loader.svg" />
                    </span>
                </div>

                <button *ngIf="!isSpinner" mat-raised-button class="btn btn-lightblack-outline" [mat-dialog-close]="true">Cancel</button>
                
                <!-- Disable the button if IP field is invalid -->
                <button *ngIf="!isSpinner" mat-raised-button class="btn btn-primary" 
                        (click)="testVpn()" [ngClass]="{'disabled':ipForm.invalid}">
                    Test
                </button>
            </div>
        </form>

        <div *ngIf="testSuccess">
            <div>
                <p class="sucsess-note" *ngIf="vpn_tunnel_status && pingStatus">
                    <span class="status-icon pass">
                        <mat-icon>check</mat-icon>
                    </span>
                    Test Successful
                </p>
                <p class="sucsess-note" *ngIf="isping">
                    <span class="status-icon failed">
                        <mat-icon>close</mat-icon>
                    </span>
                    Test Failed
                </p>
                <p class="sub-note">{{description}}</p>
            </div>

            <div class="vpn-btns">
                <button mat-raised-button class="btn btn-primary-outline" [mat-dialog-close]="true">Close</button>
            </div>
        </div>
    </div>
</div>
 