<div class="netsec-modelbox">
    <!-- <h2 mat-dialog-title class="title">{{title}}</h2> -->
    <div [innerHTML]="content" class="title"></div>
    <p class="sub-text">
        {{this.menuItemData.subContent}}
    </p>
    <div class="modelbox-footer">
        <button mat-button mat-dialog-close (click)="onClick(false)" class="mat-stroked-button btn-lightblack-outline">{{this.menuItemData?.noBtnName}}</button>
        <button mat-button [ngClass]="{'filled-btn-1': isTrue}" [class]="menuItemData.className" class="mat-stroked-button" mat-dialog-close
            (click)="onClick(true)">{{this.menuItemData.yesBtnName}}</button>
    </div>
</div>