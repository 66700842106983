import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceService } from 'src/app/modules/shared/services/device.service';

@Component({
  selector: 'app-asset-summary-download',
  templateUrl: './asset-summary-download.component.html',
  styleUrls: ['./asset-summary-download.component.scss']
})
export class AssetSummaryDownloadComponent {
  isBtnSpinner:boolean = false;
  selectedFormat: string = "pdf";
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private deviceService: DeviceService,) {
  }
  
  ngOnInit(): void {
  }
  
 

  onClose(callBack: Function | undefined) {
      if (callBack) {
          callBack(this.selectedFormat);
      }
  }
}
