import { Injectable } from '@angular/core';
import { END_POINTS } from '../constants/endpoints';
import { RestService } from './rest.service';
import { ResponseService } from './response.service';
import { IUserDetail, IUserDetailResponse } from '../model/profile/profile';
import { catchError, map, Observable, of, Subject } from 'rxjs';
import { CommonService } from './common.service';
import { AuthService } from './auth.service';
import { HttpEventType } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private _userDetails: IUserDetail = {};
  private _userImage: string = '';
  private _profileImage: string = '';
  public updateImage = new Subject();
  public updateImageObserver = this.updateImage.asObservable();
  public userImageSubject = new Subject<void>();
  private deletedPercentage = new Subject<number>();
  userImage = this.userImageSubject.asObservable();

  constructor(
    private restService: RestService,
    private responseService: ResponseService,
    private commonService: CommonService,
    private authService: AuthService) { }

  public getUserDetails(): IUserDetail {
    return this._userDetails
  }

  public getDeletedPercentageObservable() {
    return this.deletedPercentage.asObservable();
  }

  public setUserDetails(detials: IUserDetail): void {
    this._userDetails = detials;
  }

  public getUserImage(): string {
    return this._userImage
  }

  public setUserImage(detials: string, type: string): void {
    if (type === 'profile') {
      this._profileImage = detials;
    } else if (type === 'default') {
      this._userImage = detials;
    } else {
      this._profileImage = detials;
      this._userImage = detials;
    }
  }

  public reset(): void {
    this._userDetails = {};
    this._userImage = '';
    this._profileImage = '';
  }
  public fetchDevices(): Observable<any> {
    return this.restService.getApi(END_POINTS.AFFECTED_DEVICE).pipe(
      map(res => this.responseService.successResponse(res)),
      catchError(err => of(this.responseService.errorResponse(err))) 
    );
  }

  public fetchUserDetails(callBack: Function,isApicallAgain:boolean= false): void {
    this._userDetails = JSON.parse(this.authService.getCookie('userInfo') || '{}');
    if (Object.keys(this._userDetails)?.length > 0 && !isApicallAgain) {
      if(!this._userDetails.hasOwnProperty('encrypt')) {
        this._userDetails.encrypt = false;
      }
      this.commonService.setEncryptionAllowed(this._userDetails.encrypt);
      callBack(this._userDetails);
    } else {
      this.restService.getApi(END_POINTS.USER_DETAILS).subscribe({
        next: (response) => {
          const results: IUserDetailResponse = this.responseService.successResponse(response);
          if (results.status === 200 && results.data) {
            this._userDetails = results.data;
            if(!this._userDetails.hasOwnProperty('encrypt')) {
              this._userDetails.encrypt = false;
            }
            this.commonService.setEncryptionAllowed(this._userDetails.encrypt);
            this.authService.setCookie('userInfo', JSON.stringify(this._userDetails || {}));
            callBack(this._userDetails);
          } else {
            callBack({});
            this.authService.setCookie('userInfo', '{}');
            this.authService.logOut();
          }
        },
        error: (err) => {
          this.responseService.errorResponse(err)
          callBack({});
          this.authService.logOut();
        },
      });
    }
  }
  public isNewUserLoggedIn(callback:Function) {
    const isNewUserDetails = JSON.parse(this.authService.getCookie('isNewUserDetails') || '{}');
    if (Object.keys(isNewUserDetails)?.length > 0 && !isNewUserDetails?.isAddOrDeleteAction && isNewUserDetails?.device_count > 0) {
      callback(isNewUserDetails);
    } else {
      this.restService.getApi(END_POINTS.IS_NEW_USER).subscribe({
        next: (response) => {
         const results: any = this.responseService.successResponse(response);
           if (results.status === 200 && results.data) {
            const isNewUserDetails = JSON.parse(this.authService.getCookie('isNewUserDetails') || '{}');
            if(Object.keys(isNewUserDetails)?.length > 0 && isNewUserDetails?.isAddOrDeleteAction) {
              results.data.isAddOrDeleteAction = isNewUserDetails?.isAddOrDeleteAction;
            }
             this.authService.setCookie('isNewUserDetails', JSON.stringify(results.data || {}));
             callback(results.data);
           }
        },
        error: (err) => {
          callback({new_user: false});
          this.authService.setCookie('isNewUserDetails', JSON.stringify({new_user: false} || {}));
          //this.authService.logOut();
        }
      });
    }
   }
  public getOrgDetails(callBack: Function): void {
    this.restService.getApi(END_POINTS.ORG_DETAILS).subscribe({
      next: (response) => {
        callBack(this.responseService.successResponse(response));
      },
      error: (err) => {
        callBack(this.responseService.errorResponse(err));
      },
    });
  }

  public postUserDetails(profileData: any, callBack: Function): void {
    const req = {
      first_name: profileData.first_name,
      last_name: profileData.last_name,
      phone_number: '6414844541',
      country_code: '+91'
    };
    this.restService.postApi(END_POINTS.EDIT_USER, req).subscribe({
      next: (response) => {
        callBack(this.responseService.successResponse(response));
      },
      error: (err) => {
        callBack(this.responseService.errorResponse(err));
      },
    })
  }

  public deleteUserData(profileData: any, callBack: Function): void {
    const req = {
      organization_id: profileData.organization_id
      
    };
    this.restService.postApi(END_POINTS.DELETE_ORG, req).subscribe({
      next: (response) => {
        this.deleteApiEvent(response, callBack);
      },
      error: (err) => {
        callBack(this.responseService.errorResponse(err));
      },
    })
  }

  deleteApiEvent(response: any, callBack: Function) {
    let uploadedPercentage = 0;
    switch (response.type) {
      case HttpEventType.Sent:
        this.deletedPercentage.next(0)

        break;
      case HttpEventType.UploadProgress:
        
        if (response && response?.total) {
          this.deletedPercentage.next(Math.round(response.loaded / response.total * 100));
        }
        break;
      case HttpEventType.DownloadProgress:
        
        if (response && response?.total) {
          this.deletedPercentage.next(Math.round(response.loaded / response.total * 100));
        }
        break;
      case HttpEventType.Response:
        const updatedRes = this.responseService.successResponse(response);
        if(updatedRes.status==200) {
          this.deletedPercentage.next(100);
        }
        
        // Remove the names of the files that have finished uploading from the set
        callBack(updatedRes);
        break;
    }

  }

  public saveUserImage(profileImageData: any, callBack: Function): void {
    this.restService.postApi(END_POINTS.EDIT_USER_IMAGE, profileImageData).subscribe({
      next: (response) => {
        callBack(this.responseService.successResponse(response));
      },
      error: (err) => {
        callBack(this.responseService.errorResponse(err));
      },
    })
  }

  public createImageFromBlob(image: Blob, callBack: Function, type: string) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      const str: string = reader.result as string;
      if (type !== 'org') {
        this.setUserImage(str, type);
      }
      callBack(str);
    }, false);

    if (image.size > 57 && image && type!='org') {
      reader.readAsDataURL(image);
    } else if(image.size > 78 && type=='org' && image){
      reader.readAsDataURL(image);
    } 
    else {
      callBack('');
    }
  }

  public fetchUserImage(callBack: Function, width: number, height: number, type: string = 'default'): void {
    if (this._userImage && type === 'default') {
      callBack(this._userImage);
    } else if (this._profileImage && type === 'profile') {
      callBack(this._profileImage)
    } else {
      this.restService.getApi(END_POINTS.GET_USER_IMAGE + `?width=${width}&height=${height}`, null, 'blob').subscribe({
        next: (response) => {
          const blob = new Blob([response as BlobPart], { type: 'image/png' });
          this.createImageFromBlob(blob, callBack, type);
        },
        error: (err) => {
          this.responseService.errorResponse(err)
          callBack('');
        },
      });
    }
  }

  public saveOrgImage(OrgImageData: any, callBack: Function): void {
    this.restService.postApi(END_POINTS.EDIT_ORG_IMAGE, OrgImageData).subscribe({
      next: (response) => {
        callBack(this.responseService.successResponse(response));
      },
      error: (err) => {
        callBack(this.responseService.errorResponse(err));
      },
    })
  }

  public fetchOrgImage(callBack: Function, width: number, height: number, type: string = 'org'): void {
    this.restService.getApi(END_POINTS.GET_ORG_IMAGE + `?width=${width}&height=${height}`, null, 'blob').subscribe({
      next: (response) => {
        const blob = new Blob([response as BlobPart], { type: 'image/png' });
        this.createImageFromBlob(blob, callBack, type);
      },
      error: (err) => {
        callBack(this.responseService.errorResponse(err));
      },
    });
  }
  public postOrgDetails(OrganisationData: any, callBack: Function): void {
    const req = {
      organization_address: OrganisationData.organization_address,
      location: OrganisationData.organization_location
    };
    this.restService.postApi(END_POINTS.EDIT_ORG, req).subscribe({
      next: (response) => {
        callBack(this.responseService.successResponse(response));
      },
      error: (err) => {
        callBack(this.responseService.errorResponse(err));
      },
    })
  }
}
