import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';
import { CONFIG } from '../../shared/constants/config';
import { CVE_PAGE } from '../../shared/constants/constant-data';

@Component({
  selector: 'app-epss-header',
  templateUrl: './epss-header.component.html',
  styleUrls: ['./epss-header.component.scss']
})
export class EpssHeaderComponent  implements IHeaderAngularComp {
  public params!: IHeaderParams;
  public tableHeader = CONFIG.TABLE_INFO.CVE_DASHBOARD.HEADER_LABEL;
  public get tooltip() {
    return CVE_PAGE.epssHeaderTooltip
  }
  agInit(params: IHeaderParams): void {
      this.params = params;
  }

  refresh(params: IHeaderParams) {
      return false;
  }
}