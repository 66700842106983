<div class="netsec-modelbox">
  <form [formGroup]="cybergraph">
    <h2 mat-dialog-title class="title">{{data.title}}</h2>
    <div class="modelbox-body">
      <p class="sub-text">{{data.content}}</p>
      <!-- <p class="sub-header">Report Format</p>
        <div class="radio-options">
            <mat-radio-group class="report-label">
                <mat-radio-button name="reportFormat"  value="pdf">Pdf</mat-radio-button>
                <mat-radio-button name="reportFormat"  value="excel">Excel</mat-radio-button>
            </mat-radio-group>
        </div> -->
      <div class="input-box-container">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>From</mat-label>
          <input matInput [matDatepicker]="fromDate" placeholder="MM/DD/YYYY" [(ngModel)]="fromDateValue"
            formControlName="fromDate" [min]="fromDateProp" [max]="today">
          <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
          <mat-datepicker #fromDate></mat-datepicker>
          <mat-error *ngIf="dateError">{{ dateError }}</mat-error>
        </mat-form-field>
      </div>

      <div class="input-box-container">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>To</mat-label>
          <input matInput [matDatepicker]="toDate" placeholder="MM/DD/YYYY" [(ngModel)]="toDateValue"
            formControlName="toDate" [max]="today" [min]="fromDateProp">
          <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
          <mat-datepicker #toDate></mat-datepicker>
          <mat-error *ngIf="dateError">Required</mat-error>
        </mat-form-field>

      </div>
      <mat-error *ngIf="dateError">Invalid date range</mat-error>
    </div>

    <div class="modelbox-footer">
      <button (click)="onClose(btn?.callBack,btn.label)"
        [disabled]="btn.label === 'Download'? cybergraph.invalid: false" mat-button *ngFor="let btn of data.buttons"
        [ngClass]="[btn?.class, (btn.label === 'Download' && cybergraph.invalid) ? 'disabled-download' : '']"
        class="mat-stroked-button">{{btn.label}}</button>
    </div>
  </form>
</div>