import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { DeviceManagementComponent } from '../device-management.component';
import { IDeviceList } from '../device.model';

@Component({
  selector: 'app-last-scan',
  templateUrl: './last-scan.component.html',
  styleUrls: ['./last-scan.component.scss']
})
export class LastScanComponent implements ICellRendererAngularComp{
  public params!: ICellRendererParams;
  public get permissionProps() {
    return this.deviceComp.permissionProps
  }
  public get is_rescan() {
    return this.deviceComp.is_rescan;
  }
  constructor(private deviceComp: DeviceManagementComponent) {
    
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
  public hasPermission(type: string){
    return this.deviceComp.hasPermission(type)
  }
  public rescan(device: IDeviceList) {
    this.deviceComp.rescan(device);
  }
  public getStatus(item: any): boolean {
    return this.deviceComp.getStatus(item);
  }
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
}
