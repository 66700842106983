<div class="notification-wrapper">
    <app-notification-message 
        *ngIf="isNotificationVisible" 
        [type]="'notification'"
        class="top"
        [data]="notificationObj" 
        (toastDismissed)="onToastDismissed('notification')">
    </app-notification-message>
    
    <app-notification-message 
        *ngIf="isMessageVisible" 
        [type]="'snackMsg'"
        class="bottom"
        [data]="messageObj" 
        (toastDismissed)="onToastDismissed('message')">
    </app-notification-message>
</div>
