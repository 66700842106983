import { Injectable } from '@angular/core';
import { END_POINTS } from '../constants/endpoints';
import { RestService } from './rest.service';
import { ResponseService } from './response.service';
import { ISelectedListDetails } from '../components/list/list.model';
import { Utils } from '../utils';
import { SortingFilterService } from './sorting-filter.service';
import { GlobalSearchService } from './global-search.service';
import { Observable, catchError, throwError } from 'rxjs';
import { ApiService, IAPIDetails } from './api.service';
import { CONFIG } from '../constants/config';

@Injectable({
  providedIn: 'root'
})
export class SecurityInformationService {

  constructor(private restService: RestService,
    private responseService: ResponseService,
    private sortingFilterService: SortingFilterService,
    private globalSearchService: GlobalSearchService,
    private apiService: ApiService) { }

  public loadSecurityScore(api:string, callback: Function): void {   
      this.restService.getApi(api).subscribe({
        next: (response) => {
          callback(this.responseService.successResponse(response));
        },
        error: (err) => {
          callback(this.responseService.errorResponse(err));
        },
      })
  }

  public fetchSecurityInformationList(page: number = 1, numberOfResults: number, search: string = '',callback:Function) {
    //let apiUrl = END_POINTS.SECURITY_INFORMATION_LIST;
    const parameters: string[] = Utils.getSelectedItems(this.sortingFilterService?.filter.securityInfo?.filters.parameters);
    const severity: string[] = Utils.getSelectedItems(this.sortingFilterService?.filter.securityInfo?.filters.severity);
    const benchmark: string[] = Utils.getSelectedItems(this.sortingFilterService?.filter.securityInfo?.filters.benchmark)
    const acceptedRiskSort = Utils.getSelectedItems(this.sortingFilterService?.filter.securityInfo?.filters.accepted_risk_sort);
    const deviceFailedSort = Utils.getSelectedItems(this.sortingFilterService?.filter.securityInfo?.filters.device_failed_sort);
    const req: any = {
      content_number: numberOfResults,
      pages: page
    };
    if(this.globalSearchService.isRedirected && this.globalSearchService.searchTerm) {
      req.search = this.globalSearchService.searchTerm;
      req.redirection=true;
    }
    if(search) {
      req.search = search;
    }
    req.filters = {
      parameters: parameters.length ? parameters.toString(): '',
      accepted_risk_sort:acceptedRiskSort.length ? acceptedRiskSort.toString(): '',
      device_failed_sort:deviceFailedSort.length ? deviceFailedSort.toString(): '',
      severity:severity.length ? severity: [],
      benchmark:benchmark.length ? benchmark: [],  
    }
    const apiDetails: IAPIDetails | any = { 
      url: [],
      req: [],
      method: CONFIG.METHOD.POST,
      responseType: CONFIG.RESPONSE_TYPE.NONE,
      callBack: callback
    }
    req.type = 1;
    apiDetails.req = req;
    apiDetails.url = END_POINTS.SECURITY_INFORMATION_LIST;
    this.apiService.callAPI(apiDetails);
    // return this.restService.postApi(apiUrl, req).pipe(
    //   catchError((error) => {
    //     return throwError(error);
    //   })
    // )
  }

  public fetchSelectedSecurityDetail(id: any, callback: Function) {
    const req = {
      parameter_id: id,
      global_filter:true
    }
    this.restService.postApi(END_POINTS.SECURITY_INFORMATION_SELECTED_DETAIL, req).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  }
  public fetchSelectedSecurityAssetsList(req: any, callback: Function) {
    const reqObj: any = {
      parameter_id: req.parameter_id.id,
      global_filter:true,  
      content_number: req.content_number,
      pages: req.pages
    }
    reqObj.type=1;
    const apiDetails: IAPIDetails | any = { 
      url: [],
      req: [],
      method: CONFIG.METHOD.POST,
      responseType: CONFIG.RESPONSE_TYPE.NONE,
      callBack: callback
    }
    req.type = 1;
    apiDetails.req = reqObj;
    apiDetails.url = END_POINTS.SECURITY_INFORMATION_SELECTED_ASSETS_LIST;
    this.apiService.callAPI(apiDetails);


    // this.restService.postApi(END_POINTS.SECURITY_INFORMATION_SELECTED_ASSETS_LIST, reqObj).subscribe({
    //   next: (response) => {
    //     callback(this.responseService.successResponse(response));
    //   },
    //   error: (err) => {
    //     callback(this.responseService.errorResponse(err));
    //   },
    // })
  }
  public updateWhiteListItem(selectedDevices: ISelectedListDetails[], comment: string, callback: Function) {
    let deviceList: ISelectedListDetails[] = [];
    selectedDevices.forEach((device: ISelectedListDetails)=>{
      deviceList.push({
        white_list:device.white_list,
        device_id: device.device_id,
        uid: device.uid,
        master_data_id:device.master_data_id
       });
    })
    const req = {
      comments: comment,
      devices_ids: deviceList
    }
    this.restService.postApi(END_POINTS.WHITE_LIST, req).subscribe({
      next: (response) => {
        callback(this.responseService.successResponse(response));
      },
      error: (err) => {
        callback(this.responseService.errorResponse(err));
      },
    })
  } 
}
