<div class="ag-center">
    <ng-container *ngIf="isSuccess(params.data); else emptyScore">
        <span class="br-small white-text sec-value" [ngClass]="getScoreStatus(params.data.security_score_rounded)">
            {{!isDecommissioned ? (params.data.security_score_rounded | numberFormat) :
            params.data.security_score_rounded}}
        </span>
    </ng-container>
    <ng-template #emptyScore><span class="vulnerab_label label_dot critical"
            *ngIf="isFailed(params.data); else cancelScore">Failed</span>
    </ng-template>
    <ng-template #cancelScore>
        <span class="br-small white-text sec-value bg-critical"
            *ngIf="isCanceled(params.data)">{{params.data.security_score && params.data.security_score >
            0 ? params.data.security_score : 'Cancelled'}}</span>
    </ng-template>
</div>