<div class="details-popup">
    <div class="details-left">
        <div class="details-header white-box-header">
            <div class="header-left left-mgt-head">
                <div class="search-bar">
                    <mat-form-field appearance="fill">
                        <mat-label>Search Assets</mat-label>
                        <input matInput placeholder=""
                            autocomplete="off"
                            type="text" tabindex="-1"
                            #searchField
                            (input)="filterAssetsField()"
                            >
                        <mat-icon class='search-icon' matPrefix>search</mat-icon>
                    </mat-form-field>
                </div>
            </div>
            <div class="header-right">
                <div class="whitelisted-btn">
                    <button mat-raised-button  class="btn btn-tertiary "
                    [disabled]="isAcceptedRiskEnableDisable()"
                     (click)="onAcceptedRiskHandler()">Accept Risk</button>
                </div>
                <div class="filter-btn" (click)="openRegionSidebar()">
                    <button mat-button class="btn icon-btn applied"><mat-icon>filter_alt</mat-icon></button>
                </div>
                <div class="close-btn">
                    <button mat-button class="btn icon-btn" (click)="closeSidenav()"><mat-icon>cancel</mat-icon></button>
                </div>
            </div>

        </div>
         <div id="whitelistDetails" class="whitelist-details">
            <app-ag-grid
              #myGrid
              [agDeviceColumnDefs]="colDefs"
              [data]="dataSource?.data"
              [loader]="isApiCallDone"
              [gridClass]="'whitlist-details-table ag-grid-loader-bg'"

             >
             </app-ag-grid>
             <!-- (onRowSelectedChange)="onRowSelected($event)"
              (selectionChanged)="onRowSelectedChange($event)" -->
            <!-- <p class="no-data" *ngIf="(dataSource && (dataSource.data?.length === 0 && isApiCallDone) || (dataSource?.filteredData?.length === 0 && isApiCallDone)) else skeleton_loader">
                {{noDataMsg}}
            </p>
          <ng-template #skeleton_loader>
            <div class="item" *ngIf="((isPreloaderEligible() && isloaderEligible) || isDataAvailableOnFilter)">
              <ngx-skeleton-loader count="6" class="table-loader" [theme]="{
                width: '15%',
                'border-radius': '0',
                height: '15px',
                'margin-bottom': '10px'
              }" *ngFor="let item of [].constructor(dataSource?.data?.length === 0 ? 18 : 3); let i = index">
              </ngx-skeleton-loader>
            </div>
          </ng-template> -->
            <!-- <div *ngIf="(dataSource && (dataSource.data?.length === 0 && isApiCallDone) || (dataSource?.filteredData?.length === 0 && isApiCallDone))" class="no-data">{{noDataMsg}}</div> -->
        </div>
        <div class="details-table-rowcount" *ngIf="totalNumberofDevice > 0">
            <div class="selected-device">{{ state === 1 ? (totalNumberofDevice - unSelectedRows.length) : selectedRows.length }} / {{totalNumberofDevice}} Assets Selected</div>
            <div class="selectall-device">
                <div class="selectall-device">
                    <button class="btn-accepted-select" *ngIf="!toggleSelectAll" (click)="selectAll()">Select all {{ totalNumberofDevice }} Assets </button>
                    <button class="btn-accepted-deselect" *ngIf="toggleSelectAll" (click)="deselectAll()">Deselect all {{ totalNumberofDevice }} Assets</button>
                  </div>
            </div>
        </div>
    </div>
    <div class="details-right">
        <div class="scan-item-details" *ngIf="toggleWindows === 1">
            <div class="item" *ngIf="!isWhiteListDetailsParamsApiCallDone">
                <!-- <ngx-skeleton-loader count="2" class="rhs-content-loader tab-content-loader"> </ngx-skeleton-loader> -->
                <ngx-skeleton-loader count="1" class="rhs-content-loader selected-severity-loader"> </ngx-skeleton-loader>
                <ngx-skeleton-loader count="2" class="rhs-content-loader beach-mark-loader"> </ngx-skeleton-loader>
                <ngx-skeleton-loader count="2" class="rhs-content-loader graph-section-loader"> </ngx-skeleton-loader>
                <ngx-skeleton-loader count="6" class="rhs-content-loader content-loader"> </ngx-skeleton-loader>
              </div>
            <div class="details-section" *ngIf="isWhiteListDetailsParamsApiCallDone">
                <div class="selected-list-header" [ngClass]="addBadgeClass(vulnerabilityType||'')"  *ngIf="selectedListRow && selectedListRow.paramter">
                    <p class="selected-list-header-title text_ellipsis" appSafariEllipsis [options]="toolTipOption" [tooltip]="selectedListRow.paramter">{{selectedListRow.paramter}} </p>
                    <div class="selected-list-header-info">
                        <div class="selected-list-filecount">{{vulnerabilityType}}</div>
                    </div>
                </div>
                <div class="details-info">
                    <div class="details-info-benchmark" *ngIf="selectedRowInfoDetails?.benchmark_names">
                        <label>Benchmark:</label> <span
                            class="benchmark-values">{{selectedRowInfoDetails?.benchmark_names}}</span>
                    </div>
                    <div class="details-info-content" *ngIf="!showNist">
                        <div class="details-info-values">
                            <div class="details-info-values-graf" *ngIf="selectedRowInfoDetails?.device_count">
                                <!-- <img src="../../assets/images/Outlne Stroked.png"> -->
                                <div class="doughnut-graph">
                                <app-semi-donut-chart  [dynamicChartData]="dynamicChartData"></app-semi-donut-chart>
                            </div>
                                <p class="graf-value">{{selectedRowInfoDetails?.device_count?.total_device_count}}</p>
                                <p class="graf-title">Total Assets</p>
                            </div>
                            <div class="details-info-values-legends" *ngIf="selectedRowInfoDetails?.device_count">
                                <ul class="legends">
                                    <li><label>Assets Failed:</label>
                                        <span
                                            class="bg-critical">{{selectedRowInfoDetails?.device_count?.failed_device_count}}</span>
                                    </li>
                                    <li><label>Assets Pass:</label>
                                        <span
                                            class="bg-low">{{selectedRowInfoDetails?.device_count?.passed_device_count}}</span>
                                    </li>
                                    <li><label>Accepted Risk:</label>
                                        <span
                                            class="accepted">{{selectedRowInfoDetails?.device_count?.whitelisted_device_count}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="benchmark-mapping" *ngIf="validateObjectData(selectedRowInfoDetails?.NIST)">
                            <span class="benchmark-text" >NIST Mapping</span>
                            <span class="infoicon" mat-icon-button [matMenuTriggerFor]="menu"
                            aria-label="Example icon button with a menu icon"
                            #clickHoverMenuTrigger="matMenuTrigger">
                            <mat-icon>info</mat-icon></span>
                            <mat-menu id="info-menu" #menu="matMenu" class="benchmark-info mat-menu-scroll">
                                <div *ngFor="let publication of selectedRowInfoDetails?.NIST | keyvalue">
                                    <span class="list-item">{{ publication.key }}</span>
                                    <div class="list-order">
                                        <ol>
                                            <li *ngFor="let control of publication.value | keyvalue" class="list-item-value">
                                              <span class="list-item">{{ control.key }}:</span> {{ control.value }}
                                            </li>
                                          </ol>
                                    </div>

                                </div>
                              </mat-menu>

                        </div>
                        <div class="details-info-list scroll">
                            <ul *ngIf="!multipleRemediation" class="details-info-list-item">
                                <li *ngIf="selectedRowInfoDetails?.benchmark_list?.[0]?.description" class="benchmark-list">
                                  <div class="list-header-section">
                                    <p class="list-item">Description</p>
                                    <button class="expand-btn" mat-button (click)="toggleDescription()"><mat-icon>keyboard_arrow_down</mat-icon></button>
                                  </div>
                                  <p *ngIf="showDescription" class="list-item-description">{{selectedRowInfoDetails?.benchmark_list?.[0]?.description}}</p>
                                </li>

                                <li *ngIf="selectedRowInfoDetails?.benchmark_list?.[0]?.remediation" class="benchmark-list">
                                  <div class="list-header-section">
                                    <p class="list-item">Remediation</p>
                                    <button class="expand-btn" mat-button (click)="toggleRectification()"><mat-icon>keyboard_arrow_down</mat-icon></button>
                                  </div>
                                  <p *ngIf="showRectification" class="list-item-description">{{selectedRowInfoDetails?.benchmark_list?.[0]?.remediation}}</p>
                                </li>

                                <li *ngIf="selectedRowInfoDetails?.benchmark_list?.[0]?.rationale" class="benchmark-list">
                                  <div class="list-header-section">
                                    <p class="list-item">Rationale</p>
                                    <button class="expand-btn" mat-button (click)="toggleRationale()"><mat-icon>keyboard_arrow_down</mat-icon></button>
                                  </div>
                                  <p *ngIf="showRationale" class="list-item-description">{{selectedRowInfoDetails?.benchmark_list?.[0]?.rationale}}</p>
                                </li>

                                <li *ngIf="selectedRowInfoDetails?.benchmark_list?.[0]?.issues" class="benchmark-list">
                                  <div class="list-header-section">
                                    <p class="list-item">Issues</p>
                                    <button class="expand-btn" mat-button (click)="toggleIssues()"><mat-icon>keyboard_arrow_down</mat-icon></button>
                                  </div>
                                  <p *ngIf="showIssues" class="list-item-description">{{selectedRowInfoDetails?.benchmark_list?.[0]?.issues}}</p>
                                </li>
                              </ul>
                              <ul *ngIf="multipleRemediation" class="details-info-list-item">
                                <li *ngIf="getDescription()" class="benchmark-list">
                                  <div class="list-header-section">
                                    <p class="list-item">Description</p>
                                    <button class="expand-btn" mat-button (click)="toggleDescription()"><mat-icon>keyboard_arrow_down</mat-icon></button>
                                  </div>
                                  <p *ngIf="showDescription" class="list-item-description">{{getDescription()}}</p>
                                </li>
                                <ng-container>
                                <li *ngIf="hasNonEmptyRemediation()" class="benchmark-list">
                                  <div class="list-header-section">
                                    <p class="list-item">Remediation</p>
                                    <button class="expand-btn" mat-button (click)="toggleRectification()"><mat-icon>keyboard_arrow_down</mat-icon></button>
                                  </div>
                                  <div  *ngFor="let benchmark of selectedRowInfoDetails?.benchmark_list">
                                  <p *ngIf="showRectification" class="list-item-description"><strong>{{benchmark.name}}-Remediation :</strong> {{benchmark.remediation}}</p>
                                  </div>
                                </li>
                            </ng-container>
                                <li *ngIf="getRationale()" class="benchmark-list">
                                  <div class="list-header-section">
                                    <p class="list-item">Rationale</p>
                                    <button class="expand-btn" mat-button (click)="toggleRationale()"><mat-icon>keyboard_arrow_down</mat-icon></button>
                                  </div>
                                  <p *ngIf="showRationale" class="list-item-description">{{getRationale()}}</p>
                                </li>

                                <li *ngIf="getIssue()" class="benchmark-list">
                                  <div class="list-header-section">
                                    <p class="list-item">Issues</p>
                                    <button class="expand-btn" mat-button (click)="toggleIssues()"><mat-icon>keyboard_arrow_down</mat-icon></button>
                                  </div>
                                  <p *ngIf="showIssues" class="list-item-description">{{getIssue()}}</p>
                                </li>
                              </ul>
                        </div>
                    </div>
                    <p class="no-nist-data" *ngIf="showNist">
                        {{noDataMsg}}
                      </p>
                </div>
            </div>
        </div>
        <div class="scan-item-details reason-section" *ngIf="toggleWindows === 2">
            <h3>Reason to Accepted Risk</h3>
            <div class="close-btn">
                <button mat-button (click)="closeComment()" class="btn icon-btn"><mat-icon>cancel</mat-icon></button>
            </div>
            <div class="details-section">
                <div class="selected-list-header" [ngClass]="addBadgeClass(vulnerabilityType||'')" *ngIf="selectedListRow && selectedListRow.paramter">
                    <p class="selected-list-header-title text_ellipsis" [options]="toolTipOption" appSafariEllipsis [tooltip]="selectedListRow.paramter">{{selectedListRow.paramter}} </p>
                    <div class="selected-list-header-info">
                        <div class="selected-list-filecount">{{vulnerabilityType}}</div>
                    </div>
                </div>
                <div class="details-info" [formGroup]="acceptedriskReasonForm">
                    <mat-form-field appearance="outline">
                        <mat-label>Reason</mat-label>
                        <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize"
                              formControlName="whitelistReason" spellcheck="false" maxlength="120" (input)="updateCharacterCount($event.target)"></textarea>
                        <mat-error *ngFor="let validation of validation_messages.whitelistReason">
                            <mat-error class="error-message"
                                *ngIf="acceptedriskReasonForm.get('whitelistReason')?.hasError(validation.type) &&
                                (acceptedriskReasonForm.get('whitelistReason')?.dirty || acceptedriskReasonForm.get('whitelistReason')?.touched)">
                                {{validation.message}}
                            </mat-error>
                        </mat-error>
                    </mat-form-field>

                    <p class="word-count">{{ characterCount }}</p>

                    <div class="details-info-bottom">
                        <p> <mat-checkbox formControlName="rememberMe" [required]="true"
                                (change)="acceptedriskReasonForm.controls['rememberMe'].setValue($event.checked)">I have
                                the permissions to accepted risk</mat-checkbox></p>
                        <div>
                                <button _ngcontent-axo-c127="" #myButton class="full-width btn" [ngClass]="{'loader-btn disabled': isSpinner, 'disableMe':!acceptedriskReasonForm.valid}" color="primary" [disabled]="!acceptedriskReasonForm.valid" (click)="onApplyAcceptedRisk()">
                                    <span>Accepted Risk</span>
                                    <span class="btn-loader spin"><img src="../../assets/images/btn-loader.png" /></span>
                                </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="filter-section" *ngIf="toggleWindows === 3">
            <button mat-button *ngIf="isFirstLevelFetchBtn" (click)="closeFilter()"
                class="btn icon-btn filter-close"><mat-icon>cancel</mat-icon></button>
            <app-filter *ngIf="filterColumnData.get('Region')?.length" type="checkbox"
                [filterColumnData]="filterColumnData" [title]="'Filters'" [showCloseBtn]="false"
                (applyBasedOnSelectedData)="selectedFilters($event)"
                [clearButtonRequired]="true"
                [resetButtonRequired]="true"
                [saveButtonRequired]="true">
            </app-filter>
            <div class="item" *ngIf="!isFirstLevelFetchBtn">
                <ngx-skeleton-loader class="rhs-content-loader head" count="4"></ngx-skeleton-loader>
                <ngx-skeleton-loader class="rhs-content-loader global-search-loader" count="1"></ngx-skeleton-loader>
                <div class="global-loader-section">
                   <div>
                      <ngx-skeleton-loader class="rhs-content-loader checkbox-box-loader" *ngFor="let row of [].constructor(12)" count="2"></ngx-skeleton-loader>
                   </div>
                </div>
             </div>
        </div>
    </div>
</div>
