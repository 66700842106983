import { Component } from '@angular/core';
import { VpnService } from 'src/app/modules/shared/services/vpn.service';
import { ICreateUpdateDeleteVpnPayLoad, ICreateUpdateDeleteVpnResponse, ILoadVpnsResponse, Ivpn } from './vpn..models';
import { CONFIG } from 'src/app/modules/shared/constants/config';

import { IDialog } from 'src/app/modules/shared/model/Dialog.model';
import { AddVpnComponent } from './add-vpn/add-vpn.component';
import { COMPOENT_NAME, CONSTANT } from 'src/app/modules/shared/constants/constant-data';
import { DialogService } from 'src/app/modules/shared/services/dialog.service';
import { VpnPopupsComponent } from './vpn-popups/vpn-popups.component';
import { Title } from 'chart.js';
import { PasswordConfirmationPopupComponent } from 'src/app/modules/shared/components/password-confirmation-popup/password-confirmation-popup.component';
import { ToastrService } from 'ngx-toastr';
import { SpinnerService } from 'src/app/modules/shared/services/spinner.service';
import { HeaderService } from 'src/app/modules/shared/services/header.service';

@Component({
  selector: 'app-vpn',
  templateUrl: './vpn.component.html',
  styleUrls: ['./vpn.component.scss']
})
export class VpnComponent {
  hide = true;
  tunnelName : any;
  public createAndUpdateDeleteVpnLoader: boolean = false;
  public isVpnLoader = false;
  public isdetails=false
  public vpnList: Ivpn[] | undefined = [];
  public noData: boolean = false;
  constructor(private vpnService: VpnService,
    private dialogService: DialogService,
    private toastr: ToastrService,
    private spinnerService:SpinnerService,
    private headerService:HeaderService
  ) {
    this.headerService.setHeading('VPN Management', { type: 'vpn' });
    this.loadVpns();
  }

  public createAndUpdateDeleteVpn(payload: ICreateUpdateDeleteVpnPayLoad, isNew = true) {
    this.createAndUpdateDeleteVpnLoader = false;
    this.vpnService.createUpdateVpn(payload, CONFIG.METHOD.POST, this.createAndUpdateDeleteVpnCallback);
  }
  public createAndUpdateDeleteVpnCallback = (response: ICreateUpdateDeleteVpnResponse) => {
    this.createAndUpdateDeleteVpnLoader = true;
    if (response.status === 200) {
      

    }
  }
  public deleteVpnCallback = (response: ICreateUpdateDeleteVpnResponse) => {
    //this.createAndUpdateDeleteVpnLoader = true;
    this.spinnerService.hide();
    if (response.status === 200) {
      this.loadVpns()
    }
  }
  public pauseVpnCallback = (response: ICreateUpdateDeleteVpnResponse) => {
    //this.createAndUpdateDeleteVpnLoader = true;
    if (response.status === 200) {
      this.toastr.success('VPN Status has been updated successfully','',{timeOut: 5000, progressBar: true,positionClass: "toast-top-right"});
      this.loadVpns()
    }
  }
  public loadVpns() {
    this.isVpnLoader = false;
    this.isdetails=false;
    this.noData = true;
    this.vpnService.createUpdateVpn(null, null, this.loadVpnsCallback);
  }
  public loadVpnsCallback = (response: ILoadVpnsResponse) => {
    this.createAndUpdateDeleteVpnLoader = true;
    this.isVpnLoader = true;
    this.isdetails=true;
    if (response.status === 200) {
      this.vpnList = response.data;
      this.updateVpnStatus()
      //this.vpnList?.concat(this.vpnList[0])
      if (this.vpnList?.length === 0) {
        this.noData = false;
      } else {
        this.checkTabChange(this.vpnList?this.vpnList[0]:null)

      }
    }
  }
  // Declare the statusMap as a Map
statusMap: Map<any, any> = new Map([
  ['Active', 0],
  ['Failed', 1],
  ['Paused', 2],
  ['Inactive', 3] ,
  ['Inprogress', 4]
]);
getScanStatusClass(value: any): string {
  const arr = ['low', 'critical','partial', 'cancle', 'in-pgr'];
  return arr[value];
}

updateVpnStatus() {
  this.vpnList?.forEach(vpn => {
    const status = vpn.tunnel_status ? vpn.tunnel_status : 'Unknown'; // Provide a default status if undefined
    vpn.status = this.statusMap.get(status) ?? -1; // Use get() method for Map
  });
}

  
  
  vpndetails:any=null;
  detailsLoader:boolean=false;
  public checkTabChange(details:any){
    console.log(details)
    this.tunnelName = details.tunnel_name
  //this.isVpnLoader=false;
  this.isdetails=false;
  setTimeout(() => {
    // After 1 second, set detailsLoader back to false
    this.isdetails = true;
    this.vpndetails=details;
    console.log(this.vpndetails);

  }, 3000);
  
  

  }
  isCopied = false;
  public copyToClipboard(value: string): void {
    if (!value) {
      return;
    }
  
    // Create a temporary textarea element
    const textarea = document.createElement('textarea');
    textarea.value = value;
    document.body.appendChild(textarea);
  
    // Select the text
    textarea.select();
    textarea.setSelectionRange(0, 99999); // For mobile devices
  
    // Copy the text to clipboard
    document.execCommand('copy');
  
    // Remove the temporary element
    document.body.removeChild(textarea);
    this.isCopied = true;
  
    // Optionally, you can show a success message or a toast notification
    console.log('Copied to clipboard:', value);
  }
  
  get componentName() {
    return COMPOENT_NAME;
  }
  addVpn(): void {
    const payload: IDialog = {
      component: AddVpnComponent,
      width: '350px',
      class: 'add-vpn',
      data: {
        title:'Add VPN',
        action:"add",
        createVpn: (result: any)=>{
          if(result){
            this.loadVpns()
          }
          console.log(result);
        }
      },
      
    }
    this.dialogService.openDialog(payload);
  }
  testVpn(): void {
    const payload: IDialog = {
      component:VpnPopupsComponent,
      width: '360px',
      data: {
        data:this.vpndetails
        // content:CONTENT_MESSAGE(option, currentUser),
        // title: option,
        // setCallbackFunction: (result: any) => {
        //   this.onOptionCall(result,option,currentUser)
        // },
        // menuData:GET_MENU_ITEM_DATA(option,currentUser)
      },
    }
    this.dialogService.openDialog(payload);
  }

  editVpn(vpndetails:any): void {
    const payload: IDialog = {
      component: AddVpnComponent,
      width: '350px',
      class: 'add-vpn',
      data: {
        title:'Edit VPN',
        action:'edit',
        vpndetails:vpndetails,
        createVpn: (result: any)=>{
          console.log(result);
        }
      },
      
    }
    this.dialogService.openDialog(payload);


  }

  deleteVpn(vpndetails:any): void {
    this.checkAuthorization()
  }

  pauseVpnconnection(pause:boolean=false){

   let payload:any={};
   payload.tunnel_id=this.vpndetails.tunnel_id;
   payload.pause=pause;
   this.vpnService.pauseVpn(payload, CONFIG.METHOD.POST, this.pauseVpnCallback);

  }

  pauseVpn(vpndetails:any,pause:boolean=false): void {
    let tempTitle="";
    let tempContent="";
    let btnClass='btn-Pumpkin';
    let templabel="Pause";
    if(pause){
      tempTitle=`Are you sure you want to Pause "${this.vpndetails.tunnel_name}"?`
      tempContent='You won\'t be able to connect using this tunnel. You can resume it later.'
    } else {
       tempTitle=`Are you sure you want to Resume "${this.vpndetails.tunnel_name}"?`
       tempContent='';
       btnClass='btn-primary';
       templabel="Resume"
    }
    this.dialogService.openDialog({
      title: tempTitle,
      content: tempContent,
      width: '369px',
      class: 'custom-schedule-popup',
      buttons: [
        {
          label: 'Cancel',
          class: 'btn-lightblack-outline',
          callBack: () => {
            // Add any cancel logic here if needed
          }
        },
        {
          label: templabel,
          class: btnClass,
          callBack: (data: any = '', radioFlag: boolean = false) => {
            this.pauseVpnconnection(pause);
            // Call the appropriate method if necessary, e.g., this.openScheduleDialog(null, data, radioFlag)
          }
        }
      ],
      data: {
        
        disableClose: true
      }
    });
    


  }

  checkAuthorization() {
    let passwordData = {
      title: 'Are you sure want to delete "'+ this.vpndetails.tunnel_name+ '"?',
      text: 'If you delete, you will not be able to find the VPN again.',
      buttonLabel: 'Delete',
      password: CONSTANT.PASSWORD,
    }
    const payload: IDialog = {
      component: PasswordConfirmationPopupComponent,
      width: '350px',
      data: {
        userData: passwordData,
        setCallbackFunction: (isApiCall:boolean=false,data:any=null) => {
          if(isApiCall) {
          //console.log("asdfadsf")
          let payload:any={};
          payload.tunnel_id=this.vpndetails.tunnel_id;
          this.spinnerService.show();
          this.vpnService.delete(this.vpndetails.tunnel_id, CONFIG.METHOD.DELETE, this.deleteVpnCallback);
          //console.log("sadfadsf")

          }
          
          
        }
      },
    }
    this.dialogService.openDialog(payload);
  }

  
  
  // Method to get the value for a given status
  getStatusValue(status: string): number | undefined {
    return this.statusMap.get(status);
  }

}
