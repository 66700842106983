import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { ToolTipConfig } from 'src/app/modules/shared/constants/constant-data';


@Component({
    selector: 'app-phone',
    templateUrl: './phone.component.html',
    styleUrls: ['./phone.component.scss']
  })
  export class PhoneComponent {
    public params!: ICellRendererParams;
    // public toolTipOption = ToolTipConfig;
  
    agInit(params: ICellRendererParams): void {
      this.params = params;
    }
  
  }