import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CVE_PAGE } from '../../shared/constants/constant-data';

@Component({
  selector: 'app-cisa-kev',
  templateUrl: './cisa-kev.component.html',
  styleUrls: ['./cisa-kev.component.scss']
})
export class CisaKevComponent implements ICellRendererAngularComp{
  public params!: ICellRendererParams;
  public get cvePageLabels() {
    return CVE_PAGE
  }
  constructor() {
    
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;
  }
  
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
  
}
