import { Component, Input, SimpleChanges, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  ApexNonAxisChartSeries, ApexPlotOptions, ApexChart,
  ApexLegend,
  ChartComponent,
  ApexDataLabels,
  ApexTooltip,
  ApexFill,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexYAxis
} from "ng-apexcharts";
import { AffectedAssets } from '../../admin/components/device-management/device.model';
import { HeaderService } from '../../shared/services/header.service';
import { CveService } from '../../shared/services/cve.service';
import { HttpResponse } from '@angular/common/http';
import { ResponseService } from '../../shared/services/response.service';
import { CONSTANT, ToolTipConfig } from '../../shared/constants/constant-data';
import { IFilterFlags } from '../../shared/components/filter/filter.model';
import { Utils } from '../../shared/utils';
import { ISortingFilterObj, SortingFilterService } from '../../shared/services/sorting-filter.service';
import * as _ from 'lodash';
import { AuthService } from '../../shared/services/auth.service';
export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: any[];
  colors: any[];
  legend: ApexLegend;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  tooltip: ApexTooltip;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  title: ApexTitleSubtitle;
};
@Component({
  selector: 'app-cve-enumeration',
  templateUrl: './cve-enumeration.component.html',
  styleUrls: ['./cve-enumeration.component.scss']
})
export class CveEnumerationComponent {
  @Input() public cveDetails: any;
  public selectedCveRow: any;
  public chartOptions!: Partial<ChartOptions> | any;
  public selctedIndex: number = 0;
  public showMore: boolean = false;
  public searchAssets: string = '';
  public openedMenuId: string | null = null;
  public toolTipOption = ToolTipConfig;
  public toolTipConfig: any = _.cloneDeep(ToolTipConfig);
  @ViewChild(CveEnumerationComponent) cveEnumerationComponent!: CveEnumerationComponent;
  displayedColumns: string[] = ['asset_name', 'asset_type', 'vendor'];
  public dataSource!: MatTableDataSource<AffectedAssets>;
  public page: number = 1;
  public totalPage: number = 1;
  public numberOfAssetsListResults: number = Math.ceil((window.innerHeight - 125) / 63);
  public noData: string = CONSTANT.NO_DATA_MSG;
  public dropdownData: any[] = [];
  public selectedMatrix : any;
  public selectedMatrixScore: any;
  isSafari!: boolean;
  public selectedAuthority: string = '';
  public selectedAuthorityValue: string = 'nvd';
  public selectedAuthorityKeys: string[] = ['nvd', 'cisco'];
  constructor(
    private headerService: HeaderService,
    private cveService: CveService,
    public authservice: AuthService) {
    this.selectedCveRow = this.cveService.selectedRow;
    this.toolTipConfig.tooltipClass = 'cve-tooltip-width';
    if(this.cveDetails){
      this.loadEnumdetails();
    }
    this.headerService.getDropdown().subscribe((dropdownData: any) => {
      this.dropdownData = dropdownData.dropdown;
    })
  }
  ngOnDestroy(): void {
    
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['cveDetails'] &&
      changes['cveDetails']?.currentValue != changes['cveDetails']?.previousValue) {
        this.cveDetails = changes['cveDetails'].currentValue;
        if(this.cveDetails) {
          this.loadEnumdetails();
        }
    }
  }
  ngOnInit(): void { 
    this.isSafari=this.detectSafari();
  }
  loadEnumdetails() {
    //this.headerService.setHeading(this.selectedCve || '', { dropdown: [], type: 'cveDashDetails' });
    this.loadCVEDetails();
  }
  public hideNonAvailableList(auth: string) {  
    let index: any =  this.selectedMatrix?.authority?.indexOf(auth);
    let  key: string = '';
    if(index!==-1){
      key = this.selectedAuthorityKeys[index];
     }
    return key && Object.keys(this.selectedMatrix.value[key]).length ? true : false;
  }
  public isAuthDropdrowShow(){
    return Object.keys(this.selectedMatrix.value[this.selectedAuthorityKeys[0]])?.length && 
    Object.keys(this.selectedMatrix.value[this.selectedAuthorityKeys[1]])?.length
  }
  
  public openAccordian(index: number) {
    this.selctedIndex = index;
  }
  public closeAccordian(){
    this.selctedIndex = 0;
  }
  public loadCVEDetails() {
      const matrixDetails: any = [];
      let selectedRowIndexObject: any = null;
      this.cveDetails?.severity_metrics?.forEach((cvssObject: any)=>{
        if(cvssObject.value.cisco.length || cvssObject.value.nvd.length) {
          if(this.selectedCveRow) {
            const cveName = cvssObject?.name?.split('v');
            const selectedRowCVSSName = this.selectedCveRow.version.split('v');
            if(selectedRowCVSSName[1].trim() === cveName[1].trim() && !selectedRowIndexObject){
              selectedRowIndexObject = cvssObject;
            }
          }
          matrixDetails.push(cvssObject);
        }
      });
      if(selectedRowIndexObject && Object.keys(selectedRowIndexObject).length) {
        if(matrixDetails && matrixDetails.length) {
          const findIndex = matrixDetails.findIndex((obj: any)=> obj.name === selectedRowIndexObject.name);
          if(findIndex > -1) {
            matrixDetails.splice(findIndex, 1);
          }
        }
        matrixDetails.unshift(selectedRowIndexObject)
      }
      this.cveDetails.severity_metrics = matrixDetails;
      this.selectedMatrix = this.cveDetails?.severity_metrics[0];
      this.updateVulnerabilitydetails();
      this.cvsScoreGraph();
  }
  splitedCVSS(name: string | any){
    if(name && name.indexOf(':')!==-1 && name.split(':').length > 1) {
      name  =  name.split(':');
    }
    return name;
  }
  
  public selectAuthority(selectedAuthority: string){
    this.selectedAuthority = selectedAuthority
    const index =  this.selectedMatrix.authority.indexOf(selectedAuthority);
    if(index!==-1){
     this.selectedAuthorityValue = this.selectedAuthorityKeys[index];
     this.selectedMatrixScore = this.selectedMatrix.score[this.selectedAuthorityValue];
    }
    this.cvsScoreGraph();
   }
   public selectMatrix(selectedMetrix: any){
     this.selectedMatrix = selectedMetrix;
     this.updateVulnerabilitydetails();
     this.cvsScoreGraph();
   }
  public updateVulnerabilitydetails(authority: string = '') {
    let isAvilableData = false;
      if(this.selectedMatrix.authority.length) {
        this.selectedMatrix.authority.forEach((key: string, index: number)=>{
            if(!isAvilableData && Object.keys(this.selectedMatrix.value[this.selectedAuthorityKeys[index]])) {
              isAvilableData = true;
              this.selectedAuthority = key;
              this.selectedAuthorityValue = this.selectedAuthorityKeys[index];
            }
          })
      }
      this.selectedMatrixScore = this.selectedMatrix.score[this.selectedAuthorityValue];
  }
  public addClassSeverity(score: number): string {
    let color = "#670004";
    if (score >= 1 && score <= 10) {
      let temp=Math.floor(score)+1
      if(temp > 9) {
        temp = temp - 1;
      }
      color = this.cveService.bgColorSeverity[temp];
    }
    return color; // Add a default class for scores outside the range
  }
  detectSafari(): boolean {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1;
  }
  public cvsScoreGraph() {
    const bgColor: any = this.cveService?.bgColor;
    let score = 0 ;
    let severity = '';
    if(this.selectedMatrixScore.length){
      this.selectedMatrixScore.forEach((obj: any)=>{
        if(obj.name === 'cvss') {
          score = obj.value;
        }
        if(obj.name === 'severity') {
          severity = obj.value;
        }
      })
    }
    //const graphColor = severity ? bgColor[severity] : '#670004';
    const graphColor = score ?this.addClassSeverity(score) : '#670004';
    const isSafari = this.detectSafari()
    this.chartOptions = {
      series: [score],
      colors: [graphColor],
      chart: {
        width: 190,
        type: "donut",
        offsetX: -25,
      },
      states: {
        hover: {
          filter: {
              type: 'none',
              value: 0,
          }
       }
      },
      plotOptions: {
        pie: {
          donut: {
            size: 80,
            labels: {
              show: true,
              fontSize: '24',
              name: {
                show: true,
                color: graphColor,
                offsetY: 0,
                formatter:(w: string)=>{
                  return [score.toFixed(1)];
                } 
              },
              value: {
                show: true,
                fontSize: '14',
                fontWeight: 600,
                offsetY: 0,
                color: graphColor,
                formatter: (w: any) => {
                  return [severity]
                }
              },
              total: {
                show: true,
                showAlways: true,
                fontSize: '24',
                color: graphColor,
                fontWeight: 600,
                formatter: (w: any) => {
                  return [severity]
                }
              }
            }
          }
        }
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false
      },
      tooltip: {
        enabled: false
      },
      stroke: {
        width: 0
      }
    };
    if(isSafari){
     this.chartOptions.chart.events = {
          mounted: function (chartContext: { el: { querySelector: (arg0: string) => any; }; }, config: any) {
            const innerGraphicalGroup = chartContext.el.querySelector('.apexcharts-inner.apexcharts-graphical');
            if (innerGraphicalGroup) {
              innerGraphicalGroup.setAttribute('transform', 'translate(0.83333333333333, 0)');
            }
          },
          updated: function (chartContext: { el: { querySelector: (arg0: string) => any; }; }, config: any) {
            const innerGraphicalGroup = chartContext.el.querySelector('.apexcharts-inner.apexcharts-graphical');
            if (innerGraphicalGroup) {
              innerGraphicalGroup.setAttribute('transform', 'translate(0.83333333333333, 0)');
            }
          }
        }
    }
  }

  getCvss_data(element:any){
    return element.cvss_data.slice().reverse();
  }
  
  public urlify(text: any) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url: string) {
      return `<a href="${url}" target="_blank">${url}</a>`;
    })
  }
  public showLimitedContent(content: string) {
    let text = content;
    if (content && content.length > 200) {
      text = content.substring(0, 200) + '....';
    }
    return text;
  }
  public lowerCaseClass(val: string) {
    if(val) {
      val = val.toLocaleLowerCase();
    }
    return val;
  }
}
