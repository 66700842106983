<div class="device-sec-icon ag-center"
    *ngIf="params.value != null && !getStatus(params.data)">
    <div class="donut-vul" [appCustomTooltip]="customTooltip" [style.background]="graphProps(params.data, params.value)"></div>
    <p class="Vul" >{{params.value | numberFormat}}</p>
</div>
<ng-template #customTooltip>
    <div class="ag-vul-tooltip"> 
        <div class="ag-dialog">
            <div *ngIf="params && params.data && params.data.vulnerability_levels[0].critical">Critical: {{params.data.vulnerability_levels[0].critical}}</div>
            <div *ngIf="params && params.data && params.data.vulnerability_levels[0].high">High: {{params.data.vulnerability_levels[0].high}}</div>
            <div *ngIf="params && params.data && params.data.vulnerability_levels[0].medium">Medium: {{params.data.vulnerability_levels[0].medium}}</div>
            <div *ngIf="params && params.data && params.data.vulnerability_levels[0].low">Low: {{params.data.vulnerability_levels[0].low}}</div>
            <!-- <div *ngIf="params && params.location && params.location==='headPer'">{{params.value}}</div> -->
        </div>
    </div>
</ng-template>