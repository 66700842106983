import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-dashboard',
    templateUrl: './cve-count.component.html',
    styleUrls: ['./cve-count.component.scss']
  })

  export class CveCountComponent {
    public params!: ICellRendererParams;

    agInit(params: ICellRendererParams): void {
        this.params = params;
      }

      getStatus(item: any): boolean {
        return item?.status < 3;
      }
  
    constructor() {    
      }
  }