import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { IToastrOptions } from '../model/IMessage.model';
import { USER_PERMISSION } from '../constants/constant-data';
import { MessageServiceService } from './message-service.service';
import { GlobalFilterService } from './global-filter.service';
@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(
    private service: AuthService,
    private route: Router,
    private messageServive: MessageServiceService,
    private globalFilterService: GlobalFilterService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this.service.isLoggedIn()) {
      let isRoleHasAccess = true;
      //console.log( route.routeConfig?.path)
      const fullPath = this.getFullPath(route);
      //console.log('Navigating to:', fullPath);
      const msg: IToastrOptions = {};
      msg.message = USER_PERMISSION(route.routeConfig?.title);
      const userInfo = JSON.parse(this.service.getCookie('userInfo') || '{}');
      //console.log(userInfo)
      if (userInfo) {
        const expiryDate = new Date(userInfo.expiry_date);
        const currentDate = new Date();
        // Check if expiry_date is less than or equal to current time
        const showUpgrade = expiryDate <= currentDate;
        if (showUpgrade && route.routeConfig?.path != '') {
          //isRoleHasAccess=false;
          //this.service.logOut();
          this.route.navigate(['dashboard']);
          return false;
        }
      } else {
        this.service.logOut();
        return false;
      }
      this.globalFilterService.showFilter(route?.data['isFilter']);
      if (Object.keys(this.service.getRoles()).length) {
        if (route.data && route.data['role']) {
          isRoleHasAccess = !!this.service.isRoleAccess(route.data['role']);
        }
      } else {
        isRoleHasAccess = false;
      }
      if (!isRoleHasAccess) {
        this.messageServive.showError(msg);
        this.route.navigate(['dashboard']);
      } else {
      }

      return isRoleHasAccess;
    } else {
      this.service.logOut();

      return false;
    }
  }

  // Helper function to get the full path
  private getFullPath(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .map((v) => v.url.map((segment) => segment.toString()).join(''))
      .join('/');
  }
}
