import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';
import { CONSTANT } from 'src/app/modules/shared/constants/constant-data';

@Component({
  selector: 'app-user-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class UserStatusComponent {
  public params!: ICellRendererParams;
  public invite = CONSTANT.INVITE;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

}
