import { ChangeDetectorRef, Component } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { NotificationService } from '../../services/notification.service';
import { AuthService } from '../../services/auth.service';
import { MessageServiceService } from '../../services/message-service.service';
import { environment } from 'src/environments/environment';
import { IToastrOptions } from '../../model/IMessage.model';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-events-manager',
  templateUrl: './events-manager.component.html',
  styleUrls: ['./events-manager.component.scss']
})
export class EventsManagerComponent {
  private socket: Socket | null = null;
  public notificationObj:any = {}
  public messageObj:any = {}
  public notificationQueue: Array<{ resp: IToastrOptions, imageUrl: string }> = [];
  public messageQueue: Array<{ resp: IToastrOptions, imageUrl: string }> = [];
  public isNotificationVisible: boolean = false;
  public isMessageVisible: boolean = false;
  public deviceManagementCodes: string[] = [ 'decommission_device', 'recommission_device', 'modify_device_location', 'delete_device'];
  public regionManagementCodes: string[] = ['add_region_data', 'delete_region_data', 'modify_region_level', 'modify_region_name'];
  public acceptedRiskCodes: string[] = ['accepted_risk'];
  public newFileUpload: string[] = ['api_connection_new_device','file_upload_new_device','ssh_connection_new_device','rescan'];
  public roleManagement: string[] = ['role_delete','role_modification']
  public userManagement: string[] = ['activate_user','deactivate_user','invite_user','modify_user_role']; 
  public myScans: string[] = ['master_file_upload']; 
  public vpn: string[] = [];
  constructor(public notificationService: NotificationService,
    private sharedService: SharedService,
    public authService: AuthService,
  public messageService: MessageServiceService,
  private cdr: ChangeDetectorRef ) {
    this.notificationService.notificationState.subscribe((flag: boolean) => {
     if(flag){
      this.notificationQueue = [];
      this.messageQueue = [];
      this.isMessageVisible = false;
      this.isNotificationVisible = false;
     }
    });
  }

  ngOnInit(): void {
     this.authService.userLoggedIn.subscribe(() => {
       this.socketConnection();
     });

     if(this.authService.isLoggedIn()){
      this.socketConnection();
     }

     this.authService.userLoggedOut.subscribe(() => {
      this.closeSocketConnection()
      this.notificationQueue = [];
      this.messageQueue = [];
      this.isMessageVisible = false;
      this.isNotificationVisible = false;
     });

     this.messageService.messagePass.subscribe((resp: any) => {
      let svgImage = ""
       if(resp.title.toLowerCase() === 'success' || resp.title.toLowerCase() === 'congratulations!'){
        svgImage = "pass"
       } else {
        svgImage = "fail"
       }
       const snakMsg: IToastrOptions = {
        message: resp.message,
         title: resp.title
      };
       this.queueMessage(snakMsg, svgImage);
     })
     
  }
  public closeSocketConnection() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  public socketConnection() {
        
    // Check if a socket connection already exists
    if (this.socket) {
      this.disconnectSocket();
    }

    if (this.authService.isLoggedIn()) {
      const token = this.authService.getToken();
      if (token) {
        this.socket = io(environment.WebSocket + `?token=${token}&device_code=d48iif4qdPzyoS8yZ0DqPQmBNIjD2Cc3wXWSaqxO`);
        
        this.socket.on('connect', ()=>{
          console.log('Connected to the WebSocket server');
        });

        this.socket.on('message', (message: any)=>{
          console.log(message)
          if(message){
            message.type = message?.event_type_name.toLowerCase() || '';
          }
          this.showNotification(message);
        });

        this.socket.on('custom_message', (message: any)=>{
          console.log(message);
          //this.sharedService.updateApiData(message);
          this.sharedService.updateDataOnSocketNotify.emit(message);
        });

        this.socket.on('scan_status_event', (response: any) => {
          this.authService.scanDetailsSubject.next(response);
        });
        this.socket.on('socket-data-sync-topic-dev',(response:any)=>{
         console.log(response);
        })
        this.socket.on('socket-data-sync-dev-group',(response:any)=>{
          console.log(response);
         })
        this.socket.on('final_myscans_status', (response: any) => {
          this.authService.myScansSubject.next(response);
        });
        this.socket.on('expire_token', (response: any) => {
        if(response.expire_token === true){
          this.authService.logoutUser().subscribe({
            next: (result) => {
              if (result.status === 200) {
                this.authService.logOut(true,true,false,true);
                this.authService.userLoggedOutSubject.next();
              }
            },
            error: (error) => {
              console.error('Error during logout:', error);
              this.authService.logOut(true,true,false,true);
              this.authService.userLoggedOutSubject.next()
            }
          });
        }
        })

      }
    }
  }

  public disconnectSocket() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null; 
    }
  }

  ngOnDestroy() {
    this.disconnectSocket();
  }

  public showNotification(notificationData: any): void {
    const categories = [
      { codes: this.deviceManagementCodes, title: 'Asset Management' },
      { codes: this.regionManagementCodes, title: 'Region Management' },
      { codes: this.acceptedRiskCodes, title: 'Accepted Risk' },
      { codes: this.newFileUpload, title: 'New File Upload' },
      { codes: this.roleManagement, title: 'Role Management' },
      { codes: this.userManagement, title: 'User Management' },
      { codes: this.myScans, title: 'My Scans' }
    ];
  
    const titleMap: Map<string, string> = new Map();
    categories.forEach(category => {
      category.codes.forEach(code => {
        titleMap.set(code, category.title);
      });
    });
    const snakMsg: IToastrOptions = {
      message: notificationData.message,
       title: titleMap.get(notificationData.notification_category_code) || 'Notification',
       event_type_name: notificationData?.event_type_name,
       type: notificationData?.type
    };
  
    const notificationCategoryCode: string = notificationData.notification_category_code;

    const notificationImageMap: Map<string, string> = new Map([
      ['deviceManagementCodes', "device"],
      ['regionManagementCodes', "region"],
      ['acceptedRiskCodes', "accepted"],
      ['newFileUpload', "newScan"],
      ['roleManagement', "role"],
      ['userManagement', "user"],
      ['myScans', "myScan"],
      ['vpn', "vpn"]
    ]);
  
    let svgImage: string = "../../assets/images/Device-Management.png"; 
  
    const codeArrays = {
      deviceManagementCodes: ['decommission_device', 'recommission_device', 'modify_device_location', 'delete_device'],
      regionManagementCodes: ['add_region_data', 'delete_region_data', 'modify_region_level', 'modify_region_name'],
      acceptedRiskCodes: ['accepted_risk'],
      newFileUpload: ['api_connection_new_device', 'file_upload_new_device', 'ssh_connection_new_device', 'rescan'],
      roleManagement: ['role_delete', 'role_modification'],
      userManagement: ['activate_user', 'deactivate_user', 'invite_user', 'modify_user_role'],
      myScans: ['master_file_upload'],
      vpn: [''],
    };
  
    for (const [codesKey, imagePath] of notificationImageMap.entries()) {
      const codeArray = codeArrays[codesKey as keyof typeof codeArrays];
      if (codeArray.includes(notificationCategoryCode)) {
        svgImage = imagePath;
        break; 
      }
    }
  
    this.queueNotification(snakMsg, svgImage);
    if (notificationData.category_type === this.notificationService.alertKey && !notificationData.read_status) {
      this.notificationService.alert_count += 1;
    }
  
    if (notificationData.category_type === this.notificationService.notificationKey && !notificationData.read_status) {
      this.notificationService.notification_count += 1;
    }
  }

  public queueNotification(resp: IToastrOptions, imageUrl: string): void {
    this.notificationQueue.push({ resp, imageUrl });
    if (!this.isNotificationVisible) {
      this.showNextNotification();
    }
  }

  public queueMessage(resp: IToastrOptions, imageUrl: string): void {
    this.messageQueue.push({ resp, imageUrl });
    if (!this.isMessageVisible) {
      this.showNextMessage();
    }
  }

  public showNextNotification(): void {
    this.isNotificationVisible = false;
    this.cdr.detectChanges();
    if (this.notificationQueue.length === 0) {
      return;
    }
    if(this.notificationQueue.length > 0){
      const nextNotification = this.notificationQueue.shift();
      if(nextNotification){
        this.notificationObj.resp = nextNotification.resp;
        this.notificationObj.imageUrl = nextNotification.imageUrl;
        this.isNotificationVisible = true;
        this.cdr.detectChanges();
      }
      
    } 
  }

  public showNextMessage(): void {
    this.isMessageVisible = false;
    this.cdr.detectChanges();
    if (this.messageQueue.length === 0) {
      return;
    }
    if(this.messageQueue.length > 0){
      const nextMessage = this.messageQueue.shift();
      if(nextMessage){
        this.messageObj.resp = nextMessage.resp;
        this.messageObj.imageUrl = nextMessage.imageUrl;
        this.isMessageVisible = true;
        this.cdr.detectChanges();
      }
      
    } 
  }
  
  public onToastDismissed(type: 'notification' | 'message'): void {
    if (type === 'notification') {
      this.isNotificationVisible = false;
      this.showNextNotification();
    } else if (type === 'message') {
      this.isMessageVisible = false;
      this.showNextMessage();
    }
  }
  

}
