<mat-sidenav-container class="main-content">
  <mat-sidenav-content class="si-content">
    <div class="out-layout scroll"> 
      <!-- <div class="si-header">
      <div class="scan-details-header-section">
        <div class="scan-details-header-left ">
          <div class="search-bar">
            <mat-form-field appearance="fill">
              <mat-label>search..</mat-label>
              <input matInput placeholder="" autocomplete="off" (keyup)="filterData($event)" type="text">
              <mat-icon class='search-icon' matPrefix>search</mat-icon>
            </mat-form-field>
          </div>
        </div>
        <div class="scan-details-header-right" *ngIf="securityInfoDataDetails">
          <label class="vulnerabilities-lable">Total Vulnerabilities</label>
          <button class="btn btn-secondary vulnerabilities-btn">Critical:
            {{securityInfoDataDetails.critical_count}}</button>
          <button class="btn btn-secondary vulnerabilities-btn">High: {{securityInfoDataDetails.high_count}}</button>
          <button class="btn btn-secondary vulnerabilities-btn">Medium:
            {{securityInfoDataDetails.medium_count}}</button>
          <button class="btn btn-secondary vulnerabilities-btn">Low: {{securityInfoDataDetails.low_count}}</button>
        </div>
      </div>
    </div> -->
      <div class="main-si-content">
        <div class="left-side-si white-box">
          <div class="scan-details-header-section">
            <div class="scan-details-header-left ">
              <div class="search-bar">
                <mat-form-field appearance="fill" [ngClass]="{'disabled-input': perameterId}">
                  <mat-label>Search Parameters</mat-label>
                  <input matInput placeholder=""
                  [ngModel]="searchText" #searchField autocomplete="off" [disabled]="!isWhiteListApiCall"
                  autocomplete="off"  type="text" (ngModelChange)="onSearchTextChange($event)" >
                  <mat-icon class='search-icon' matPrefix>search</mat-icon>
                </mat-form-field>
                 <!-- <div *ngIf="securityInfoData && securityInfoData.length <= 0">
                  <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                </div> -->
              </div>
            </div>
            <div class="scan-details-header-right">
              <span class="vulnerab_label label_dot critical">Critical:
                {{securityInfoDataDetails.critical_count}}</span>
              <span class="vulnerab_label label_dot high">High: {{securityInfoDataDetails.high_count}}</span>
              <span class="vulnerab_label label_dot medium">Medium: {{securityInfoDataDetails.medium_count}}</span>
              <span class="vulnerab_label label_dot low">Low: {{securityInfoDataDetails.low_count}}</span>
            </div>
          </div>
          <app-list *ngIf="filterList?.filters" [data]="securityInfoData" [totalPage]="totalPage" [page]="page"
            [isScrollable]="true" [isRowClickable]="true" [filter]='filterValue' [selectedRow]="currentSelectedRow"
            [isApiCallDone]="isWhiteListApiCall" (updateListFilter)="applySortingAndFilter($event)"
            (updateListOnScroll)="updateListOnScroll($event)" (currentSelectedRow)="selectedSecurityDetails($event)"
            [filterList]="filterList" [numberOfResults]="numberOfResults" [tableSearch]="searchText" [tableKey]="tableKey"></app-list>
        </div>
        <div class="right-side-si white-box">
          <div class="item" *ngIf="!isWhiteListApiCall && securityInfoData.length===0">
            <ngx-skeleton-loader count="2" class="rhs-content-loader tab-content-loader"> </ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" class="rhs-content-loader selected-severity-loader"> </ngx-skeleton-loader>
            <ngx-skeleton-loader count="2" class="rhs-content-loader beach-mark-loader"> </ngx-skeleton-loader>
            <ngx-skeleton-loader count="2" class="rhs-content-loader graph-section-loader"> </ngx-skeleton-loader>
            <ngx-skeleton-loader count="4" class="rhs-content-loader content-loader"> </ngx-skeleton-loader>
          </div>
          <div class="no-data" *ngIf="securityInfoData.length===0 && isWhiteListApiCall">{{noDataMsg}}
          </div>
          <app-selected-list-details *ngIf="securityInfoData && securityInfoData.length && currentSelectedRow"
            [selectedRowApiCall]="selectedRowApiCall" 
            [selectedRow]="currentSelectedRow" [selectedRowDetails]="selectedRowDetails"
            [selectedRowInfoDetails]="selectedRowInfoDetails" [btnLabel]="'Apply'" 
            (updateSelectedDetailsList)="whiteList($event)" [compType]="'security'"
            [page]="assetsPage" [totalPage]="totalAssetsPage" [selectedApiAssets]="selectedApiAssets"
            [isScrollEligible]="true"
            (scroll)="onScroll($event)"
            [searchText]="perameterId">
          </app-selected-list-details>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>