import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomDailog } from '../../shared/model/Dialog.model';

@Component({
  selector: 'app-active-scan-schedule',
  templateUrl: './active-scan-schedule.component.html',
  styleUrls: ['./active-scan-schedule.component.scss']
})
export class ActiveScanScheduleComponent {
  dataSource:any=[]
  device_name:string=''
  constructor(public dialogRef: MatDialogRef<ActiveScanScheduleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CustomDailog){
      if(data?.data?.payload) {
       this.dataSource = data?.data?.payload;
      }
      if(data?.data?.deviceName){
        this.device_name=data?.data?.deviceName
      }

    }
    displayedColumns: string[] = ['number', 'scanName', 'frequency', 'schedule', 'time'];
  
   

}
