export interface User {
  id?: string;
  position?: string | number;
  firstname?: string;
  lastname?: string;
  organization_email?: string;
  phone_number?: string;
  country_code?: string;
  mfa?: string;
  role?: string | null;
  customize?: number;
  is_active?: boolean;
  isNewUser?: boolean;
  kebabOptions?: string[]
}

export interface RoleItem {
  id?: string;
  selected?: boolean;
  activated?: boolean;
  name?: string;
}

export enum validation_messages {
  DUPLICATE_USER = "Duplicate user entered",
  INVALID_EMAIL = "The email is not a valid email"
}
export interface IUserListReq {
  order_column?: string;
  sort_by?: string,
  rows_count?: number,
  page_no?: number,
  search?: string,
  role_filter?: string[] | null
  type?: number,
}
export interface IUpdateUserRoleReq {
  user_id?: string;
  role_id?: string;
}
export interface IUserList {
  users_list?: User[];
  numberofpages?: number;
}
export interface IUserListResponse {
  status?: number;
  message?: string;
  data?: IUserList;
}
export interface InviteUserReq {
  role_id?: string
  email?: string;
}
export interface InviteUserMsg {
  email?: string;
  message?: string;
  status?: string;
}
export interface IInviteUserResponse {
  status?: number;
  message?: string;
  data?: InviteUserMsg[];
}
export interface IDeleteUser {
  user_id?: string;
}
export interface IDeleteUserResponse {
  status?: number;
  message?: string;
  data?: null;
}
export interface IAciveDeactiveReq {
  user_id?: string;
  is_active?: boolean;
}
export interface IAciveDeactiveResponse {
  status?: number;
  message?: string;
  data?: null;
}
export interface IResedPasswordReq {
  email?: string;
}
export interface IResedPasswordResponse {
  status?: number;
  message?: string;
  data?: null;
}
export interface IUpdatedUserReq {
  user_id?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  organization_email?: string | null;
  phone_number?: string | null;
  country_code?: string | null;
}
export interface IUpdatedUserResponse {
  status?: number;
  message?: string;
  data?: null;
}