<div class="chart-section vulnerabilty-chart">
    <div class="control-panel-col-header">
        <h3 *ngIf="title" class="spark-sec-tittle">{{title}}({{totalCount}})</h3> 
    </div>
    <div class="vulnerability-loader" *ngIf="isSpinner" >
        <!-- <ngx-skeleton-loader class="chart-loader"  count="1" appearance="circle" [theme]="{ width: '120px', height: '120px' }"> </ngx-skeleton-loader> -->
        <div class="vunerability-value">
            <ngx-skeleton-loader class="skeleton-loader" count="4"></ngx-skeleton-loader>
        </div>
        
      </div>
      <div 
    *ngIf="data.critical === 0 && data.high === 0 && data.medium === 0 && data.low === 0" 
    [hidden]="isSpinner" 
    [ngStyle]="{ 'height': '15px' }">
</div>
<div  [hidden]="isSpinner" *ngIf="data.critical !== 0 || data.high !== 0 || data.medium !== 0 || data.low !== 0"> 
  <div class="cve-container">
  <div class="cve-bar">
    <div class="bar critical" [style.flex]="data.critical"></div>
    <div class="bar-gap"></div>
    <div class="bar high" [style.flex]="data.high"></div>
    <div class="bar-gap"></div>
    <div class="bar medium" [style.flex]="data.medium"></div>
    <div class="bar-gap"></div>
    <div class="bar low" [style.flex]="data.low"></div>
  </div>
  <div class="cve-line-container">
    <div class="horizontal-line"></div>
    <div class="line-marker" *ngFor="let severity of severityLevels" [style.flex]="data[severity]">
      <ng-container *ngIf="data[severity] !== 0">
        <span class="vertical-line"></span>
        {{ data[severity] }}
      </ng-container>
    </div>
  </div>
</div>
</div>
</div>


  
  
