<div class="admin_section out-layout role-management-page ">
    <div class="role-main-section">
        <div class="role-search white-box p0">
            <div *ngIf="!tabApiLoaded" class="role-data-section role-data white-box p0">
                <ngx-skeleton-loader count="18">
                </ngx-skeleton-loader>
                <div class="control-panel-overlay"></div>
            </div>
            <div class="role-search-header" *ngIf="tabApiLoaded">
                <button class="btn add-role" (click)="openDialog('0ms', '0ms')"><mat-icon>add</mat-icon></button>
                <div class="search-bar">
                    <mat-form-field appearance="fill">
                        <mat-label>Search Role</mat-label>
                        <input matInput placeholder="" [(ngModel)]="searchRole" autocomplete="off" autocomplete="off"
                            type="text">
                        <mat-icon class='search-icon' matPrefix>search</mat-icon>
                    </mat-form-field>
                </div>
            </div>
            <div class="role-search-list scroll" *ngIf="tabApiLoaded">
                <div [ngClass]="{'active': role?.name === currentRoleObj.name}"
                    (click)="isApiCallDone? checkTabChange(i, currentRoleObj):null"
                    *ngFor="let currentRoleObj of roles | search : searchRole; let i = index " class="role-label">
                    <label class="labelHeading text_ellipsis" [options]="toolTipOption"  tooltip="{{currentRoleObj.name}}">{{currentRoleObj.name}}</label>
                    <div (click)="$event.stopPropagation()" class="role-dropdown kebab-tool"
                        *ngIf="!currentRoleObj.default_role">
                        <a mat-icon-button aria-label="Example icon button with a horizontal three dot icon"
                            [matMenuTriggerFor]="menu">
                            <mat-icon class="kebab-icon">more_vert</mat-icon>
                        </a>
                        <mat-menu #menu="matMenu">
                            <a mat-menu-item (click)="openDailog(currentRoleObj, currentRoleObj.id)">Rename</a>
                            <a mat-menu-item (click)="deleteRole(currentRoleObj)"> Delete Role</a>
                        </mat-menu>
                    </div>
                </div>

            </div>
        </div>
        <div *ngIf="apiCount < 2" class="role-data-section role-data white-box p0">
            <ngx-skeleton-loader count="18">
            </ngx-skeleton-loader>
            <div class="control-panel-overlay"></div>
        </div>
        <div class="role-data-section role-data white-box p0" *ngIf="role && apiCount==2">
            <div class="role-data-header">
                <h3>{{role.name}}
                    <span class="warn-msg" *ngIf="isRoleEditable(role)">
                    *You don't have permission to edit the role.
                </span></h3>
                <div class="role-tabs">
                    <mat-button-toggle-group (change)="updateState($event)" [value]="selectedState" name="fontStyle"
                        class="spark-btngroup" aria-label="Font Style">
                        <mat-button-toggle [value]="1"

                            [ngModelOptions]="{standalone: true}" [ngClass]="{'active':selectedState==1}">Permissions</mat-button-toggle>

                        <mat-button-toggle [value]="2" [ngClass]="{'active':selectedState==2}">Regions & Assets</mat-button-toggle>
                        <!-- <mat-button-toggle [value]="3">Notifications</mat-button-toggle> -->
                    </mat-button-toggle-group>
                </div>
                <div class="role-btns">

                    <button class="btn btn-outline"
                        *ngIf="roleDetails && roleDetails?.permissions?.length && !isSaveDisable"
                       
                        (click)="saveChanges()">Save</button>
                        <button class="btn btn-outline" *ngIf="!role.default_role"
                    (click)="resetValues(role)">Cancel</button>
                </div>
            </div>
            <div class="tool_management">

                <div class="tool_management_inner ">
                    <div class="tool_management-permissions role-data-container hide-role"
                        [ngClass]="{'show': selectedState === 1}">
                            <div class="tool_managementleft-inner">
                                <div class="super-permission">
                                    <div class="tool_label">
                                        <label>Modules</label>
                                    </div>
                                    <!-- <button class="btn btn-plain" (click)="resetRoles()">Reset</button> -->
                                    <div class="tool_switch right_ele"  *ngIf="!isSystemRole(role.name)">
                                        <mat-slide-toggle [(ngModel)]="enableAll"
                                            (change)="toggleEnableAll($event)"></mat-slide-toggle>
                                    </div>
                                </div>
                                <ul *ngIf="role.permissions" class="tool_list scroll">
                                    <li class="align-items-center tool-list-item" *ngFor="let permission of roleDetails.permissions"
                                        (click)="handlePermissionClick(permission)"  [ngClass]="{'active': selectedPermission === permission}">
                                        <div class="d-flex ">
                                            <div class="tool_label ">
                                                <label>{{permission.label}}</label>
                                            </div>
                                            <div class="tool_switch right_ele" Confirm
                                                *ngIf="permission.code && permission.type === 'toggle'; else stepper">
                                                <mat-slide-toggle [disabled]="isRoleEditable(role)"
                                                    [(ngModel)]="role.permissions[permission.code]"
                                                    (change)="toggleParentRole($event, role, permission.subPermissions)"></mat-slide-toggle>
                                            </div>
                                            <ng-template #stepper>
                                                <div class="tool_switch right_ele"
                                                    *ngIf="permission.code && permission.type === 'stepper'">
                                                    <div class="tool_value">
                                                        <div class="value-button" id="decrease"
                                                            [ngClass]="{'disabled': isRoleEditable(role)}"
                                                            (click)="onStepperChange(role, permission.code, 'decrement')"
                                                            value="Decrease Value">-
                                                        </div>
                                                        <input type="number" id="number" style="pointer-events: none; "
                                                            [(ngModel)]="role.permissions[permission.code]" readonly />
                                                        <div class="value-button" id="increase"
                                                            [ngClass]="{'disabled': isRoleEditable(role)}"
                                                            (click)="onStepperChange(role, permission.code, 'increment')"
                                                            value="Increase Value">+
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="tool_managementright-inner">
                                <div class="super-permission">
                                    <div class="tool_label">
                                        <label>{{selectedPermission.label}}</label>
                                    </div>
                                </div>
                                <div *ngIf="selectedPermission">
                                    <div class="tool-sublist" *ngIf="selectedPermission.subPermissions && selectedPermission.subPermissions.length > 0; else noDataMessage">
                                        <ul class="tool_list">
                                            <li *ngFor="let subPermission of selectedPermission.subPermissions">
                                                <div class="d-flex">
                                                    <div class="tool_label">
                                                        <label>{{subPermission.label}}</label>
                                                    </div>
                                                    <div class="tool_switch right_ele" *ngIf="subPermission.code && subPermission.type === 'toggle'; else stepper">
                                                        <mat-slide-toggle [disabled]="isRoleEditable(role)"
                                                                          [(ngModel)]="role.permissions[subPermission.code]"
                                                                          (change)="toggleChildRole($event, role, selectedPermission)">
                                                        </mat-slide-toggle>
                                                    </div>
                                                    <ng-template #stepper>
                                                        <div class="tool_switch right_ele" *ngIf="subPermission.code && subPermission.type === 'stepper'">
                                                            <div class="tool_value">
                                                                <div class="value-button" id="decrease"
                                                                     [ngClass]="{'disabled': isRoleEditable(role)}"
                                                                     (click)="onStepperChange(role, subPermission.code, 'decrement')"
                                                                     value="Decrease Value">-
                                                                </div>
                                                                <input type="number" id="number"
                                                                       [(ngModel)]="role.permissions[subPermission.code]" />
                                                                <div class="value-button" id="increase"
                                                                     [ngClass]="{'disabled': isRoleEditable(role)}"
                                                                     (click)="onStepperChange(role, subPermission.code, 'increment')"
                                                                     value="Increase Value">+
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <ng-template #noDataMessage>
                                        <div class="no-data">
                                            The selected module has no sub permissions.
                                        </div>
                                    </ng-template>
                                </div>
                                
                            </div>
                        <!-- <div class="role-data-modules">
                            <div class="spark-accordion-tab">
                                <div class="spark-accordion-tabheader" (click)="toggleDescription()">
                                    <p>Module</p>
                                    <mat-icon class="accordion-arrow">keyboard_arrow_down</mat-icon>
                                </div>
                                <div class="spark-accordion-tabbody" *ngIf="showDescription">
                                    <ul class="tool_list">
                                        <li>
                                            <div class="d-flex align-items-center">
                                                <div class="tool_label ">
                                                    <label>Upload File</label>
                                                </div>
                                                <div class="tool_switch right_ele">
                                                    <mat-slide-toggle></mat-slide-toggle>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="d-flex  align-items-center">
                                                <div class="tool_label ">
                                                    <label>Upload File</label>
                                                </div>
                                                <div class="tool_switch right_ele">
                                                    <mat-slide-toggle></mat-slide-toggle>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="d-flex align-items-center ">
                                                <div class="tool_label ">
                                                    <label>Upload File</label>
                                                </div>
                                                <div class="tool_switch right_ele">
                                                    <mat-slide-toggle></mat-slide-toggle>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="spark-accordion-tab">
                                <div class="spark-accordion-tabheader" (click)="toggleDescription()">
                                    <p>Module</p>
                                    <mat-icon class="accordion-arrow">keyboard_arrow_down</mat-icon>
                                </div>
                                <div class="spark-accordion-tabbody" *ngIf="showDescription">
                                    <ul class="tool_list">
                                        <li>
                                            <div class="d-flex align-items-center">
                                                <div class="tool_label ">
                                                    <label>Upload File</label>
                                                </div>
                                                <div class="tool_switch right_ele">
                                                    <mat-slide-toggle></mat-slide-toggle>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="d-flex  align-items-center">
                                                <div class="tool_label ">
                                                    <label>Upload File</label>
                                                </div>
                                                <div class="tool_switch right_ele">
                                                    <mat-slide-toggle></mat-slide-toggle>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="d-flex align-items-center ">
                                                <div class="tool_label ">
                                                    <label>Upload File</label>
                                                </div>
                                                <div class="tool_switch right_ele">
                                                    <mat-slide-toggle></mat-slide-toggle>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> -->
                    </div>


                    <div class="regions-devices tool_managementright hide-role"
                        [ngClass]="{'show': selectedState === 2}">
                        <div *ngIf="showFilterForCurrentTab(role)" class="tool_managementright-inner" [ngClass]="{'userCreated': !role.default_role == false}">
                            <app-filter class="filter-header" *ngIf="filterColumnData.get('Region')?.length"
                                [type]="'checkbox'"
                                [filterColumnData]="filterColumnData" [filterSecondLevelData]="deviceDetails"
                                (selectedFiltersEvent)="selectedRegions($event)" [resetButtonRequired]="false"
                                [isSecondLevel]="true" [autoUpdateBothLevel]="true" [showCloseBtn]="false"
                                [clearButtonRequired]="false" [isFirstLevelApiCalled]="isFirstLevelApiCalled"
                                [isSecondLevelApiCalled]="isSecondLevelApiCalled"
                                (applyBasedOnSelectedData)="updateBothLevelData($event)" [isHeaderHide]="false" [isDividerHide]="false" [isRightClickMenuHide]="false">
                            </app-filter>
                        </div>

                    </div>
                    <div class="role-notifications hide-role" [ngClass]="{'show': selectedState === 3}">
                        <div class="role-notification-list">
                            <div class="role-data-modules">
                                <div class="spark-accordion-tab">
                                    <div class="spark-accordion-tabheader" (click)="toggleDescription()">
                                        <p>Module</p>
                                        <mat-icon class="accordion-arrow">keyboard_arrow_down</mat-icon>
                                    </div>
                                    <div class="spark-accordion-tabbody" *ngIf="showDescription">
                                        <ul class="tool_list">
                                            <li>
                                                <div class="d-flex align-items-center">
                                                    <div class="tool_label ">
                                                        <label>Upload File</label>
                                                    </div>
                                                    <div class="tool_switch right_ele">
                                                        <mat-slide-toggle></mat-slide-toggle>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="d-flex  align-items-center">
                                                    <div class="tool_label ">
                                                        <label>Upload File</label>
                                                    </div>
                                                    <div class="tool_switch right_ele">
                                                        <mat-slide-toggle></mat-slide-toggle>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="d-flex align-items-center ">
                                                    <div class="tool_label ">
                                                        <label>Upload File</label>
                                                    </div>
                                                    <div class="tool_switch right_ele">
                                                        <mat-slide-toggle></mat-slide-toggle>
                                                    </div>
                                                </div>           
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="role-notification-list scroll">
                            <div class="role-data-modules">
                                <div class="spark-accordion-tab">
                                    <div class="spark-accordion-tabheader" (click)="toggleDescription()">
                                        <p>Module</p>
                                        <mat-icon class="accordion-arrow">keyboard_arrow_down</mat-icon>
                                    </div>
                                    <div class="spark-accordion-tabbody" *ngIf="showDescription">
                                        <ul class="tool_list">
                                            <li>
                                                <div class="d-flex align-items-center">
                                                    <div class="tool_label ">
                                                        <label>Upload File</label>
                                                    </div>
                                                    <div class="tool_switch right_ele">
                                                        <mat-slide-toggle></mat-slide-toggle>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="d-flex  align-items-center">
                                                    <div class="tool_label ">
                                                        <label>Upload File</label>
                                                    </div>
                                                    <div class="tool_switch right_ele">
                                                        <mat-slide-toggle></mat-slide-toggle>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="d-flex align-items-center ">
                                                    <div class="tool_label ">
                                                        <label>Upload File</label>
                                                    </div>
                                                    <div class="tool_switch right_ele">
                                                        <mat-slide-toggle></mat-slide-toggle>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>