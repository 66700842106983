<div class="chart-section cyber-reilience">
    <!-- <div *ngIf="isSpinner" class="loading-text">{{constant.LOADING}}</div> -->

    <div class="control-panel-col-header">
        <h3 class="spark-sec-tittle" *ngIf="title">{{title}} </h3> 
        <form *ngIf="false" class="date-range-section" [formGroup]="datePickerRange">
            
                <div class="date-field">
                    <label class="date-field-label">From: </label>
                    <mat-form-field>
                        <mat-label>DD/MM/YYYY</mat-label>
                        <input matInput [matDatepicker]="startDate" formControlName="startDate" (dateChange)="getCustomRangeData()" [min]="minimumDate" [max]="maximumDate">
                        <mat-datepicker-toggle matIconSuffix [for]="startDate"></mat-datepicker-toggle>
                        <mat-datepicker #startDate></mat-datepicker>
                        <!-- <mat-error *ngIf="datePickerRange.get('startDate').hasError('matStartDateInvalid')">
                            Invalid start date.
                        </mat-error> -->
                    </mat-form-field>
                </div>
                <div class="date-field">
                    <label class="date-field-label">To: </label>
                    <mat-form-field>
                        <mat-label>DD/MM/YYYY</mat-label>
                        <input matInput [matDatepicker]="endDate" formControlName="endDate" (dateChange)="getCustomRangeData()" [min]="minimumDate" [max]="maximumDate">
                        <mat-datepicker-toggle matIconSuffix [for]="endDate"></mat-datepicker-toggle>
                        <mat-datepicker #endDate></mat-datepicker>
                        <!-- <mat-error *ngIf="datePickerRange.get('endDate').hasError('matEndDateInvalid')">
                            Invalid start date.
                        </mat-error> -->
                    </mat-form-field>
                </div>
                <p class="date-err" *ngIf="datePickerRange.touched && datePickerRange.invalid">Please select valid start/end date.</p>
        </form> 
        <!-- <div class="spark-btngroup cyber-reilience-gtngroup region-sort">
            <mat-button-toggle-group name="fontStyle" aria-label="Font Style" class="spark-btngroup cyber-reilience-gtngroup">
                <mat-button-toggle value="1D">1D</mat-button-toggle>
                <mat-button-toggle value="1W">1W</mat-button-toggle>
                <mat-button-toggle value="1M">1M</mat-button-toggle>
                <mat-button-toggle value="3M">3M</mat-button-toggle>
                <mat-button-toggle value="6M">6M</mat-button-toggle>
                <mat-button-toggle value="1Y">1Y</mat-button-toggle>
                <mat-button-toggle value="custom">Custom</mat-button-toggle>
            </mat-button-toggle-group>
        </div> -->

        <!-- <div class="spark-btngroup cyber-reilience-gtngroup region-sort">
            <mat-button-toggle-group name="fontStyle" aria-label="Font Style" class="spark-btngroup cyber-reilience-gtngroup" [(ngModel)]="this.selectedMonth">
              
              <mat-button-toggle *ngFor="let monthFilter of monthFilter" [value]="monthFilter.id" (click)="filterMonth(monthFilter)">

                {{ monthFilter.name }}
              </mat-button-toggle>
              
            </mat-button-toggle-group>
          </div> -->
          <div class="right-btns">
            <div>
              <button  mat-button class="btn-custom download-btn" (click)="OpenReport()"><img _ngcontent-xlr-c163="" src="../../assets/images/dowload_iconsmall.svg"> Download</button>
            </div>
            <div *ngIf="monthFilter.length">
              <!-- <button mat-button [matMenuTriggerFor]="securityHistory" class="btn-custom">
                <label class="sort-title">{{ selectedMonthLabel }}</label>
                <mat-icon>arrow_drop_down</mat-icon>
              </button> -->
              <mat-menu #securityHistory="matMenu" class="level-menu" (closed)="setDates()" [xPosition]="'before'" >
                <div class="inner-content" [formGroup]="datePickerRange">
                  <div class="date-range-container">
                    <div class="d-flex full-width">
                      <div class="half-width">
                        <label style="font-weight: bold !important;">From</label>
                        <mat-form-field class="no-bg" appearance="outline" (click)="$event.stopPropagation()">
                          <input matInput [matDatepicker]="startDate" formControlName="startDate" (dateChange)="onDateChange($event,'startDate')" placeholder="Select Date" 
                          [max]="maxDate" #dateInput (input)="onDateInput($event, 'startDate')" >
                          <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                          <mat-datepicker #startDate></mat-datepicker>
                        </mat-form-field>
                      </div>
                      <div class="half-width">
                        <label>To</label>
                        <mat-form-field class="no-bg" appearance="outline" (click)="$event.stopPropagation()">
                          <input matInput [matDatepicker]="endDate"   (dateChange)="onDateChange($event,'endDate')" formControlName="endDate" placeholder="Select Date" [max]="maxDate" 
                          (input)="onDateInput($event, 'endDate')">
                          <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                          <mat-datepicker #endDate></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="btn-section">
                      <button class="btn apply-btn" 
                              (click)="applyDateRange()" 
                              [disabled]="datePickerRange.invalid || datePickerRange.hasError('invalidRange')">
                        Apply
                      </button>
                    </div>
                  </div>
                  <!-- <div class="info-text" *ngIf="datePickerRange.valid && !datePickerRange.hasError('invalidRange')">
                    <mat-icon>info</mat-icon>
                    <span class="info-note">You can select a minimum period of 7 days.</span>
                  </div> -->
                  <p class="date-err" *ngIf="datePickerRange.touched && datePickerRange.invalid">Please select a valid start/end date.</p>
                </div>
                
                
                
                
                <div class="menu-item-list">
                  <button (click)="filterMonth(month)" *ngFor="let month of monthFilter;let i = index" mat-menu-item class="list-item" [ngClass]="{'active': month.id === selectedMonth, 'even-child': i % 2 === 1}">{{ month.name }}</button>
                </div>
              </mat-menu>
            </div>
          </div>

          
          
          
    </div>
    

    <div class="cyber-resilience">
        <div class="canvas-loader" *ngIf="isSpinner">
          <div class="vertical-items">
            
            <ngx-skeleton-loader *ngFor="let height of loaderHeights" count="1" appearance="circle" [theme]="{
              width: '20px',
              height:height,
              'border-radius': '0px',
            }" class="vertical-bar">
          </ngx-skeleton-loader>
           </div>  

            <ngx-skeleton-loader count="1" class="horizontal-bar"></ngx-skeleton-loader>
        </div>
        <div *ngIf="showPopup" (click)="overlayClick()" class="window-over-lay"></div>
        <div *ngIf="showPopup" [style]="positionVal(position.left)"
            class="cybertooltip-popup-section">
            <h5>Security Score on {{currentSelectedDateEvent.date_time | date:'d MMM, y'}}</h5>
            <div class="cyber-score-list scroll">
                <ul>
                  <li *ngFor="let event of eventData; let i = index" 
                  [class.disable-cursor]="(isClicked && loaderLength === i && (!(event.event_code.includes('device')) && (event.reference_id == null || event.reference_id == undefined))) || filterPage === 'cyberDevice'"
                  (click)="(!isClicked || (event.event_code.includes('device') || event.reference_id != null)) && (filterPage == 'dashboardCyber' && !isClicked) ? navigateToUrl(event, i) : null">
                
                  
                  
                  <div class="cyber-time">{{event.event_date | date:'HH:mm:ss'}} - <span>{{event.current_score.toFixed(2)}}</span></div>
                  
                  <div *ngIf="event.score_difference < 0" class="cyber-arrow arrow-down-evt">
                      <mat-icon>arrow_drop_down</mat-icon>
                  </div>
                  
                  <div *ngIf="event.score_difference > 0" class="cyber-arrow arrow-up-evt">
                      <mat-icon>arrow_drop_up</mat-icon>
                  </div>
                  
                  <div *ngIf="event.score_difference === 0" class="cyber-arrow"></div>
                  
                  <div class="cyber-value">{{getPositiveValue(event.score_difference)?.toFixed(2)}}</div>
                  <div class="cyber-status text_ellipsis" [title]="event.event_name">({{event.event_name}})</div>
                  
                  <span *ngIf="isLiLoading[i]" class="btn-loader spin">
                      <img src="../../../../../assets/images/btn-loader.png" />
                  </span>
              </li>
              
                    <li *ngIf="eventData.length === 0 && !loadEvents">No Data</li>
                </ul>
                <div class="item" *ngIf="loadEvents">
                    <ngx-skeleton-loader count="2" class="table-loader cyber-reilience-loader" [theme]="{
                                width: '15%',
                                'border-radius': '0',
                                height: '15px',
                                'margin-bottom': '5px'
                                }"
                        *ngFor="let item of [].constructor(8); let i = index">
                    </ngx-skeleton-loader>
                </div>
            </div>
        </div>
        <div id="cyberResilience" #cyberResilience></div>
        <div *ngIf="!chartResponse?.length && !isSpinner" class="loading-text">No Data</div>
    </div>
    
</div>