import { Component } from '@angular/core';
import { ILoadingCellRendererAngularComp, ILoadingOverlayAngularComp } from 'ag-grid-angular';
import { ILoadingCellRendererParams, ILoadingOverlayParams } from 'ag-grid-community';
type CustomLoadingOverlayParams = ILoadingOverlayParams & { totalColumn: number, totalRow: number };
@Component({
  selector: 'app-table-loader',
  templateUrl: './table-loader.component.html',
  styleUrls: ['./table-loader.component.scss']
})
export class TableLoaderComponent implements ILoadingOverlayAngularComp{
  public params!: CustomLoadingOverlayParams | any;
  agInit(params: ILoadingOverlayParams): void {
      this.params = params;
  }
}
