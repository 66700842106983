import { Component, OnInit } from '@angular/core';
import { FilterService } from '../../shared/services/filter.service';
import { AuthService } from '../../shared/services/auth.service';
import { ProfileService } from '../../shared/services/profile.service';
import { GlobalFilterService } from '../../shared/services/global-filter.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit{
  public accountSubscription!: Subscription;
  private accountFilterSubscription!: Subscription;
  constructor(private authService: AuthService,private profileService: ProfileService,
    private globalFilterService: GlobalFilterService,
    private filterService: FilterService) {
    //console.log("efaedsf")
  } 
  ngOnInit(): void {
    this.accountSubscription = this.globalFilterService.accountNavFilterState.subscribe(
      (flag: boolean) => {
        this.openAccountFilter(flag);
      }
    )  
  }
  openAccountFilter(flag: boolean) {
    this.filterService.toggleSidenav('accountFilter');
  }

  public closeFilter() {
    this.filterService.closeSidenav();
  }

}
