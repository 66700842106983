<!-- <div class="ag-center"> -->
          <!-- <span   matTooltip="params.value" >{{params.value}}</span> -->
          <!-- <span *ngIf="isSafari"  class="text_ellipsis" title="">{{element.paramter}}</span> -->
          <!-- <span   matTooltip="params.data.desctiption_tooltip"> {{params.data.desctiption}}</span> -->
          <!-- <span *ngIf="isSafari" class="text_ellipsis"  [tooltip]="params.data.desctiption_tooltip" >{{element.desctiption}}</span> -->
<!-- </div> -->

<div style="margin-top: -8px;">
  <strong matTooltip="{{ params.value }}" class="paramer-value">{{ params.value }}</strong>
  <p matTooltip="{{ params.data.desctiption }}"  style="margin-top: -15px;" class="text_ellipsis paramer-value">{{ params.data.desctiption }}</p>
</div>
