import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { AgGridComponent } from '../ag-grid/ag-grid.component';
import { CONFIG } from '../../constants/config';
import { CveAssetsService } from '../../services/cve-assets.service';
import { ICommonVulnarability, ICommonVulnarabilityResponse, IDrop, ISelectedVulnarability } from '../common-vulnerability/common.vulnerability.model';
import { RestService } from '../../services/rest.service';
import { ResponseService } from '../../services/response.service';
import { AuthService } from '../../services/auth.service';
import { END_POINTS } from '../../constants/endpoints';
import { CveService } from '../../services/cve.service';
import { SeverityVulComponent } from '../severit-vul/severity-vul.component';
import { CisaKevHeaderComponent } from 'src/app/modules/cve-dashboard/cisa-kev-header/cisa-kev-header.component';
import { CisaKevComponent } from 'src/app/modules/cve-dashboard/cisa-kev/cisa-kev.component';
import { EpssHeaderComponent } from 'src/app/modules/cve-dashboard/epss-header/epss-header.component';


@Component({
  selector: 'app-cve-assets',
  templateUrl: './cve-assets.component.html',
  styleUrls: ['./cve-assets.component.scss']
})
export class CveAssetsComponent {
  @ViewChild('myGrid') grid!: AgGridComponent;
  @ViewChild('searchField') searchField!: ElementRef;
  @Output() selectRowChange = new EventEmitter<any>();
  @Output() openCveSummery = new EventEmitter<any>();
  public isApiCalled: boolean = false;
  public isCveSelected:boolean = false;
  public tableHeader = CONFIG.TABLE_INFO.CVE_DASHBOARD.HEADER_LABEL;
  public tableProps = CONFIG.TABLE_INFO.CVE_DASHBOARD.PROPS;
  public commonVulnarabilites: ICommonVulnarability[] = [];
  public allCommonVulnarabilites: ICommonVulnarability[] = [];
  @Input() payLoad: any;
  @Input() apiName: string = '';
  @Input() showSearch: boolean = true
  @Input() isCveSummery: boolean = true;
  public vulnarabilityMenu: IDrop[] = [];
  public selectedVulnarability: ISelectedVulnarability = {};
  @Output() cveListLength = new EventEmitter<number>();

  public agDeviceColumnDefs: ColDef[] = [
    { headerName: this.tableHeader.POSITION,
      field: this.tableProps.POSITION,
      tooltipField:this.tableProps.SERIAL_NO,
      headerTooltip:this.tableHeader.POSITION,
      lockPosition: true,
      // pinned: 'left',
      filter:false,
    },
    { headerName: this.tableHeader.CVE_ID, field: this.tableProps.CVE_ID, headerTooltip:this.tableHeader.CVE_ID,lockPosition: true,  tooltipField: this.tableProps.CVE_ID,},
    { headerName: this.tableHeader.CVSS, field: this.tableProps.CVSS,  headerTooltip:this.tableHeader.CVSS,  tooltipField: this.tableProps.CVSS,},
    {
      headerName: this.tableHeader.SEVERITY,
      field: this.tableProps.SEVERITY,
      headerTooltip:this.tableHeader.SEVERITY,
      cellRenderer: SeverityVulComponent,
      onCellClicked: (event: CellClickedEvent) => {
        this.getRowData(event);
      }
    },
    {
      headerName: this.tableHeader.CISA_KEY,
      field: this.tableProps.CISA_KEY,
      // headerTooltip:this.tableHeader.CISA_KEY,
      headerComponent: CisaKevHeaderComponent,
      cellRenderer: CisaKevComponent,
      cellClass: (params: any)=>{
        const exploitClass = params.data?.cisas_kev ? 'ag-red' : '';
        return exploitClass;
      },
      valueGetter: (params: any) => {
        return params.data?.cisas_kev ? 'Exploited' : 'Not Exploited';
      }
    },
    {
      headerName: this.tableHeader.EPSS,
      valueFormatter:(params) =>{    
              const value = params.value;    
              return value != null && ! isNaN (value) ?`${value}%`: value; },
      tooltipValueGetter(params) {
        const value = params.value;    
        return value != null && ! isNaN (value) ?`${value}%`: value;
      },
      field: this.tableProps.EPSS,
      // headerTooltip:this.tableHeader.EPSS,
      // tooltipField: this.tableProps.EPSS,
      headerComponent: EpssHeaderComponent
    },
  ];

  constructor(private cveAssetsService: CveAssetsService,private restService: RestService,
    private responseService: ResponseService,
    private authService: AuthService,
    private cveService: CveService // Inject CveService here
  ) {
  }

  ngOnInit(): void {
    this.loadCveCommonData()
  }

  loadCveCommonData(): void {
    this.isApiCalled = false;
    this.commonVulnarabilites = [];
    if (this.payLoad && this.payLoad.device_uuid) {
      const formattedPayload = [this.payLoad.device_uuid]; // Wrap the UUID in an array
      this.cveService.fetchCveCommonData((error: any, data: any) => {
        if (error) {
          console.error('Error fetching CVE common data', error);
          return;
        }
        this.isApiCalled = true
        this.commonVulnarabilites = data?.data?.cve_list || [];
        //sorting the data in format of critical,high,medium,low
        const critical = this.commonVulnarabilites.filter(item => item.severity === "Critical");      
        const high = this.commonVulnarabilites.filter(item => item.severity === "High");
        const medium = this.commonVulnarabilites.filter(item => item.severity === "Medium");
        const low = this.commonVulnarabilites.filter(item => item.severity === "Low");
        const sortedData = [...critical, ...high, ...medium, ...low]; // Combine the arrays
        this.commonVulnarabilites=sortedData;     
        
        this.commonVulnarabilites.forEach((element: any,index:number) => {
          element.position=index+1;
        });
        this.cveListLength.emit(this.commonVulnarabilites.length);
        window.setTimeout(() => {
          if(this.grid && this.grid.grid && this.grid.grid.api){
            this.grid.grid.api.sizeColumnsToFit();
          }
        }, 10);
      },formattedPayload);
    } else {
      this.cveService.fetchCveCommonData((error: any, data: any) => {
        if (error) {
          console.error('Error fetching CVE common data', error);
          return;
        }
        this.isApiCalled = true
        this.commonVulnarabilites = data?.data?.cve_list || [];
        this.commonVulnarabilites.forEach((element: any,index:number) => {
          element.position=index+1;
        });
        window.setTimeout(() => {
          this.grid.grid.api.sizeColumnsToFit();
        }, 10);
      });
    }
  }

  filterAssetsField() {
    this.grid.grid.api.setGridOption(
      "quickFilterText",
       this.searchField?.nativeElement?.value
    );
  }

  getRowData(row: any){
    this.cveAssetsService.vulnerabilityRow.next(row.data);
   }
   public onRowSelected(event: any){
    if(event && event.length) {
      if(this.isCveSummery) { // this flag we have added becase in control panel same component has used  to open cve dettails overlay
        this.isCveSelected = true;
        this.openCveSummery.emit(event);
      } else {
        this.selectRowChange.emit(event[0]); //
      }
    }
 }
}
