import { Component } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef,MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'custom-snack-bar',
  template: `
    <div class="custom-snackbar">
      <button mat-icon-button (click)="closeSnackBar()">
        <mat-icon aria-label="Close">close</mat-icon>
      </button>
      <span>{{ message }}</span>
      <button mat-button color="accent" (click)="clearAll()">{{ action }}</button>
    </div>
  `,
  styles: [`
    .custom-snackbar {
      display: flex;
      align-items: center;
      background-color: #333;
      color: #fff;
      padding: 8px;
      border-radius: 4px;
    }
    .custom-snackbar button {
      margin-right: 8px;
    }
    .custom-snackbar span {
      flex-grow: 1;
    }
  `],
})
export class CustomSnackBarComponent {
  message: string ="hiii";
  action: string= "he wle";

  constructor(private snackBar: MatSnackBar) {}

  closeSnackBar() {
    this.snackBar.dismiss();
  }

  clearAll() {
    // Add your logic for the "Clear All" action here
    console.log('Clear All action clicked');
  }
}
