import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';
import { CONFIG } from '../../shared/constants/config';
import { CONSTANT, CVE_PAGE } from '../../shared/constants/constant-data';

@Component({
  selector: 'app-cisa-kev-header',
  templateUrl: './cisa-kev-header.component.html',
  styleUrls: ['./cisa-kev-header.component.scss']
})
export class CisaKevHeaderComponent implements IHeaderAngularComp {
  public params!: IHeaderParams & IHeaderParams;
  public tableHeader = CONFIG.TABLE_INFO.CVE_DASHBOARD.HEADER_LABEL;
  public get tooltip() {
    return CVE_PAGE.cisaKevHeaderTooltip
  }
  agInit(params: IHeaderParams): void {
      this.params = params;
  }

  refresh(params: IHeaderParams) {
      return false;
  }
}