
<div class="calender-popup">
    <div class="calender-popup-header">
        <h3>Schedule Information - {{device_name}}</h3>
        <button mat-button [mat-dialog-close]="true" class="popup-close-btn">
            <mat-icon>close</mat-icon></button>
    </div>
    <div class="calander-data-tabel scroll">
        <table mat-table [dataSource]="dataSource" class="spark_table devices-list">
            <!-- Number Column -->
            <ng-container matColumnDef="number">
                <th mat-header-cell *matHeaderCellDef> # </th>
                <td mat-cell *matCellDef="let element; let i = index" class="number"> {{i+1}} </td>
            </ng-container>
    
            <!-- Scan Name Column -->
            <ng-container matColumnDef="scanName">
                <th mat-header-cell *matHeaderCellDef> Scan Name </th>
                <td mat-cell *matCellDef="let element" class="scanName" [tooltip]="element.Scan_Name"> {{element.Scan_Name.length > 15 ? element.Scan_Name.substring(0,15)+ '...':element.Scan_Name}} </td>
            </ng-container>
    
            <!-- Frequency Column -->
            <ng-container matColumnDef="frequency">
                <th mat-header-cell *matHeaderCellDef> Frequency </th>
                <td mat-cell *matCellDef="let element" class="frequency"> {{element.Frequency}} </td>
            </ng-container>
    
            <!-- Schedule Column -->
            <ng-container matColumnDef="schedule">
                <th mat-header-cell *matHeaderCellDef> Schedule </th>
                <td mat-cell *matCellDef="let element" class="schedule"> {{element.Schedule}} </td>
            </ng-container>
    
            <!-- Time Column -->
            <ng-container matColumnDef="time">
                <th mat-header-cell *matHeaderCellDef> Time </th>
                <td mat-cell *matCellDef="let element" class="time"> {{element.Time}} </td>
            </ng-container>
    
            <!-- Table Rows -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
    
</div>
