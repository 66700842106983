<div class="app-innercontainer">
    <div class="personal_details">
        <div class="d-flex">
            <div class="profile_photo">
                <mat-icon aria-hidden="false" aria-label="Example person icon" fontIcon="person"></mat-icon>
            </div>
            <div class="profile_info pt_3 pl_4">
                <p class="font_bold profile_name pb_2">Steve Johnson</p>
                <P><Label>Email:</Label>steve.johnson@gmail.com</P>
            </div>
        </div>
        <div class="bg-light my_4 p4 profile_details">
            <div class="d-flex mb_4">
                <div class="half-width">
                    <p class="lable_object">Organization Name:</p>
                    <p class="lable_value">ABC Company pvt Ltd</p>
                </div>
                <div class="half-width">
                    <p class="lable_object">Organization Role:</p>
                    <p class="lable_value">Analyst</p>
                </div>
            </div>
            <div class="d-flex">
                <div class="half-width">
                    <p class="lable_object">Mobile Number:</p>
                    <p class="lable_value">+1 837 8362 8373</p>
                </div>
                <div class="half-width">
                    <p class="lable_object">Date of Birth:</p>
                    <p class="lable_value">01/01/1990</p>
                </div>
            </div>
        </div>
        <div class="profile_details">
            <p class="lable_object">Address</p>
            <p class="lable_value">132, My Street, Kingston, New York 12401.</p>
        </div>
    </div>
</div>
