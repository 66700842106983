import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { CONSTANT } from 'src/app/modules/shared/constants/constant-data';
import { CustomDailog } from 'src/app/modules/shared/model/Dialog.model';
import { CredentionalService } from 'src/app/modules/shared/services/credentional.service';
import { DialogService } from 'src/app/modules/shared/services/dialog.service';
import { EncryptService } from 'src/app/modules/shared/services/encrypt.service';
import { SpinnerService } from 'src/app/modules/shared/services/spinner.service';

@Component({
  selector: 'app-credential-popup',
  templateUrl: './credential-popup.component.html',
  styleUrls: ['./credential-popup.component.scss']
})
export class CredentialPopupComponent {
  credentialForm!: FormGroup;
  credentials_list: any = [];
  execPassword: boolean = false;
  autoSave: boolean = false;
  showPasswordInput = false;
  showPrivateKeyInput = false;
  showApiTokenInput = false;
  exec_pass_hide: boolean = true;
  showNewCredentialFields: boolean = false;
  selectedPasswordTypeVal: string = "";
  credentional_types: any = [];
  credential_type_id:any;
  saveCred: boolean = false;
  hide = true;
  credentialTypes: any = [];
  passwordTypes: any[] = []; 
  asset_id:any;
  constructor(private fBuilder: FormBuilder, 
    public credentialService: CredentionalService,
    @Inject(MAT_DIALOG_DATA) public data: CustomDailog,
    public dialogService: DialogService,
  public spinnerService:SpinnerService,
  private encryptService: EncryptService) {
    this.credentialTypes = CONSTANT.CONNECTION_TYPE;
    this.passwordTypes = CONSTANT.PASSWORD_TYPE;
    this.credentionalList();
    this.credentialForm = this.fBuilder.group({
      select: new FormControl("",Validators.required),
      cred_name: new FormControl(""),
      username: new FormControl(""),
      password: new FormControl(""),
      api_token: new FormControl(""),
      privateFile: new FormControl(""),
      connectionType: new FormControl("ssh"),
      loginParameter: new FormControl("none"),
      enable_password: new FormControl(""),
      execPasswordToggle: new FormControl(""),
      autoAssignToggle: new FormControl("")
    });
  }

  ngOnInit(): void {
    this.credentionalTypes();
    this.asset_id = this.data?.data?.assetIds;
    this.credentialForm?.get('connectionType')?.valueChanges.subscribe(value => {
      if (value === 'ssh') {
        this.showApiTokenInput = false;
        this.credentialForm.get('api_token')?.setValidators(null);
        this.credentialForm?.get('api_token')?.setValue("");
        this.credentialForm?.get('api_token')?.markAsUntouched();
        if(this.showNewCredentialFields){
          this.credentialForm.get('username')?.setValidators([Validators.required]);
          this.credentialForm.get('username')?.setValue("");
          this.credentialForm.get('username')?.markAsUntouched();
        }
        this.credentialForm.get('loginParameter')?.setValue("none");
      } else {
        this.showApiTokenInput = true
        this.credentialForm.get('api_token')?.setValidators([Validators.required]);
        this.credentialForm.get('loginParameter')?.setValue("none");
        if(this.showNewCredentialFields){
        this.credentialForm.get('username')?.clearValidators();
        this.credentialForm.get('username')?.setValue("");
        this.credentialForm.get('username')?.markAsUntouched();
      }
      }
      this.credentialForm.get('api_token')?.updateValueAndValidity();
      this.credentialForm.get('loginParameter')?.updateValueAndValidity();
      this.credentialForm.get('username')?.updateValueAndValidity();
    })
    this.credentialForm?.get('loginParameter')?.valueChanges.subscribe(value => {
        this.selectedPasswordTypeVal = value;
        if (value === 'password') {
          this.showPasswordInput = true;
          this.showPrivateKeyInput = false;
          this.credentialForm?.get('password')?.setValidators(Validators.required);
          this.credentialForm?.get('privateFile')?.setValidators(null);
          this.credentialForm?.get('password')?.setValue("");
          this.credentialForm?.get('password')?.markAsUntouched();
          this.credentialForm.get('execPasswordToggle')?.reset();
          this.credentialForm.get('autoAssignToggle')?.reset();
        } else if (value === 'privatekey') {
          this.showPasswordInput = false;
          this.showPrivateKeyInput = true;
          this.credentialForm?.get('privateFile')?.setValidators(Validators.required);
          this.credentialForm?.get('password')?.setValidators(null);
          this.credentialForm?.get('privateFile')?.setValue("");
          this.credentialForm?.get('privateFile')?.markAsUntouched();
          this.credentialForm.get('execPasswordToggle')?.reset();
          this.credentialForm.get('autoAssignToggle')?.reset();
        } else {
          // const noneType = this.credentional_types[0].password_type.find((type: { code: string; }) => type.code === 'none');
          // if (noneType) {
          //   this.credential_type_id = noneType.id;
          // }
          this.showPasswordInput = false;
          this.showPrivateKeyInput = false;
          this.credentialForm?.get('password')?.setValidators(null);
          this.credentialForm?.get('privateFile')?.setValidators(null);
          this.credentialForm?.get('privateFile')?.setValue("");
          this.credentialForm?.get('password')?.setValue("");
          this.credentialForm?.get('password')?.markAsUntouched();
          this.credentialForm?.get('privateFile')?.markAsUntouched();
          this.credentialForm.get('execPasswordToggle')?.reset();
          this.credentialForm.get('autoAssignToggle')?.reset();
          this.credentialForm.get('execPasswordToggle')?.setValidators(null);
        }
        this.credentialForm?.get('password')?.updateValueAndValidity();
        this.credentialForm?.get('privateFile')?.updateValueAndValidity();
        this.credentialForm?.get('execPasswordToggle')?.updateValueAndValidity();
        this.credentialForm?.get('autoAssignToggle')?.updateValueAndValidity();
      });
      this.credentialForm.get('execPasswordToggle')?.valueChanges.subscribe(value => {
        if(value){
          this.execPassword = true
          this.credentialForm.get('autoAssignToggle')?.disable();
          this.credentialForm.get('enable_password')?.enable();
          this.credentialForm.get('enable_password')?.setValidators(Validators.required);
          this.credentialForm.get('enable_password')?.setValue("");
          this.credentialForm.get('enable_password')?.updateValueAndValidity();
          this.autoSave = false;
        } else {
          this.execPassword = false;
          this.credentialForm.get('autoAssignToggle')?.enable();
          this.credentialForm.get('enable_password')?.disable();
          this.credentialForm.get('enable_password')?.setValidators(null);
          this.credentialForm.get('enable_password')?.setValue("");
          this.credentialForm.get('enable_password')?.updateValueAndValidity();
        }
      });
      this.credentialForm.get('autoAssignToggle')?.valueChanges.subscribe(value => {
        if(value){
          this.credentialForm.get('execPasswordToggle')?.disable();
          this.credentialForm.get('enable_password')?.disable();
          this.credentialForm.get('enable_password')?.setValidators(null);
          this.credentialForm.get('enable_password')?.setValue("");
          this.credentialForm.get('enable_password')?.updateValueAndValidity();
          this.autoSave = true;
        } else {
          this.credentialForm.get('execPasswordToggle')?.enable();
          this.autoSave = false;
        }
      });
  }

  onCredentialChange(event: MatSelectChange) {
    if (event.value === 'Create New Credential') {
      this.showNewCredentialFields = true;
      this.credentialForm.get('cred_name')?.setValidators([Validators.required]);
      this.credentialForm.get('connectionType')?.setValidators([Validators.required]);
      this.credentialForm.get('loginParameter')?.setValidators([Validators.required]);
      this.credentialForm.get('execPasswordToggle')?.setValidators([Validators.required]);
      this.credentialForm.get('loginParameter')?.setValue("none");
      this.credentialForm.get('connectionType')?.setValue("ssh");
    } else {
      this.showNewCredentialFields = false;
      this.selectedPasswordTypeVal = "";
      this.saveCred = false;
      this.credentialForm.get('cred_name')?.clearValidators();
      this.credentialForm.get('loginParameter')?.clearValidators();
      this.credentialForm.get('connectionType')?.clearValidators();
      this.credentialForm.get('api_token')?.clearValidators();
      this.credentialForm.get('password')?.setValue("");
      this.credentialForm.get('privateFile')?.setValue("");
      // this.credentialForm.get('loginParameter')?.setValue("none");
      // this.credentialForm.get('connectionType')?.setValue("ssh");
      this.credentialForm.get('cred_name')?.setValue("");
      this.credentialForm.get('username')?.clearValidators();
      this.credentialForm.get('username')?.setValue("");
      this.credentialForm.get('api_token')?.setValue("");
      this.credentialForm.get('execPasswordToggle')?.clearValidators();
      this.credentialForm.get('autoAssignToggle')?.clearValidators();
      this.credentialForm.get('enable_password')?.clearValidators();
      this.credentialForm.get('enable_password')?.setValue("");
    }
    this.credentialForm.get('password')?.updateValueAndValidity();
    this.credentialForm.get('privateFile')?.updateValueAndValidity();
    this.credentialForm.get('cred_name')?.updateValueAndValidity();
    this.credentialForm.get('loginParameter')?.updateValueAndValidity();
    this.credentialForm.get('connectionType')?.updateValueAndValidity();
    this.credentialForm.get('api_token')?.updateValueAndValidity();
    this.credentialForm.get('username')?.updateValueAndValidity();
    this.credentialForm.get('execPasswordToggle')?.updateValueAndValidity();
    this.credentialForm.get('autoAssignToggle')?.updateValueAndValidity();
    this.credentialForm.get('enable_password')?.updateValueAndValidity();
  }

  public credentionalTypes() {
    this.credentialService.credentialTypes(this.credentialTypeResponse);
  }

  credentialTypeResponse = (response: any) => {
    if (response.status === 200) {
      this.credentional_types = response.data;
    }
  }

  public credentionalList(){
    this.credentialService.credentialList(this.credentialListResponse);
  }
  credentialListResponse= (response:any) => {
    if(response.status === 200) {
    this.credentials_list = response.data.cred_list;
   }
   }

   onCheckboxChange(event: any) {
    this.saveCred = event.checked
  }

  assetRescanCredential(){
    this.spinnerService.show();
    const { username, cred_name,password , privateFile, api_token, loginParameter,connectionType, select,enable_password, ...rest } = this.credentialForm.value
    let data = { username, cred_name,password , privateFile, api_token, connectionType,loginParameter, select, enable_password}
    let payload: any = {
      "asset_id": this.asset_id,
      "new_cred" : this.showNewCredentialFields,
      }
     if(this.showNewCredentialFields){
      payload.loginParameter = data.loginParameter;
      payload.username = data.username;
      payload.cred_name = data.cred_name;
      payload.save = this.saveCred;
      payload.global_cred = false;
      payload.description = '';
      payload.role_id = [];
      payload.user_id = [];
      if(this.autoSave){
        payload.auto_assign = true;
      }
      else {
        payload.auto_assign = false;
      }
      if(this.execPassword){
        payload.exec_password = true
        payload.enable_password = data.enable_password
      } 
      else {
        payload.exec_password = false
      }
     if (data.connectionType === "api") {
      payload.encrypt=true
      const APIType = this.credentional_types[1].password_type.find((type: { code: string; }) => type.code === 'api_token');
      if (APIType) {
        this.credential_type_id = APIType.id;
      }
      payload.api_token = data.api_token;
      payload.api_token = this.encryptService.doEncryptLogin(data.api_token);

      payload.custom_port = false;
      payload.credential_type_id = this.credential_type_id;
      // payload.custom_port_number = 22;
     } else {
      if (data.loginParameter === "password") {
        const passwordType = this.credentional_types[0].password_type.find((type: { code: string; }) => type.code === 'password');
        if (passwordType) {
          this.credential_type_id = passwordType.id;
        }
        payload.password = data.password;
        payload.credential_type_id = this.credential_type_id;
     }
     if (data.loginParameter === "privateFile") {
      const privateKeyType = this.credentional_types[0].password_type.find((type: { code: string; }) => type.code === 'private_key');
          if (privateKeyType) {
            this.credential_type_id = privateKeyType.id;
          }
      payload.private_key = data.privateFile;
      payload.credential_type_id = this.credential_type_id;
     }
     payload.username = data.username;
     }
     }
     else {
      payload.credential_id = data.select;
     }
   this.credentialService.assetCredential(this.assetCredentialCallback,payload,this.showNewCredentialFields)
  }

  assetCredentialCallback = (response:any)=> {
    this.spinnerService.hide();
    if(response.status === 200){
      this.data?.data?.setCallbackFunction(response);
      this.dialogService.closeDialog();
    }
  }
}
