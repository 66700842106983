<div class="vpn-modalbox" *ngIf="!createAndUpdateVpnLoader">
  <form  [formGroup]="addVpnFrm" (ngSubmit)="createTunnel()" autocomplete="off" >
    <h3>{{data.data.title}}</h3>
     <span><button mat-button class="close-icon btn" [mat-dialog-close]="true">
        <mat-icon>cancel</mat-icon></button> </span>

        <div class="inviteuser-field">
          
            <div class="input-box-container" >
                <mat-form-field   class="full-width border-field no-bg" appearance="outline">
                    <mat-label>Tunnel Name</mat-label>
                    <input formControlName="name" matInput [readonly]="isSpinner">
                  </mat-form-field>
            </div>
            <div class="input-box-container" >
                <mat-form-field class="full-width border-field no-bg" appearance="outline">
                    <mat-label>Tunnel Description</mat-label>
                    <input formControlName="description" matInput [readonly]="isSpinner">
                  </mat-form-field>
            </div>
            <div class="input-box-container" >
                <mat-form-field class="full-width border-field no-bg" appearance="outline">
                    <mat-label>Customer Gateway IP</mat-label>
                    <input formControlName="customer_gw_ip" matInput [readonly]="isSpinner">
                  </mat-form-field>
            </div>
            <!-- <div class="input-box-container" >
              <mat-form-field class="full-width border-field no-bg" appearance="outline">
                  <mat-label>Customer Private IP</mat-label>
                  <input formControlName="customer_private_ip" matInput [readonly]="isSpinner">
                </mat-form-field>
          </div> -->
            <div class="input-box-container" >
                <mat-form-field class="full-width border-field no-bg" appearance="outline">
                    <mat-label>Customer CIDR Block</mat-label>
                    <input formControlName="customer_cidr" matInput [readonly]="isSpinner">
                  </mat-form-field>
                  <p class="info-note"><mat-icon>info</mat-icon> Add multiple CIDR block using comma (,).</p>
                  <!-- <p class="info-note error-note" *ngIf="false"><mat-icon>info</mat-icon>Lorem ipsum dolor sit amet consectetur. </p> -->
            </div>
        </div>
        <div class="inviteuser-btn">
          <div *ngIf="isSpinner" class="loader-btn" [ngClass]="{isSpinner: 'loader-btn disabled'}">
          <span class="btn-loader spin">
            <img src="../../../../../../assets/images/vpn-loader.svg" />
          </span>
          </div>
          <button *ngIf="!isSpinner && data.data.action=='add'" [disabled]="addVpnFrm?.invalid" #myButton  class="btn btn-primary">
              Create Tunnel
          </button>
          <button *ngIf="data.data.action=='edit'" class="btn btn-lightblack-outline" [mat-dialog-close]="true">Cancel</button>
            <button *ngIf="data.data.action=='edit'" class="btn btn-blue" [disabled]="!addVpnFrm.dirty||addVpnFrm.invalid" >Save</button>
          
          

    </div>
  </form>
</div>

<!-- Tunnel Successfully Created popup-->
 
<div class="vpn-modalbox vpn-modalbox-readonly tunnel" *ngIf="createAndUpdateVpnLoader">
    <img src="../../../../../../assets/images/Capa_1.svg"  alt="">
    <h3>Tunnel Successfully Created</h3> 
          <div class="VPN-item">   
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>SPARK VPN Public IP</mat-label>
              <input matInput  value="{{vpndetails.spark_vpn_public_id}}" readonly>
              <button mat-icon-button matSuffix (click)="copyToClipboard(vpndetails?.spark_vpn_public_id)">
                <mat-icon [ngClass]="{ 'copied': isCopied }">content_copy</mat-icon>
              </button>
              
            </mat-form-field>
          </div> 
          <div class="VPN-item" >   
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>SPARK Subnet</mat-label>
              <input matInput  value="{{vpndetails.spark_subnet}}" readonly>
              <button mat-icon-button matSuffix (click)="copyToClipboard(vpndetails?.spark_subnet)">
                <mat-icon [ngClass]="{ 'copied': isCopied }">content_copy</mat-icon>
              </button>
            </mat-form-field>
          </div> 
          <div class="VPN-item" >   
            <mat-form-field class="example-full-width" appearance="outline">
              <mat-label>IKE Key</mat-label>
              <input matInput *ngIf="hide"  value="{{vpndetails.ike_key}}" readonly type="password">
              <input matInput *ngIf="!hide" value="{{vpndetails.ike_key}}" readonly type="text">
              <button mat-icon-button matSuffix (click)="copyToClipboard(vpndetails?.ike_key)">
                <mat-icon [ngClass]="{ 'copied': isCopied }">content_copy</mat-icon>
              </button>
              <mat-icon matSuffix (click)="hide = !hide" class="hide-icon">{{hide ? 'visibility_off' :
                'visibility'}}</mat-icon>
            </mat-form-field>
          </div> 
          <!-- <p class="note-text"><span>Loreum Ipsum</span> Lorem ipsum dolor sit amet consectetur.</p> -->
    <div class="vpn-btns">
        <button #myButton  class="btn btn-primary" (click)="addAnotherVPN()">Add Another VPN
        </button>
        <button #myButton  class="btn btn-outline" [mat-dialog-close]="true">Close
        </button>
    </div>
</div>