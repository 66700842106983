<div class="create-role-modalbox">
    <h3>Create Role</h3>
    <p class="sub-text">Name the title of the role you would like to create</p>
    <form [formGroup]="roleNameInput">
    <div class="createrole-field">
        <div class="createrole-input">
            <mat-form-field class="example-full-width border-field no-bg" appearance="outline">
                <mat-label>Role Name</mat-label>
                <input matInput placeholder="Role Name" #roleInput formControlName="role_name" maxlength="64">
                <mat-error *ngFor="let validation of validation_messages.role_name">
                    <mat-error class="error-message"
                        *ngIf="roleNameInput.get('role_name')?.hasError(validation.type) && (roleNameInput.get('role_name')?.dirty || roleNameInput.get('first_name')?.touched)">
                        {{validation.message}}</mat-error>
                </mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="defaultRoles?.length"  class="example-full-width border-field no-bg" appearance="outline">
                <mat-label>Clone Role {{selectedRole?.name}}</mat-label>
                <mat-select formControlName="roleType">
                    <div class="scroll select-credentials">
                        <mat-option *ngFor="let role of defaultRoles" value="{{role.id}}">{{role.name}}</mat-option>

                    </div>
                </mat-select>
              </mat-form-field>
        </div>
        <!-- <div class="createrole-btn">
            <button mat-raised-button [disabled]="!roleNameInput.valid" color="primary" (click)="createRole(roleInput.value.trim())">Create
                Role</button>
        </div> -->
        <div class="renam-btns">
            <button (click)="createRole(roleInput.value.trim())" mat-raised-button class="btn btn-blue" [disabled]="!roleNameInput.valid">Create Role</button>
            <button (click)="cancelRename()" mat-raised-button class="btn btn-white">Cancel</button>
        </div>
    </div>
</form>
</div>