export interface ITreeView {
  label?: string;
  id?: string | number;
  data?: ITreeView[] | null;
  selected?: boolean;
  indeterminate?: boolean;
  parent?: ITreeView | null;
  lavel?: number
  name?: string;
  type?: string;
}
export interface IApply {
  firstLevel?:ITreeView[],
  secondLevel?: ITreeView[],
  isFirstLevelAllSelected?: boolean,
  isSecondLevelAllSelected?: boolean
}

/**
 * Node for to-do item
 */
 export class TodoItemNode {
    public children: TodoItemNode[];
    public item: {
      label: string,
      id: number
    };
    constructor(item?: {label: string, id: number} | undefined, children?: TodoItemNode[]) {
        this.children = children ?? [];
        this.item = item ?? {label: '', id: 0};
    }
  }
  
  /** Flat to-do item node with expandable and level information */
  export class TodoItemFlatNode {
    item: {
      id: number,
      label: string
    };
    level: number;
    expandable: boolean;
    children ?: any;
    constructor(item?: {id: number, label: string}, level?: number, expandable?: boolean, children?: any) {
        this.item = item ?? {label: '', id: 0};
        this.level = level ?? 0;
        this.expandable = expandable ?? false;
        this.children = children ?? [];
    }
  }

  export interface TreeJson {
    label: string,
    id: number,
    data?: TreeJson[]
  }

  export const REGION_TREE_DATA: TreeJson[] = [
    {
        label: 'Region',
        id: 1,
        data: [
            {label: 'Country1', id: 2},
            {label: 'Country2', id: 3},
            {label: 'Country3', id: 4}
        ]
    },
    {
        label: 'Region2',
        id: 5,
        data: [
            {label: 'Country', id: 6},
            {label: 'Country2', id: 7},
            {label: 'Country3', id: 8}
        ]
    }
]

export const DEVICE_TREE_DATA: TreeJson[] = [
  {
      label: 'Device',
      id: 1,
      data: [
          {label: 'Switch', id: 2},
          {label: 'Router', id: 3},
          {label: 'Hub', id: 4}
      ]
  },
  {
      label: 'Router',
      id: 5,
      data: [
          {label: 'Country', id: 6},
          {label: 'Country2', id: 7},
          {label: 'Country3', id: 8}
      ]
  }
]


export interface IFilterFlags{
  "assignedRegion"?: boolean;
  "deviceType"?: boolean;
  "vendor"?: boolean;
  "roles"?: boolean,
  "affectedDevice"?: boolean,
  "cve"?: boolean,
  "severity"?: boolean,
  "severitySort"?: boolean,
  "assetsName"?: boolean,
  "assetsType"?: boolean
}