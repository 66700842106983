import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomDailog } from 'src/app/modules/shared/model/Dialog.model';
import { DialogService } from 'src/app/modules/shared/services/dialog.service';

@Component({
  selector: 'app-user-delete-component',
  templateUrl: './user-delete-component.component.html',
  styleUrls: ['./user-delete-component.component.scss']
})
export class UserDeleteComponentComponent {
  content: any;
  title: any;
  isTrue: any;
  menuItemData:any;


  constructor(@Inject(MAT_DIALOG_DATA) public data: CustomDailog,
    public dialogRef: MatDialogRef<UserDeleteComponentComponent>,
    public dialogService: DialogService) {
    this.title = data?.data?.title;
    this.content = data?.data?.content;
    this.menuItemData=data?.data?.menuData
    this.isTrue = this.containsReactivate();
  }

  containsReactivate(): boolean {
    return this.content?.toLowerCase().includes("reactivate");
  }

  onClick(value: boolean): void {
    // this.dialogRef.close(value)
    this.data?.data?.setCallbackFunction(value);
    this.dialogService.closeDialog();
  }

}
