import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomDailog } from 'src/app/modules/shared/model/Dialog.model';
import { DialogService } from 'src/app/modules/shared/services/dialog.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: CustomDailog,
  private dialogService: DialogService) { }

  ngOnInit(): void {
  }
  updateUser(){
    this.data?.data?.getUserListCallback(this.data?.data?.user);
    this.dialogService.closeDialog();
  }
}
