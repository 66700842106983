<div class="account_details">
    <div class="d-flex">
        <div class="half-width ">
            <div class="profile_details">
                <div class="full-width pb_3">
                    <p class="label_object">License level being used</p>
                    <p class="label_value">ABC Company Pvt Ltd</p>
                </div>
                <div class="full-width pb_3">
                    <p class="label_object">User Role</p>
                    <p class="label_value">Scan User</p>
                </div>
                <div class="full-width pb_3">
                    <p class="label_object">Reporting to</p>
                    <p class="label_value"><span>Robert</span> | <span> {{acoutDetail.email}} </span> |  <span>abc.com</span> </p>
                </div>
                <div class="d-flex pb_3">
                    <div class="half-width ">
                        <p class="label_object">Location & time-zome</p>
                        <p class="label_value">New York, NY, USA (GMT-4)</p>
                    </div>
                    <div class="half-width ">
                        <p class="label_object">Language</p>
                        <p class="label_value">English</p>
                    </div>
                </div>
                <div class="full-width ">
                    <p class="label_object">Last Scan</p>
                    <p class="label_value">Oct 26, 2022 | 16:23:36</p>
                </div> 
            </div>
        </div>
        <div class="half-width account_details_right pl_5 ">
            <div class="profile_details">
                <div class="full-width pb_3">
                    <p class="label_object">Saved SSH address details</p>
                    <p class="label_value"><span>25 Oct, 2022</span> | <span>Assets: 20</span></p>
                    <p class="label_value"><span>25 Oct, 2022</span> | <span>Assets: 20</span></p>
                    <p class="label_value"><span>25 Oct, 2022</span> | <span>Assets: 20</span></p>
                    <p class="label_value"><span>25 Oct, 2022</span> | <span>Assets: 20</span></p>
                    <p class="label_value"><span>25 Oct, 2022</span> | <span>Assets: 20</span></p>
                    <p class="label_value"><span>25 Oct, 2022</span> | <span>Assets: 20</span></p>
                    <p class="label_value"><span>25 Oct, 2022</span> | <span>Assets: 20</span></p>
                </div>
            </div>
        </div>
    </div>
    
</div>
