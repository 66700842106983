import { Component, OnInit, Inject, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomDailog } from 'src/app/modules/shared/model/Dialog.model';
import { IInviteUserResponse, InviteUserMsg, RoleItem, validation_messages } from '../model/user.model';
import { SnackbarService } from 'src/app/modules/shared/services/snackbar.service';
import { SpinnerService } from 'src/app/modules/shared/services/spinner.service';
import { UserManagementService } from 'src/app/modules/shared/services/user-management.service';
import { CONSTANT, EMAIL_ALREADY_REGISTERED, EMAIL_SUCCESS_REGISTERED } from 'src/app/modules/shared/constants/constant-data';
import { DialogService } from 'src/app/modules/shared/services/dialog.service';
import { MessageServiceService } from 'src/app/modules/shared/services/message-service.service';
import { IToastrOptions } from 'src/app/modules/shared/model/IMessage.model';

@Component({
  selector: 'app-users-invite',
  templateUrl: './invite-users.component.html',
  styleUrls: ['./invite-users.component.scss']
})
export class InviteUsersComponent implements OnInit {
  readOnlyRole: RoleItem = {};
  inviteUserList: string[] = [];
  validationMessages: validation_messages[] = [];
  userId = '';
  randomBackgroundcolor:string[]=[];
  fixedColors: string[] = ['#F6C055', '#FF9F81', '#769BFA', '#77C771', '#DBD301', '#00000040','#F3931B','#EC8F91','#FD6165'];
  isSpinner: boolean = false;
  isFormDisabled: boolean = false;
  @ViewChild('myButton') myButton!: ElementRef;

  constructor(@Inject(MAT_DIALOG_DATA) public data: CustomDailog,
  private spinnerService: SpinnerService,
  private snackbarService: SnackbarService,
  private userManagementService: UserManagementService,
  private dialogService: DialogService,
  private messageService:  MessageServiceService,
  private renderer: Renderer2
  ) {
    this.readOnlyRole = this.data?.data?.rolesList.find((role: RoleItem)=>{
      return role.name==='Read-Only';
    });
   
  }

  ngOnInit(): void {

  }

  inviteUser(): void {
  //  this.spinnerService.show();
  this.isSpinner = true;
  this.restrictFormAccess(true)
   const roleId = this.readOnlyRole?.id;
   this.userManagementService.inviteUser(this.inviteUserCallback,roleId , this.inviteUserList);
  }
  inviteUserCallback = (response: IInviteUserResponse | any) => {
    this.dialogService.closeDialog();
    // this.spinnerService.hide();
    this.isSpinner = false;
    this.restrictFormAccess(false)
    if(this.data.callBack){
      this.data.callBack(response.data);
    }
    const snakMsg: IToastrOptions = {}
    snakMsg.timeOut = 10000;
    if (response.status === 200) {
      this.inviteUserList = [];
      let failedEmails: string[] | any = [];
      let successedEmails: string[] | any  = [];
      if(response && response.data && response.data.length) {
        response.data.forEach((mainObj: any)=>{
          if(mainObj?.status === 'failed') {
            failedEmails.push(mainObj.email)
          }
          if(mainObj?.status === 'success') {
            successedEmails = mainObj.userlist.map((obj: any)=>{
              return obj.organization_email
            });
          }
        })
      }
      if (failedEmails?.length) {
        snakMsg.message = EMAIL_ALREADY_REGISTERED(failedEmails);
        this.messageService.showError(snakMsg);
        this.isSpinner= false;
        this.restrictFormAccess(false)
      }
      if (successedEmails?.length) {
        snakMsg.message = EMAIL_SUCCESS_REGISTERED(successedEmails);
        // snakMsg.message = response.message;
        this.messageService.showSuccess(snakMsg);
      }
    }
  }

  onClickOfEnter(event: any, userEmails: string): void {
    if (userEmails) {
      this.userId = userEmails;
      this.validationMessages.pop();
      if (userEmails.match(/;;/)) {
        this.validationMessages.push(validation_messages.INVALID_EMAIL);
        setTimeout(() => {
          this.validationMessages.splice(this.validationMessages.indexOf(validation_messages.INVALID_EMAIL), 1);
        }, 3000);
        return;
      }
      let emailList = userEmails.split(';').filter(email => email.trim() !== ''); 
      emailList.forEach(email => this.validateAndAddUsers(email.trim()));
    } else {
      this.validationMessages = [];
    }
  }

  randomcolorgenerator(){
    let maxVal = 0xFFFFFF;
    let randomNumber = Math.random() * maxVal;
    randomNumber = Math.floor(randomNumber);
    let randColor = randomNumber.toString(16);
    return '#'+randColor;
  }

  validateAndAddUsers(userEmail: string) {
    const emailPattern = new RegExp(CONSTANT.AUTHENTICATION.AUTH_EMAIL_PATTERN);
    const errMsg: IToastrOptions = {}
    if (emailPattern.test(userEmail)) {
      const lowercaseEmail = userEmail.toLowerCase();
      if (this.inviteUserList.map(email => email.toLowerCase()).includes(lowercaseEmail)) {
        errMsg.message = 'Duplicate user'
        this.messageService.showError(errMsg)
      } else {
        this.inviteUserList.push(userEmail);
        this.userId = '';
        this.randomBackgroundcolor.push(this.getColor(this.randomBackgroundcolor.length));
      }

    } 
    else {
      errMsg.message = 'Invalid email'
      this.messageService.showError(errMsg)
    }

  }

  removeUser(user: string): void {
    let index = this.inviteUserList.findIndex(x => x == user);
    this.inviteUserList.splice(index, 1);
  }
  restrictFormAccess(flag: boolean): void {
    this.isFormDisabled = flag;
    if (flag) {
        this.renderer.setStyle(this.myButton.nativeElement, 'pointer-events', 'none');
        this.renderer.setStyle(this.myButton.nativeElement, 'opacity', '1');
    } else {
        this.renderer.removeStyle(this.myButton.nativeElement, 'pointer-events');
        this.renderer.setStyle(this.myButton.nativeElement, 'opacity', '0.5');
    }
}
getColor(index: number): string {
  return this.fixedColors[index % this.fixedColors.length];
}

}