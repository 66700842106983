import { Injectable } from '@angular/core';
import { ApiService, IAPIDetails } from './api.service';
import { ICreateUpdateDeleteVpnPayLoad } from '../../admin/components/vpn/vpn..models';
import { CONFIG } from '../constants/config';
import { END_POINTS } from '../constants/endpoints';

@Injectable({
  providedIn: 'root'
})
export class VpnService {

  constructor(private apiService: ApiService) {

  }

  public delete(tunnel_id: any | null = null, action: string | null = null, callBack: Function){
    const apiDetails: IAPIDetails | any = {
      url: [],
      req: [],
      method: action ? action : CONFIG.METHOD.DELETE,
      responseType: CONFIG.RESPONSE_TYPE.NONE,
      callBack: callBack
    }
    
    apiDetails.req=null;
    //apiDetails.req = payload;
    apiDetails.url = END_POINTS.CREATE_UPDATE_VPN;
    
    apiDetails.url =  apiDetails.url + '/' + tunnel_id;
    
    this.apiService.callAPI(apiDetails);

  }

  public createUpdateVpn(payload: ICreateUpdateDeleteVpnPayLoad | null = null, action: string | null = null, callBack: Function) {
    const apiDetails: IAPIDetails | any = {
      url: [],
      req: [],
      method: action ? action : CONFIG.METHOD.GET,
      responseType: CONFIG.RESPONSE_TYPE.NONE,
      callBack: callBack
    }
    
    
    apiDetails.req = payload;
    apiDetails.url = END_POINTS.CREATE_UPDATE_VPN;
    if(payload && action && (action === CONFIG.METHOD.GET)) {
      apiDetails.url =  apiDetails.url + '/' + payload.tunnel_id;
    }
    this.apiService.callAPI(apiDetails);
  }

  public pauseVpn(payload: ICreateUpdateDeleteVpnPayLoad | null = null, action: string | null = null, callBack: Function){
    const apiDetails: IAPIDetails | any = {
      url: [],
      req: [],
      method: action ? action : CONFIG.METHOD.GET,
      responseType: CONFIG.RESPONSE_TYPE.NONE,
      callBack: callBack
    }
    
    
    apiDetails.req = payload;
    apiDetails.url = END_POINTS.CONNECTION_STATUS;
    if(payload && action && (action === CONFIG.METHOD.GET)) {
      apiDetails.url =  apiDetails.url + '/' + payload.tunnel_id;
    }
    this.apiService.callAPI(apiDetails);

  }

  public testVpn(payload: any | null = null,action: string | null = null,callBack: Function) {
    const apiDetails: IAPIDetails | any = {
      url: [],
      req: [],
      method: action ? action : CONFIG.METHOD.GET,
      responseType: CONFIG.RESPONSE_TYPE.NONE,
      callBack: callBack
    }
    apiDetails.req = payload;
    apiDetails.url = END_POINTS.TEST_VPN;
    if(payload && action && (action === CONFIG.METHOD.GET)) {
      apiDetails.url =  apiDetails.url + '/' + payload.tunnel_id;
    }
    this.apiService.callAPI(apiDetails);

  }
   
}
