import { Component } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';


@Component({
    selector: 'app-severity',
    templateUrl: './severity-vul.component.html',
    styleUrls: ['./severity-vul.component.scss']
  })
  export class SeverityVulComponent {
    public params!: ICellRendererParams;
  
    agInit(params: ICellRendererParams): void {
      this.params = params;
    }
  
  }