import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomDailog } from 'src/app/modules/shared/model/Dialog.model';
import { DialogService } from 'src/app/modules/shared/services/dialog.service';

@Component({
  selector: 'app-whitelist-reason',
  templateUrl: './whitelist-reason.component.html',
  styleUrls: ['./whitelist-reason.component.scss']
})
export class WhitelistReasonComponent {
  public whitelistForm!: FormGroup;
  Parameter: any;
  regionData!: string;
  isButtonDisabled: boolean = true;

  constructor(private dialogRef: MatDialogRef<WhitelistReasonComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CustomDailog,
    private dialogService: DialogService,
    private fb: FormBuilder) {
     this.Parameter = data?.data?.Parameter.length > 60 ? data?.data?.Parameter.slice(0, 57) + '...' : data?.data?.Parameter;


    this.whitelistForm = this.fb.group({
      regionData:new FormControl(null, Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(120),])),
      rememberMe: new FormControl(false, Validators.requiredTrue),
    });

  }

  messageName(value: string): void {
    const result = {
      click: true,
      value: value.trim()
    };
    // this.dialogRef.close(result);
    this.data?.data?.setCallbackFunction(result);
    this.dialogService.closeDialog();
  }
  
  checkTextField(event: any) {
    if(event.target.value.length > 7 ) {
      this.isButtonDisabled = false;
    } else {
      this.isButtonDisabled = true;
    }
  }

}
