<div class="admin_section out-layout cred-management-page ">
    <div class="cred-main-section">
        <div *ngIf="!(isNewUser && (isGlobalCredential || isLocalCredential))" class="role-search white-box p0">
            <div class="role-search-header" *ngIf="tabLoaded">
                <button class="btn add-role" *ngIf="hasPermission(permissionProps.LOCAL_CREDENTIAL) || hasPermission(permissionProps.GLOBAL_CREDENTIAL)" [disabled]="!tabApiLoaded" [matMenuTriggerFor]="menu"><mat-icon>add</mat-icon></button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item  *ngIf="hasPermission(permissionProps.LOCAL_CREDENTIAL)" (click)="createLocalCredential()">Create Local Credential</button>
                  <button mat-menu-item *ngIf="hasPermission(permissionProps.GLOBAL_CREDENTIAL)"  (click)="createGlobalCredential()">Create Global Credential</button>
                </mat-menu>
                <div class="search-bar">
                    <mat-form-field appearance="fill">
                        <mat-label>Search Credentials</mat-label>
                        <input matInput placeholder="" [(ngModel)]="searchRole"  autocomplete="off" autocomplete="off"
                            type="text"> 
                        <mat-icon class='search-icon' matPrefix>search</mat-icon>
                    </mat-form-field>
                </div>
            </div>
            <div class="role-data-section cred-data white-box p0" *ngIf="!tabLoaded">
              <ngx-skeleton-loader count="18">
              </ngx-skeleton-loader>
              <!-- <div class="control-panel-overlay"></div> -->
          </div>
            <div class="cred-search-list scroll" *ngIf="tabLoaded">
                <div [ngClass]="{'active': Credential.name === currentcredentional.name, 'disable_row': !tabApiLoaded}"  (click)="checkTabChange(currentcredentional.id,currentcredentional.modify)"
                    *ngFor="let currentcredentional of credentials_List | search : searchRole; let i = index " class="cred-label">
                    <label class="labelHeading text_ellipsis" [options]="toolTipOption"  tooltip="{{currentcredentional.name}}">{{currentcredentional.name}}</label>
                    <span *ngIf="currentcredentional.global_cred" class="global-icon">
                      <img  *ngIf="Credential.name !== currentcredentional.name" src="../../../../../assets/images/globe_icon.svg">
                       <img *ngIf="Credential.name === currentcredentional.name" src="../../../../../assets/images/globe_white.svg">
                    </span>
                </div>
                <div *ngIf="credentials_List.length === 0">
                 <p class="no-data">No data available to show</p>
                </div>
            </div>
        </div>
        <div  class="cred-data-section cred-data white-box p0">
          <div *ngIf="!tabApiLoaded">
            <ngx-skeleton-loader count="18">
            </ngx-skeleton-loader>
        </div>
        <div *ngIf="tabApiLoaded" class="cred-data" >
            <div *ngIf="credentials_List.length > 0 || isLocalCredential || isGlobalCredential" class="cred-data-header">
                <h3 *ngIf="!isLocalCredential && !isGlobalCredential">{{Credential.name}}</h3>
                <h3 *ngIf="isLocalCredential">Create Local Credential</h3>
                <h3 *ngIf="isGlobalCredential">Create Global Credential</h3>
                <div *ngIf="!isLocalCredential && !isGlobalCredential" class="role-btns">
                  <div *ngIf="!isEdit" class="credential-btns">
                    <button  class="btn btn-outline" (click)="editCredentional(Credential.credential_type_id)">Edit</button>
                    <button class="btn btn-tartorange-outline" (click)="deleteCredentional(Credential)">Delete</button>
                  </div>
                  <div *ngIf="isEdit" class="credential-btns">
                    <button class="btn btn-outline" [disabled]="credentialForm.invalid || (isGlobalCredential && selectedRoleIds.length === 0)"  (click)="saveCredentional(Credential.id)">Save</button>
                    <button class="btn btn-outline"  (click)="cancelCredentional(Credential.id)">Cancel</button>
                  </div>
                </div>
                <div *ngIf="isGlobalCredential || isLocalCredential" class="role-btns">
                  <button class="btn btn-outline"  (click)="cancelADDCredentional()">Cancel</button>
                  <button class="btn btn-outline"
                  [disabled]="credentialForm.invalid"
                  (click)="saveAddCredentional()">Save</button>
                </div>
            </div>
            <div *ngIf="credentials_List.length > 0  || isLocalCredential || isGlobalCredential" class="creden_management">
              <form [formGroup]="credentialForm" autocomplete="off" [ngClass]="{'disabled': !isEdit}" class="cred-form">
                <div class="group_container">
                  <div class="form-container group_sub_container">
                    <div class="input-box-container">
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Credential Name</mat-label>
                        <input formControlName="credential_name" matInput>
                        <!-- <mat-error *ngFor="let validation of validation_messages.credential_name">
                          <mat-error class="error-message"
                            *ngIf="credentialForm.get('credential_name')?.hasError(validation.type) && (credentialForm.get('credential_name')?.dirty || credentialForm.get('credential_name')?.touched)">
                            {{validation.message}}</mat-error>
                        </mat-error> -->
                      </mat-form-field>
                    </div>
                    <div *ngIf="!showApiTokenInput" class="input-box-container">
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>User</mat-label>
                        <input formControlName="user" matInput>
                        <!-- <mat-error *ngFor="let validation of validation_messages.user">
                          <mat-error class="error-message"
                            *ngIf="credentialForm.get('user')?.hasError(validation.type) && (credentialForm.get('user')?.dirty || credentialForm.get('user')?.touched)">
                            {{validation.message}}</mat-error>
                        </mat-error> -->
                      </mat-form-field>
                    </div>
                    <div  class="input-box-container">
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Description</mat-label>
                        <input formControlName="description"  matInput>
                        <!-- <mat-error *ngFor="let validation of validation_messages.description">
                          <mat-error class="error-message"
                            *ngIf="credentialForm.get('description')?.hasError(validation.type) && (credentialForm.get('description')?.dirty || credentialForm.get('description')?.touched)">
                            {{validation.message}}</mat-error>
                        </mat-error> -->
                      </mat-form-field>
                    </div>
                    <!-- <div class="input-box-container">
                    </div> -->
                    <div class="input-box-container" *ngIf="showPasswordInput">
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Password</mat-label>
                        <input formControlName="password" matInput [type]="hide ? 'password' : 'text'" autocomplete="new-password">
                        <mat-icon *ngIf="isEdit || isLocalCredential || isGlobalCredential" matSuffix (click)="hide = !hide" class="hide-icon">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        <!-- <mat-error *ngFor="let validation of validation_messages.password">
                          <mat-error class="error-message"
                                      *ngIf="credentialForm.get('password')?.hasError(validation.type) && (credentialForm.get('password')?.dirty || credentialForm.get('password')?.touched)">
                              {{validation.message}}
                          </mat-error>
                      </mat-error> -->
                      </mat-form-field>
                    </div>
                    <div class="input-box-container"  *ngIf="showPrivateKeyInput">
                      <!-- <div>
                        <input  class="file-upload" formControlName="privateFile"/>
                      </div> -->
                      <div class="uploaded-files-section"> 
                        <div class="privatefile-list">
                          <p class="privatekey-text">Upload PPK File</p>
                        </div>
                      </div>
                      <!-- <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Private Files</mat-label>
                        <input formControlName="privateFile" matInput type="text">
                      </mat-form-field> -->
                    </div>
                    <div class="input-box-container p-bottom" *ngIf="showPasswordInput || showPrivateKeyInput">
                      <div class="exec-field">
                        <P>Exec Password </P>
                        <div class="tool_switch exec-tool_switch right_ele">
                          <mat-slide-toggle formControlName="exec_toogle"></mat-slide-toggle>
                      </div>
                      </div>
                      <div class="input-box-container input-box-container-pass" [ngClass]="{'field-hide':execPassword === false}"  *ngIf="showPasswordInput || showPrivateKeyInput">
                        <mat-form-field class="full-width" appearance="outline">
                          <mat-label>Exec Password</mat-label>
                          <input formControlName="enable_password" matInput [type]="exec_hide ? 'password' : 'text'">
                          <mat-icon matSuffix (click)="exec_hide = !exec_hide" class="hide-icon">{{exec_hide ? 'visibility_off' :
                            'visibility'}}</mat-icon>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="input-box-container" [ngClass]="{'field-hide': execPassword}" *ngIf="showPasswordInput">
                      <div class="exec-field">
                        <P class="sub-text">Auto Assign 
                          <span>This will auto-assign Login Password as Exec Password when missing.</span>
                        </P>
                        <div class="tool_switch exec-tool_switch right_ele">
                          <mat-slide-toggle formControlName="auto_assign"></mat-slide-toggle>
                      </div>
                      </div>
                    </div>
                    <div *ngIf="isGlobalCredential || (isEdit && edit_global_cred)" class="input-box-container">
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-select panelClass="cred-drop-width" placeholder="Select Role" [formControl]="Roles" multiple>
                          <mat-select-trigger>
                            <mat-chip-list>
                              <mat-chip *ngFor="let Role of selectedRoles.slice(0, 2)"  [removable]="Role !== 'Admin'" (removed)="remove(Role)">
                                <span [options]="toolTipOption" [tooltip]="Role"  class="text_ellipsis chip-value">{{Role}}</span>
                                <mat-icon *ngIf="Role !== 'Admin'" matChipRemove>cancel</mat-icon>
                              </mat-chip>
                              <span *ngIf="selectedRoles.length > 1 && (selectedRoles.length - 2) !== 0" class="more-align">
                                +{{selectedRoles.length - 2}} more
                              </span>
                            </mat-chip-list>
                          </mat-select-trigger>
                          <div class="scroll select-credentials">
                            <mat-option *ngFor="let role of org_roles" [value]="role.name"  [disabled]="role.name === 'Admin' && selectedRoles.includes('Admin')">
                              <span [options]="toolTipOption" [tooltip]="role.name" class="text_ellipsis ">{{role.name}}</span></mat-option>
                        </div>
                        </mat-select>
                      </mat-form-field>          
                    </div>
                    <div class="input-box-container" *ngIf="showApiTokenInput">
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>API Token</mat-label>
                        <input formControlName="apitoken" matInput>
                        <!-- <mat-error *ngFor="let validation of validation_messages.apitoken">
                          <mat-error class="error-message"
                            *ngIf="credentialForm.get('apitoken')?.hasError(validation.type) && (credentialForm.get('apitoken')?.dirty || credentialForm.get('apitoken')?.touched)">
                            {{validation.message}}</mat-error>
                        </mat-error> -->
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="label-container group-sub-container" [ngClass]="{'password-label-container':!(isGlobalCredential || isLocalCredential)}">
                    <div class="radio-btn-section ssh-section" [ngClass]="{'radio-btn-section-first':isGlobalCredential || isLocalCredential}">
                      <mat-radio-group *ngIf="isGlobalCredential || isLocalCredential" formControlName="connectionType">
                        <mat-radio-button *ngFor="let item of credentialTypes" [value]="item.key">{{item.value}}</mat-radio-button>
                      </mat-radio-group>
                    </div>
                    <div *ngIf="!showApiTokenInput" class="radio-btn-section" [ngClass]="{'ssh_radio-buttonhide':!(isGlobalCredential || isLocalCredential)}">
                      <p>Password Type</p>
                      <mat-radio-group  formControlName="passwordType">
                        <mat-radio-button *ngFor="let pt of passwordTypes" [value]="pt.key">{{pt.value}}</mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div *ngIf="credentials_List.length === 0 && !isGlobalCredential && !isLocalCredential">
              <p class="no-data">No data available to show</p>
             </div> 
          </div>      
</div>
</div>
</div>