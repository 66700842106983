import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CONSTANT } from 'src/app/modules/shared/constants/constant-data';
import { CustomDailog } from 'src/app/modules/shared/model/Dialog.model';
import { IUserDetail } from 'src/app/modules/shared/model/profile/profile';
import { DialogService } from 'src/app/modules/shared/services/dialog.service';
import { ProfileService } from 'src/app/modules/shared/services/profile.service';
@Component({
  selector: 'app-delete-device-popup',
  templateUrl: './delete-device-popup.component.html',
  styleUrls: ['./delete-device-popup.component.scss']
})
export class DeleteDevicePopupComponent {
  deleteDeviceForm!: FormGroup;
  isButtonDisabled!: boolean;
  deviceId: any;
  hide = true;
  userProfile: IUserDetail = {};
  displayedColumns: string[] = ['number','scanName','frequency', 'schedule', 'time'];
  dataSource = [
    { number: '1',scanName: '07SW5476E', frequency: 'Switch', vendor: 'CISCO', time:'time \ Country \ State' },
    { number: '1',scanName: '07SW5476E', frequency: 'Switch', vendor: 'CISCO', time:'time \ Country \ State' },
    { number: '1',scanName: '07SW5476E', frequency: 'Switch', vendor: 'CISCO', time:'time \ Country \ State' },
    { number: '1',scanName: '07SW5476E', frequency: 'Switch', vendor: 'CISCO', time:'time \ Country \ State' },
    { number: '1',scanName: '07SW5476E', frequency: 'Switch', vendor: 'CISCO', time:'time \ Country \ State' },
    { number: '1',scanName: '07SW5476E', frequency: 'Switch', vendor: 'CISCO', time:'time \ Country \ State' },
    { number: '1',scanName: '07SW5476E', frequency: 'Switch', vendor: 'CISCO', time:'time \ Country \ State' },
    { number: '1',scanName: '07SW5476E', frequency: 'Switch', vendor: 'CISCO', time:'time \ Country \ State' },
    { number: '1',scanName: '07SW5476E', frequency: 'Switch', vendor: 'CISCO', time:'time \ Country \ State' },
    { number: '1',scanName: '07SW5476E', frequency: 'Switch', vendor: 'CISCO', time:'Region \ Country \ State' },
  ];
  constructor(
    private fb: FormBuilder, 
    private dialogRef: MatDialogRef<any>,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: CustomDailog,
    private profileService: ProfileService) {

    }
  ngOnInit(): void {
    this.userProfile = this.profileService.getUserDetails();
    this.createForm();
  }

  validation_messages = {
    password: [
      { type: this.userProfile.sso_enabled ? '' : 'required', message: CONSTANT.AUTHENTICATION.LOG_IN_PASSWORD_REQUIRED },
    ],
    reason: [
      { type: 'required', message: CONSTANT.WHITELIST_REASON.WHITELIST_REASON_REQUIRED },
      { type: 'minlength', min: 8, message: CONSTANT.WHITELIST_REASON.WHITELIST_REASON_MIN_LENGTH },
      { type: 'maxlength', max: 120, message: CONSTANT.WHITELIST_REASON.WHITELIST_REASON_MAX_LENGTH },
      { type: 'pattern', pattern: /^(?! )[A-Za-z0-9 ]*(?<! )$/, message: CONSTANT.WHITELIST_REASON.WHITELIST_PATTERN }]
  }
  createForm(): void {
    this.deleteDeviceForm = this.fb.group({
      password: new FormControl("", Validators.compose([
        this.userProfile.sso_enabled ? null : Validators.required,
      ])),
      reason: new FormControl("", Validators.compose([
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(120),
        Validators.pattern(/^(?! )[A-Za-z0-9 ]*(?<! )$/)
      ]))
    })
  }
  checkTextField(event: any) {
    const inputValue = event.target.value;
    if (inputValue.length >= 8 && inputValue.length <= 120 && /^(?! )[A-Za-z0-9 ]*(?<! )$/.test(inputValue)) {
      this.isButtonDisabled = false;
    } else {
      this.isButtonDisabled = true;
    }
  }

  confirm() {
    // this.dialogRef.close({'authValidation': !this.userProfile.sso_enabled, ...this.deleteDeviceForm.value});
    this.data?.data?.setCallbackFunction({'authValidation': !this.userProfile.sso_enabled, ...this.deleteDeviceForm.value});
    this.dialogService.closeDialog();
  }
}
