<div class="out-layout scroll new-device-section ">
    <div class="new-device-page ">
        <span *ngIf="currentSelectedDevice" class="region-set text_ellipsis ">{{currentSelectedDevice?.region_name}}</span>
        <div class="new-device-left">
            <div class="white-box mb_4" style="overflow:visible">
                <div class="chart-section cyber-resilience" [ngClass]="{'load-graph': isChartLoad}">
                    <!-- <div *ngIf="isChartLoad" class="loading-text">{{constant.LOADING}}</div> -->
                    <div class="control-panel-col-header">
                        <h1 class="box-label" *ngIf="title">{{title}}</h1>
                        <form *ngIf="false" class="date-range-section-filter" [formGroup]="datePickerRange">
                            <div class="date-field">
                                <label class="date-field-label">From: </label>
                                <mat-form-field>
                                    <mat-label>DD/MM/YYYY</mat-label>
                                    <input matInput [matDatepicker]="startDate" formControlName="startDate" (dateChange)="getCustomRangeData()" [min]="minimumDate" [max]="maximumDate">
                                    <mat-datepicker-toggle matIconSuffix [for]="startDate"></mat-datepicker-toggle>
                                    <mat-datepicker #startDate></mat-datepicker>
                                    <!-- <mat-error *ngIf="datePickerRange.get('startDate').hasError('matStartDateInvalid')">
                                        Invalid start date.
                                    </mat-error> -->
                                </mat-form-field>
                            </div>
                            <div class="date-field">
                                <label class="date-field-label">To: </label>
                                <mat-form-field>
                                    <mat-label>MM/dd/YYYY</mat-label>
                                    <input matInput [matDatepicker]="endDate" formControlName="endDate" (dateChange)="getCustomRangeData()" [min]="minimumDate" [max]="maximumDate" placeholder="MM/dd/YYYY">
                                    <mat-datepicker-toggle matIconSuffix [for]="endDate"></mat-datepicker-toggle>
                                    <mat-datepicker #endDate></mat-datepicker>
                                    <!-- <mat-error *ngIf="datePickerRange.get('endDate').hasError('matEndDateInvalid')">
                                        Invalid start date.
                                    </mat-error> -->
                                </mat-form-field>
                            </div>
                            <p class="date-err" *ngIf="datePickerRange.touched && datePickerRange.invalid">Please select valid start/end date.</p>
                        </form>
                        <!-- <div class="region-sort" *ngIf="monthFilter.length">
                            <button mat-button
                                [matMenuTriggerFor]="securityHistory">{{selectedMonthLabel}}<mat-icon>keyboard_arrow_down</mat-icon></button>
                            <mat-menu #securityHistory="matMenu" class="mat-menu-scroll">
                                <button (click)="filterMonth(month)" *ngFor="let month of monthFilter"
                                    mat-menu-item>{{month.name}}</button>
                                <button (click)="filterMonth({id: 'custom_range', name: 'Custom Date Range'})"
                                *ngIf="monthFilter && monthFilter.length" mat-menu-item>Custom date range</button>    
                            </mat-menu>
                        </div> -->
                        <div class="region-sort" *ngIf="monthFilter.length">
                            <!-- <button mat-button [matMenuTriggerFor]="securityHistory" class="btn-custom">
                              <label class="sort-title">{{ selectedMonthLabel }}</label>
                              <mat-icon>keyboard_arrow_down</mat-icon>
                            </button> -->
                            <mat-menu #securityHistory="matMenu" class="level-menu" (closed)="setDates()" [xPosition]="'before'">
                              <div class="inner-content" [formGroup]="datePickerRange">
                                <div class="date-range-container">
                                  <div class="d-flex full-width">
                                    <div class="half-width">
                                      <label>From:</label>
                                      <mat-form-field class="no-bg" appearance="outline" (click)="$event.stopPropagation()">
                                        <input matInput [matDatepicker]="startDate" formControlName="startDate" placeholder="Select Date" [max]="maxDate" (input)="onDateInput($event, 'startDate')">
                                        <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                                        <mat-datepicker #startDate></mat-datepicker>
                                      </mat-form-field>
                                    </div>
                                    <div class="half-width">
                                      <label>To:</label>
                                      <mat-form-field class="no-bg" appearance="outline" (click)="$event.stopPropagation()">
                                        <input matInput [matDatepicker]="endDate" formControlName="endDate" placeholder="Select Date" [max]="maxDate" (input)="onDateInput($event, 'endDate')">
                                        <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
                                        <mat-datepicker #endDate></mat-datepicker>
                                      </mat-form-field>
                                    </div>
                                  </div>
                                  <div class="btn-section">
                                    <button class="btn apply-btn" 
                                            (click)="applyDateRange()" 
                                            [disabled]="datePickerRange.invalid || datePickerRange.hasError('invalidRange')">
                                      Apply
                                    </button>
                                  </div>
                                </div>
                                <!-- <div class="info-text" *ngIf="datePickerRange.valid && !datePickerRange.hasError('invalidRange')">
                                  <mat-icon>info</mat-icon>
                                  <span class="info-note">You can select a minimum period of 7 days.</span>
                                </div> -->
                                <p class="date-err" *ngIf="datePickerRange.touched && datePickerRange.invalid">Please select a valid start/end date.</p>
                              </div>
                              
                              
                              
                              
                              <div class="menu-item-list">
                                <button (click)="filterMonth(month)" *ngFor="let month of monthFilter;let i = index" mat-menu-item class="list-item" [ngClass]="{'active': month.id === selectedMonth, 'even-child': i % 2 === 1}">{{ month.name }}</button>
                              </div>
                            </mat-menu>
                          </div>
                    </div>
                    <div class="date-range-section" [ngClass]="{'hide-date-drop': !chartApiDropdownData?.length}">
                        <p>Compare selection :</p>
                        <div class="region-sort range-dropdown">
                            <button mat-button [disabled]="hasSingleDateResponse"
                                [matMenuTriggerFor]="hasSingleDateResponse ? null :fromDropdown">{{fromSelectedData?.date_added}}
                                <mat-icon>keyboard_arrow_down</mat-icon>
                            </button>
                            <mat-menu  #fromDropdown="matMenu" class="range-list scan-list-menu mat-menu-scroll">
                                <button (click)="updateDate(data, 'from')" *ngFor="let data of chartApiDropdownData" mat-menu-item>{{data?.date_added}}</button>
                            </mat-menu>
                        </div>
                        <div class="region-sort range-dropdown">
                            <button mat-button [disabled]="hasSingleDateResponse"
                                [matMenuTriggerFor]="hasSingleDateResponse ? null : toDropdown">{{toSelectedData?.date_added}}
                                <mat-icon>keyboard_arrow_down</mat-icon>
                            </button>
                            <mat-menu #toDropdown="matMenu" class="range-list scan-list-menu mat-menu-scroll">
                                <button (click)="updateDate(data, 'to')" *ngFor="let data of chartApiDropdownData"
                                    mat-menu-item>{{data?.date_added}}</button>
                            </mat-menu>
                        </div>
                        
                        <button (click)="compareData()" [disabled]="validateDate() || hasSingleDateResponse"
                        class="compare-btn" mat-flat-button color="accent">Compare</button>
                    </div>
                    <div class="cyber-resilience">
                        <div class="canvas-loader" *ngIf="isChartLoad">
                            <div class="vertical-items">

                                <ngx-skeleton-loader *ngFor="let height of loaderHeights" count="1" appearance="circle" [theme]="{
                                    width: '20px',
                                    height: height,
                                    'border-radius': '0px',
                                }" class="vertical-bar">
                            </ngx-skeleton-loader>
                            </div>
                            <ngx-skeleton-loader count="1" class="horizontal-bar"></ngx-skeleton-loader>
                        </div>
                        <div *ngIf="showPopup" (click)="overlayClick()" class="window-over-lay"></div>
                        <div *ngIf="showPopup" [ngStyle]="{'left': position.left + 10 +'px', 'bottom':'18px' }"
                            class="cybertooltip-popup-section scroll">
                            <h5>Security Score on {{currentSelectedDateEvent.date_time | date:'d MMM, y'}}</h5>
                            <div class="cyber-score-list scroll">
                                <ul>
                                    <li *ngFor="let event of eventData">
                                        <div class="cyber-time">{{event.event_date| date:'HH:mm:ss'}}  - <span>{{event.current_score.toFixed(2)}}</span></div>
                                        <div *ngIf="event.score_difference < 0" class="cyber-arrow arrow-down-evt"><mat-icon>arrow_drop_down</mat-icon></div>
                                        <div *ngIf="event.score_difference > 0" class="cyber-arrow arrow-up-evt"><mat-icon>arrow_drop_up</mat-icon></div>
                                        <div *ngIf="event.score_difference === 0" class="cyber-arrow"></div>
                                        <div class="cyber-value">{{getPositiveValue(event.score_difference)?.toFixed(2)}}</div>
                                        <div class="cyber-status text_ellipsis" [title]="event.event_name">({{event.event_name}})</div>
                                    </li>
                                    <li *ngIf="eventData.length === 0 && !loadEvents">No Data</li>
                                </ul>
                                <div class="item" *ngIf="loadEvents">
                                    <ngx-skeleton-loader count="2" class="table-loader cyber-reilience-loader" [theme]="{
                                                width: '15%',
                                                'border-radius': '0',
                                                height: '15px',
                                                'margin-bottom': '5px'
                                                }"
                                        *ngFor="let item of [].constructor(8); let i = index">
                                    </ngx-skeleton-loader>
                                </div>
                            </div>
                        </div>
                        <div  style="height: 230px;" id="cyberResilience" #cyberResilience></div>
                    </div>
                    <div *ngIf="!chartApiData?.length && !isChartLoad" class="loading-text">No Data</div>
                </div>
            </div>
            <div class="white-box">
                <div class="device-perameter">
                    <div *ngIf="isDevicePerameterLoad" class="loading-text">{{constant.LOADING}}</div>
                    <div *ngIf="!devicePerameterData.length && !isDevicePerameterLoad" class="loading-text">No Data</div>
                    <div *ngIf="devicePerameterData?.length" class="main-device-perameter" [hidden]="isDevicePerameterLoad"  [ngClass]="{'single-response': hasSingleDateResponse  }">
                        <div class="device-header">
                            <div class="parameter">
                                PARAMETERS
                            </div>
                            <div  appSafariEllipsis class="device-start-date" [options]="toolTipOption" [tooltip]="fromSelectedData?.date_added">
                                {{getDateAndMonth(fromDiffSelectedData?.validDate)}}
                            </div>
                            <div  *ngIf="!hasSingleDateResponse" class="device-end-date" [options]="toolTipOption" [tooltip]="toSelectedData?.date_added">
                                {{getDateAndMonth(toDiffSelectedData?.validDate)}}
                            </div>
                        </div>
                        <div class="device-data-section scroll"> 
                            <div class="device-data" *ngFor="let deviceData of devicePerameterData">
                                <div class="parameter">
                                    <span appSafariEllipsis class="overflow text_ellipsis" [tooltip]="deviceData.parameter" [options]="toolTipOption">
                                        {{deviceData.parameter}}
                                    </span>
                                    <span *ngIf="deviceData.white_list.value" class="white-list-badge">
                                        Risk-Accepted
                                    </span>
                                </div>
                                <div class="device-start-date">
                                    <div [ngClass]="{'pass-item': deviceData.is_passed_1,'fail-item': !deviceData.is_passed_1 }"
                                        class="selected-list-item-right ">
                                        <mat-icon *ngIf="!deviceData.is_passed_1">close</mat-icon>
                                        <mat-icon *ngIf="deviceData.is_passed_1">check</mat-icon>
                                    </div>
                                </div>
                                <div *ngIf="!hasSingleDateResponse" class="device-end-date" >
                                    <div [ngClass]="{'pass-item': deviceData.is_passed_2,'fail-item': !deviceData.is_passed_2 }"
                                        class="selected-list-item-right ">
                                        <mat-icon *ngIf="!deviceData.is_passed_2">close</mat-icon>
                                        <mat-icon *ngIf="deviceData.is_passed_2">check</mat-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="new-device-right">
            <div class="white-box mb_4 config-diff-details">
                <div *ngIf="isCompare" class="loading-text">{{constant.LOADING}}</div>
                <div *ngIf="!isCompare">
                <div class="control-panel-col-header">
                    <h3>Configuration Difference ({{getDateAndMonth(fromDiffSelectedData?.validDate)}} -
                        {{getDateAndMonth(toDiffSelectedData?.validDate)}})</h3> 
                    <div class="score-details">
                        <!-- Security Score Change: <span  [ngClass]="{
                            'value-with-direction': true,
                            'high': securityScore > 0,
                            'low': securityScore <= 0
                          }">{{securityScore === 0 ?securityScore: getPositiveValue(securityScore)}}</span> -->
                          Security Score Change: <span class="score-with-direction vulnerabity-values"><mat-icon *ngIf=" securityScore > 0" class="low-val">call_made</mat-icon>
                            <mat-icon *ngIf="securityScore < 0" class="high-val">call_received</mat-icon>{{securityScore === 0 ?securityScore: getPositiveValue(securityScore)}}</span>
                    </div>    
                </div>
                <div class="config-diff-content-section">
                    <div  class="config-diff-content">
                        <span class="config-label">Configuration Level Changes</span>
                        <div class="config-diff-blocks">
                            <div class="config-diff-block">
                                <div>
                                <span class="value-with-direction vulnerabity-values"><mat-icon *ngIf=" totalCount > 0" class="low-val">call_made</mat-icon>{{totalCount}}</span>  
                                </div>
                                <div class="config-diff-block-label">All</div>
                            </div>
                            <div class="config-diff-block">
                                <div>
                                    <span class="value-with-direction vulnerabity-values"><mat-icon *ngIf=" addition > 0" class="low-val">call_made</mat-icon>{{addition}}</span>  

                                </div>
                                <div class="config-diff-block-label">Addition</div>
                            </div>
                            <div class="config-diff-block">
                                <div>
                                  <span class="value-with-direction vulnerabity-values"><mat-icon *ngIf="deletion > 0" class="high-val">call_received</mat-icon>{{deletion}}</span>
                                </div>
                                <div class="config-diff-block-label">Deletion</div>
                            </div>
                        </div>
                    </div>
                    <div  class="config-diff-content">
                        <span class="config-label">Vulnerability Level Changes</span>
                        <div class="config-diff-blocks">
                            <div class="config-diff-block">
                                <div>
                                 
                                    <span class="value-with-direction vulnerabity-values"><mat-icon *ngIf=" newVulnerabilities > 0" class="high-val">call_made</mat-icon>
                                        <mat-icon *ngIf="newVulnerabilities < 0" class="low-val">call_received</mat-icon>{{newVulnerabilities}}</span>
                                  </div>
                                <div class="config-diff-block-label">New</div>
                            </div>
                            <div class="config-diff-block">
                                <div><span class="value-with-direction vulnerabity-values"><mat-icon *ngIf="remediated > 0" class="low-val">call_made</mat-icon>{{remediated}}</span></div>
                                <div class="config-diff-block-label">Remediated</div>
                            </div>
                            <div class="config-diff-block">
                                <div >
                                    <span class="value-with-direction vulnerabity-values"><mat-icon *ngIf=" acceptedRisk > 0" class="high-val">call_made</mat-icon>
                                        <mat-icon *ngIf="acceptedRisk < 0" class="low-val">call_received</mat-icon>{{acceptedRisk}}</span>
                                  </div>
                                <div class="config-diff-block-label">Risks accepted</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div class="white-box comparator-section">
                <div *ngIf="isCompare" class="loading-text">{{constant.LOADING}}</div>
                <div *ngIf="!isCompare && !leftFileText && !rightFileText" class="loading-text">No Data</div>
                <!-- <div *ngIf="leftFileText || rightFileText" [hidden]="isCompare" class="comparator scroll"> -->
                <div class="comparator" 
                [ngClass]="{'single-compare': hasSingleDateResponse}">
                    <div class="comparator-drop-section">
                        <div [ngClass]="{'full-width': hasSingleDateResponse}" class="comparator-head">
                            <span class="date-detail">{{comparefromSelectedData?.date_added}}</span>
                            <span class="score">Security Score: <span [ngClass]="getScoreStatus(comparefromSelectedData?.security_score)">{{comparefromSelectedData?.security_score}}</span></span>
                        </div>
                        <div *ngIf="!hasSingleDateResponse" class="comparator-head">
                            <span class="date-detail">{{comparetoSelectedData?.date_added}}</span>
                            <span class="score">Security Score: <span [ngClass]="getScoreStatus(comparetoSelectedData?.security_score)">{{comparetoSelectedData?.security_score}}</span></span>
                        </div>
                    </div>
                    <ngx-monaco-diff-editor
                    *ngIf="(leftFileText || rightFileText) && !hasSingleDateResponse"
                      [options]="editorOptions"
                      [original]="leftFileText"
                      [modified]="rightFileText">
                    </ngx-monaco-diff-editor>
                    <ngx-monaco-editor style="height: 90%;" *ngIf="hasSingleDateResponse"
                    [options]="editorOptions" [(ngModel)]="leftFileText"></ngx-monaco-editor>
                </div>
            </div>
        </div>
    </div>
</div>