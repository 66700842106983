<div class="right-side-header">
    <ul>
        <li (click)="onTabSelectHandler('detail')" [ngClass]="{'active': selectedTab === 'detail'}">Details</li>
        <li (click)="onTabSelectHandler('device')" [ngClass]="{'active': selectedTab === 'device'}">Assets</li>
    </ul>
</div>
<div *ngIf="selectedTab === 'detail'" class="scan-item-details">
    <div class="details-section">
        <div class="selected-list-header" [ngClass]="addBadgeClass(selectedRow.vulnerability||'')">
            <p class="selected-list-header-title" *ngIf="selectedRow && selectedRow.paramter"
                tooltip="{{selectedRow.paramter}}" [options]="toolTipOption">
                {{selectedRow.paramter}}
            </p>
            <div *ngIf="selectedRow && selectedRow.paramter" class="selected-list-header-info">
                <div class="selected-list-filecount">{{selectedRow.vulnerability}}</div>
            </div>
        </div>
        <div class="details-info">
            <div class="item" *ngIf="!selectedRowApiCall">
                <ngx-skeleton-loader count="2" class="rhs-content-loader beach-mark-loader"> </ngx-skeleton-loader>
                <ngx-skeleton-loader count="2" class="rhs-content-loader graph-section-loader"> </ngx-skeleton-loader>
                <ngx-skeleton-loader count="4" class="rhs-content-loader content-loader"> </ngx-skeleton-loader>
            </div>
            <div *ngIf="selectedRowApiCall && !isDetailsAvailable()" class="no-data">{{noDataMsg}}</div>
            <div class="details-info-benchmark" *ngIf="selectedRowApiCall && selectedRowInfoDetails?.benchmark_names">
                <label>Benchmark:</label>
                <span class="benchmark-text">{{selectedRowInfoDetails?.benchmark_names}}</span>
                <!-- <mat-form-field>
                    <mat-select (selectionChange)="updateBenchmark(selectedBenchMark)" [(ngModel)]="selectedBenchMark">
                        <mat-option *ngFor="let item of selectedRowInfoDetails?.benchmark_list" [value]="item">{{
                            item.name }}</mat-option>
                    </mat-select>
                </mat-form-field> -->

            </div>
            <div class="details-info-content" *ngIf="selectedRowApiCall">
                <div class="details-info-values">
                    <div class="details-info-values-graf" *ngIf="selectedRowInfoDetails?.device_count">
                        <!-- <img src="../../assets/images/Outlne Stroked.png" /> -->
                        <app-semi-donut-chart [dynamicChartData]="dynamicChartData"></app-semi-donut-chart>
                        <p class="graf-value">{{selectedRowInfoDetails?.device_count?.total_device_count}}</p>
                        <p class="graf-title">Total Assets</p>
                    </div>
                    <div class="details-info-values-legends" *ngIf="selectedRowInfoDetails?.device_count">
                        <ul class="legends">
                            <li><label>Assets Failed:</label> <span
                                    class="bg-critical">{{selectedRowInfoDetails?.device_count?.failed_device_count}}</span>
                            </li>
                            <li><label>Assets Pass:</label> <span
                                    class="bg-low">{{selectedRowInfoDetails?.device_count?.passed_device_count}}</span>
                            </li>
                            <li><label>Accepted Risk:</label> <span
                                    class="accepted">{{selectedRowInfoDetails?.device_count?.whitelisted_device_count}}</span>
                            </li>
                        </ul>
                    </div>

                </div>
                <div class="benchmark-mapping" *ngIf="validateObjectData(selectedRowInfoDetails?.NIST)">
                    <span class="benchmark-text">NIST Mapping</span>
                    <span class="infoicon" mat-icon-button [matMenuTriggerFor]="menu"
                        aria-label="Example icon button with a menu icon" #clickHoverMenuTrigger="matMenuTrigger">
                        <mat-icon>info</mat-icon></span>
                    <mat-menu id="info-menu" #menu="matMenu" class="benchmark-info mat-menu-scroll">
                        <div *ngFor="let publication of selectedRowInfoDetails?.NIST | keyvalue" class="scroll">
                            <span class="list-item">{{ publication.key }}</span>
                            <div class="list-order">
                                <ol>
                                    <li *ngFor="let control of publication.value | keyvalue" class="list-item-value">
                                        <span class="list-item">{{ control.key }}:</span> {{ control.value }}
                                    </li>
                                </ol>
                            </div>

                        </div>
                    </mat-menu>

                </div>

                <div class="details-info-list scroll">
                    <div *ngIf="selectedRowInfoDetails?.benchmark_list?.[0]">
                        <ul class="details-info-list-item">
                          <li *ngIf="selectedRowInfoDetails?.benchmark_list?.[0]?.description" class="benchmark-list">
                            <div class="list-header-section">
                              <p class="list-item">Description</p>
                              <button class="expand-btn" mat-button (click)="toggleDescription()"><mat-icon>keyboard_arrow_down</mat-icon></button>
                            </div>
                            <p *ngIf="showDescription" class="list-item-discription">{{selectedRowInfoDetails?.benchmark_list?.[0]?.description}}</p>
                          </li>
                      
                          <li *ngIf="selectedRowInfoDetails?.benchmark_list?.[0]?.remediation" class="benchmark-list">
                            <div class="list-header-section">
                              <p class="list-item">Remediation</p>
                              <button class="expand-btn"mat-button (click)="toggleRectification()"><mat-icon>keyboard_arrow_down</mat-icon></button>
                            </div>
                            <p *ngIf="showRectification" class="list-item-discription">{{selectedRowInfoDetails?.benchmark_list?.[0]?.remediation}}</p>
                          </li>
                      
                          <li *ngIf="selectedRowInfoDetails?.benchmark_list?.[0]?.rationale" class="benchmark-list">
                            <div class="list-header-section">
                              <p class="list-item">Rationale</p>
                              <button class="expand-btn" mat-button (click)="toggleRationale()"><mat-icon>keyboard_arrow_down</mat-icon></button>
                            </div>
                            <p *ngIf="showRationale" class="list-item-discription">{{selectedRowInfoDetails?.benchmark_list?.[0]?.rationale}}</p>
                          </li>
                      
                          <li *ngIf="selectedRowInfoDetails?.benchmark_list?.[0]?.issues" class="benchmark-list">
                            <div class="list-header-section">
                              <p class="list-item">Issues</p>
                              <button class="expand-btn" mat-button (click)="toggleIssues()"><mat-icon>keyboard_arrow_down</mat-icon></button>
                            </div>
                            <p *ngIf="showIssues" class="list-item-discription">{{selectedRowInfoDetails?.benchmark_list?.[0]?.issues}}</p>
                          </li>
                        </ul>
                      </div>
                      
                </div>
            </div>

        </div>
    </div>
</div>

<div *ngIf="selectedTab === 'device'" class="scan-item-details">
    <div *ngIf="showDeviceDetails" class="disable-telent">
        <div class="disable-telent-title">
        <div class="header" *ngIf="selectedRow && selectedRow.paramter"
            [ngClass]="addBadgeClass(selectedRow.vulnerability||'')">
            <div class="header-left">
                <p class="header-title" tooltip="{{selectedRow.paramter}}" [options]="toolTipOption">{{selectedRow.paramter}}</p>
                <p class="header-status">{{selectedRow.vulnerability}}</p>
            </div>
            <div class="header-right">
                <div class="log-close">
                    <button mat-button class="log-close-btn btn" aria-label="Clear" (click)="closeDeviceDetail()" [mat-dialog-close]="true">
                        <mat-icon>cancel</mat-icon></button> 
                </div> 
            </div>
            
        </div>
        <div class="telent-selected-item" *ngIf="selectedDevice">
            <p class="text_ellipsis" [options]="toolTipOption" tooltip="{{selectedDevice.host_name}}
            ({{makeCapital(selectedDevice.vendor_category)}}-{{makeCapital(selectedDevice.device_category)}})">
                {{selectedDevice.host_name}}
                ({{makeCapital(selectedDevice.vendor_category)}}-{{makeCapital(selectedDevice.device_category)}})</p>


            <span *ngIf="selectedDevice.white_list" class="bg-tertiary white-text telent-a">A</span>
            <div [ngClass]="{'pass-item': selectedDevice.passed_value,'fail-item': !selectedDevice.passed_value }"
                class="selected-list-item-right ">
                <mat-icon *ngIf="!selectedDevice.passed_value">close</mat-icon>
                <mat-icon *ngIf="selectedDevice.passed_value">check</mat-icon>
            </div>
        </div>
        </div>
        <div class="item" *ngIf="isSpinner">
            <ngx-skeleton-loader count="2" class="rhs-content-loader device-filter-loader"> </ngx-skeleton-loader>
            <ngx-skeleton-loader *ngFor="let item of [].constructor(9); let i = index" count="4" class="rhs-content-loader  device-details-loader"> </ngx-skeleton-loader>
        </div>
        <div *ngIf="!isSpinner" class="telent-content">
            <div  class="log-info">
                <div class="input-box-container">
                    <mat-form-field appearance="outline" class="border-field no-bg">
                        <mat-label>Logs Info</mat-label>
                        <mat-select [ngModelOptions]="{standalone: true}" [(ngModel)]="filterValue"
                            (ngModelChange)="filterData()" [disabled]="noTests()">
                            <mat-option *ngFor="let state of states" [value]="state">{{state}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                </div>
                <button mat-raised-button class="collapse-btn"
                    (click)="toggleCollapseExpandAll()"  [disabled]="noTests() || (filterXsltData.length === 0 && !isSpinner)">
                    {{ allCollapsed ? 'Expand All' : 'Collapse All' }}
                </button>
            </div>
            <div class="scroll log-item-list-scroll">
                
                <!-- <div  class="no-data">
                    <div *ngFor="let rule of filterXsltData" >
                        <p  *ngIf="rule.Tests.length === 0 && !isSpinner" >No tests available for {{ rule.RuleName }}3</p>
                    </div>
                </div> -->
                <div *ngFor="let rule of filterXsltData" class="log-item-list"> 
                    <div *ngIf="rule.Tests && rule.Tests.length === 0" class="log-item normal-item">
                        <p>{{ rule.RuleName }}</p>
                       </div>
                    <div *ngIf="rule.Tests && rule.Tests.length > 0" [class.passed-item]="allPassedStatus(rule)"
                        [class.failed-item]="!allPassedStatus(rule)" class="log-item">
                        <div class="logitem-header">
                            <p class="log-title text_ellipsis" tooltip="{{rule.RuleName }}" [options]="toolTipOption">{{ rule.RuleName }}</p>
                            <span class="rule-info"><mat-icon (click)="toggleShowContent(rule,rule.RuleName)">
                                    {{ rule.isOpen ? 'remove_circle_outline' : 'add_circle_outline' }}
                                </mat-icon>
                            </span>
                        </div>
                        <ul *ngIf="rule.isOpen">
                            <li [class.passed-item]="allPassedStatus(rule)" [class.failed-item]="!allPassedStatus(rule)"
                                *ngFor="let test of rule.Tests">
                                <span [class.text-passed]="test.Status === 'PASSED'"
                                    [class.text-failed]="test.Status === 'FAILED'"
                                    [class.status-based]="(test.Status === 'PASSED' && hasFailedStatus(rule.Tests)) || (test.Status === 'FAILED' && hasPassedStatus(rule.Tests))">
                                    {{ test.Status }}
                                </span> - {{ test.Description }}
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
            <div *ngIf="filterXsltData.length === 0 && !isSpinner" class="no-data">
                {{noDataMsg}}
            </div>

        </div>
    </div>
    <div class="selected-list-details">
        <div class="selected-list-header" [ngClass]="addBadgeClass(selectedRow.vulnerability||'')">
            <p class="selected-list-header-title" *ngIf="selectedRow && selectedRow.paramter"
            [options]="toolTipOption" tooltip="{{selectedRow.paramter}}">
                {{selectedRow.paramter}}
            </p>
            <div *ngIf="selectedRow && selectedRow.paramter" class="selected-list-header-info">
                <div class="selected-list-filecount">{{selectedRow.passed_count }} / {{selectedRow.count}}</div>
                <div class="sort">
                    <p class="text">Sort by: <span class="selec_val">{{selectedValue}}</span></p>
                    <div>
                        <button *ngIf="filteredData && filteredData.length > 1" class="" mat-button [matMenuTriggerFor]="parameterMenuDrop"><mat-icon
                                class="sort-icon">arrow_drop_down</mat-icon>
                        </button>
                        <mat-menu #parameterMenuDrop="matMenu">
                            <button (click)=" selectedValue = value.name; sortParameters(value)"
                                *ngFor="let value of parameterMenu" mat-menu-item>{{value.name}}</button>
                        </mat-menu>
                    </div>
                </div>
            </div>
        </div>
        <div class="selected-list">
            <div *ngIf="filteredData?.length" class="disable-selected-list-item list-search-box">
                <mat-form-field appearance="fill">
                    <!-- <mat-label>Search..</mat-label> -->
                    <input matInput placeholder="Search.." [(ngModel)]="searchDevice" autocomplete="off" type="text">
                    <mat-icon class='search-icon' matPrefix>search</mat-icon>
                </mat-form-field>
                <div class="search-close">
                    <button *ngIf="searchDevice"  mat-icon-button aria-label="Clear" (click)="clearSearch()">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
            <ul class="selected-list-item-scroll scroll" infinite-scroll [infiniteScrollDistance]="6"
            [infiniteScrollThrottle]="30" [alwaysCallback]="true" [scrollWindow]="false"
            (scrolled)="onScroll()">
                <ng-container *ngFor="let item of filteredData | search :searchDevice">
                  <li class="disable-selected-list-item" [ngClass]="{'accepted-item': enableWhiteListIndicator(item)}" (click)="selectedListPopup(item)">
                    <div class="selected-list-item-left">
                      <span [options]="toolTipOption" tooltip="{{item.host_name}} ({{makeCapital(item.vendor_category)}}-{{item.device_category}})" matTooltipClass="tool-tip-desc" class="text_ellipsis">{{item.host_name}} ({{makeCapital(item.vendor_category)}}-{{item.device_category}})</span>
                    </div>
                    <div class="selected-icon-list">
                      <div class="is-white-list" *ngIf="enableWhiteListIndicator(item)">
                        <p>A</p>
                      </div>
                      <div [ngClass]="{'pass-item': item.passed_value, 'fail-item': !item.passed_value}" class="selected-list-item-right">
                        <mat-icon *ngIf="!item.passed_value">close</mat-icon>
                        <mat-icon *ngIf="item.passed_value">check</mat-icon>
                      </div>
                    </div>
                  </li>
                </ng-container>
                <div *ngIf="(filteredData | search :searchDevice).length === 0" class="no-data">
                  {{noDataMsg}}
                </div>
                <div *ngIf="!filteredData?.length">
                  <p class="no_data" *ngIf="selectedValue === 'Pass'">There are no passed parameters</p>
                  <p class="no_data" *ngIf="selectedValue === 'Failed'">There are no failed parameters</p>
                </div>
                <div class="item" *ngIf="!selectedApiAssets && isScrollEligible">
                    <ngx-skeleton-loader *ngFor="let item of [].constructor(!filteredData?.length ? 9 : 3); let i = index" count="2" class="rhs-content-loader   device-details-loader"> </ngx-skeleton-loader>
                </div>
              </ul>
             
            <!-- <div *ngIf="isSpinner" class="small-spinner"></div> -->
        </div>
    </div>
</div>