import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './components/admin/admin.component';
import { PersonalDetailsComponent } from './components/personal-details/personal-details.component';
import { AccountDetailsComponent } from './components/account-details/account-details.component';
import { UsersComponent } from './components/users/users.component';
import { ToolManagementComponent } from './components/tool-management/tool-management.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatIconModule} from '@angular/material/icon';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ProfileComponent } from './components/profile/profile.component';
import {MatSelectModule} from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrganisationComponent } from './components/organisation/organisation.component';
import { SharedModule } from '../shared/shared.module';
import { InviteUsersComponent } from './components/users/invite-users/invite-users.component';
import { CustomizeComponent } from './components/users/customize/customize.component';
import { CreateRoleComponent } from './components/tool-management/create-role/create-role.component';
import { DeviceManagementComponent } from './components/device-management/device-management.component';
import { EditUserComponent } from './components/users/edit-user/edit-user.component';
import { SecurityInformationComponent } from './components/security-information/security-information.component';
import { DevicePopupComponent } from './components/device-management/device-popup/device-popup.component';
import { HeaderService } from '../shared/services/header.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NewDeviceComponent } from './components/device-management/new-device/new-device.component';
import { MONACO_PATH, MonacoEditorModule } from '@materia-ui/ngx-monaco-editor';
import { ToolRenamedialogComponent } from './components/tool-management/tool-renamedialog/tool-renamedialog.component';
import { DeleteDevicePopupComponent } from './components/device-management/delete-device-popup/delete-device-popup.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { UserDeleteComponentComponent } from './components/users/user-delete-component/user-delete-component.component';
import { AssetSummaryDownloadComponent } from './components/asset-summary-download/asset-summary-download.component';
import { NumberFormatPipe } from './admin-format.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CredentionalsManagementComponent } from './components/credentionals-management/credentionals-management.component';
import { CredentialPopupComponent } from './components/device-management/credential-popup/credential-popup.component';
import { MapCredentialComponent } from './components/device-management/map-credential/map-credential.component';
import { LastScanComponent } from './components/device-management/last-scan/last-scan.component';
import { ScoreComponent } from './components/device-management/score/score.component';
import { VulnerabilityValComponent } from './components/device-management/vulnerability-val/vulnerability-val.component';
import { VulnerabilityTooltipComponent } from './components/device-management/vulnerability-tooltip/vulnerability-tooltip.component';
import { MatMenuModule } from '@angular/material/menu';
import { AgGridModule } from 'ag-grid-angular';
import { OptionsComponent } from './components/device-management/options/options.component';
import { AssetsNameComponent } from './components/device-management/assets-name/assets-name.component';
import { MfaComponent } from './components/users/mfa/mfa.component';
import { RoleComponent } from './components/users/role/role.component';
import { MenuOptionsComponent } from './components/users/menu-options/menu-options.component';
import { PhoneComponent } from './components/users/phone/phone.component';
import { UserStatusComponent } from './components/users/status/status.component';
import { ExcelExportComponent } from './components/device-management/excel-export/excel-export.component';
import { DeviceSeverityComponent } from './components/device-management/device-severity/device-severity.component';
import { VpnComponent } from './components/vpn/vpn.component';
import { AddVpnComponent } from './components/vpn/add-vpn/add-vpn.component';
import { VpnPopupsComponent } from './components/vpn/vpn-popups/vpn-popups.component';
import { ComplainceReportComponent } from './components/complaince-report/complaince-report.component';
@NgModule({
  declarations: [
    AdminComponent,
    PersonalDetailsComponent,
    AccountDetailsComponent,
    UsersComponent,
    ToolManagementComponent,
    ProfileComponent,
    OrganisationComponent,
    InviteUsersComponent,
    CreateRoleComponent,
    CustomizeComponent,
    DeviceManagementComponent,
    EditUserComponent,
    SecurityInformationComponent,
    DevicePopupComponent,
    NewDeviceComponent,
    ToolRenamedialogComponent,
    DeleteDevicePopupComponent,
    UserDeleteComponentComponent,
    AssetSummaryDownloadComponent,
    NumberFormatPipe,
    CredentionalsManagementComponent,
    CredentialPopupComponent,
    MapCredentialComponent,
    LastScanComponent,
    ScoreComponent,
    VulnerabilityValComponent,
    VulnerabilityTooltipComponent,
    OptionsComponent,
    AssetsNameComponent,
    MfaComponent,
    UserStatusComponent,
    RoleComponent,
    MenuOptionsComponent,
    PhoneComponent,
    ExcelExportComponent,
    DeviceSeverityComponent,
    VpnComponent,
    AddVpnComponent,
    VpnPopupsComponent,
    ComplainceReportComponent
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    MatTabsModule,
    MatIconModule,
    MatSlideToggleModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    SharedModule,
    FlexLayoutModule,
    MonacoEditorModule,
    MatTooltipModule,
    NgxSkeletonLoaderModule.forRoot(),
    MatMenuModule,
    AgGridModule
  ],
  exports:[
    ComplainceReportComponent
  ],
  providers: [

    {
      provide: MONACO_PATH,
      useValue: 'https://unpkg.com/monaco-editor@0.24.0/min/vs'
    }],
    schemas: [
      CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class AdminModule { }
