import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CONSTANT } from 'src/app/modules/shared/constants/constant-data';
import { CustomDailog } from 'src/app/modules/shared/model/Dialog.model';
import { DialogService } from 'src/app/modules/shared/services/dialog.service';

@Component({
  selector: 'app-tool-renamedialog',
  templateUrl: './tool-renamedialog.component.html',
  styleUrls: ['./tool-renamedialog.component.scss']
})
export class ToolRenamedialogComponent {
  roleNameInput!: FormGroup;
  inputDirty = false;
  validation_messages = {
    role_name: [
      { type: 'required', message: CONSTANT.CREATE_ROLE.ROLE_NAME_REQUIRED },
      { type: 'minlength', min: 3, message: CONSTANT.CREATE_ROLE.ROLE_NAME_MIN_LENGTH },
      { type: 'maxlength', max: 64, message: CONSTANT.CREATE_ROLE.ROLE_NAME_MAX_LENGTH },
      { type: 'pattern', pattern:/^[a-zA-Z0-9]*(\s[a-zA-Z0-9]+)*$/, message: CONSTANT.CREATE_ROLE.ROLE_NAME_PATTERN }
    ]
  };
  constructor(public dialogRef: MatDialogRef<ToolRenamedialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CustomDailog,
    private fb: FormBuilder, private cdr: ChangeDetectorRef,
    public dialogService: DialogService
  ) {
    this.roleNameInput = this.fb.group({
      role_name: new FormControl(data?.data?.roleName, Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(64),
        Validators.pattern(/^[a-zA-Z0-9]*(\s[a-zA-Z0-9]+)*$/)
      ]))
    });
  }

  ngOnInit(): void {
  }

  newName(value: string): void {
    const result = {
      click: true,
      value: value.trim()
    };
    // this.dialogRef.close(result);
    this.data?.data?.setCallbackFunction(result);
    this.dialogService.closeDialog();
  }

  renameDisabled(roleNameInput:any, roleInput: any) {
    const value: string = roleInput.value.trim();
    return  ((!roleNameInput.valid)  || (value === this.data?.data?.roleName))
  }
  cancelRename(): void {
    this.dialogService.closeDialog();
  }
}
